import template from './manage-labels.html';
import controller from './manage-labels.controller';
import './manage-labels.scss';

const manageLabelsDirective = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        team: '<'
    }
};

export default manageLabelsDirective;
