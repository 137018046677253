import controller from './document-show.controller';
import template from './document-show.html';
import './document-show.scss';

const documentShowComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        loadedDocument: '<',
        $transition$: '<'
    }
};

export default documentShowComponent;
