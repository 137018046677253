import { StateProvider } from '@uirouter/angularjs';

const downloadsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.downloads', {
        url: '/downloads',
        views: {
            '': 'downloadsIndex'
        }
    });
};

downloadsRoute.$inject = ['$stateProvider'];

export default downloadsRoute;
