import { StateOrName, RawParams } from '@uirouter/angularjs';

export enum ThemeColors {
    'blue',
    'green'
}

export interface BarOptions {
    descriptionText: string;
    descriptionTextHelper?: string;
    descriptionLink: { state: StateOrName; params: RawParams };
    actionText: string;
    actionLink: { state: StateOrName; params: RawParams };
    completeActionText?: string;
}
