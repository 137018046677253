import { Transition } from '@uirouter/angularjs';

import { ApiError } from '@app/shared/models';

class DashboardTimelineDetailPageController {
    // bindings
    public $transition$: Transition;
    crumbs = [];

    constructor($state, $filter, $q, Adapter, Team, Projects, SORT, CurrentSession, Notifications) {
        this._$state = $state;
        this._$filter = $filter;
        this._$q = $q;
        this._Adapter = Adapter;
        this._Teams = Team;
        this._Projects = Projects;
        this.SORT = SORT;
        this._CurrentSession = CurrentSession;
        this._Notifications = Notifications;

        this.project = {};
        this.items = [];
        this.timeline = {};
        this.loadingData = false;
    }

    $onInit(): void {
        const stateParams = this.$transition$.params();
        this.currentTeam = this._CurrentSession.getCurrentTeam();

        this.loadingData = true;
        this._$q.all({
            project: this._Projects.getProject(this.currentTeam.id, stateParams.projectId).toPromise(),
            timelines: this._Projects.getProjectTimelines(this.currentTeam.id, stateParams.projectId).toPromise(),
            timeline: this._Projects.getFullTimeline(this.currentTeam.id, stateParams.timelineId).toPromise()
        }).then(
            (data) => {
                this.project = data.project;
                this.allTimelines = data.timelines;
                this.timeline = data.timeline;

                const items = this.timeline.items || [];
                // TODO: Consider the alternative for adaptBinderItem when working on https://florencehc.atlassian.net/browse/EBS-18259
                this.adaptedItems = items.map((item) => this._Adapter.adaptBinderItem(item));
                this.updateSort('name', false);
                this.crumbs = this._getCrumbs();
                this.loadingData = false;
            },
            ({ error }: ApiError) => {
                this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
            }
        );
    }

    goToItem(item) {

        if (item.type !== 'document') {
            return;
        }

        this._$state.go('app.team.document-show', {
            teamId: item.teamId,
            documentId: item.id,
            version: item.version
        });
    }

    selectTimeline(timeline) {
        const destination = {
            teamId: timeline.teamId,
            projectId: timeline.projectId,
            timelineId: timeline.id
        };
        this._$state.go('app.team.dashboard-timeline-detail', destination);
    }

    canManageTimeline() {
        return this._Teams.canManageIndividualTimeline();
    }

    manageTimeline() {
        if (!this.canManageTimeline()) {
            return;
        }

        this._$state.go('app.team.manage-project-timeline', {
            teamId: this.timeline.teamId,
            projectId: this.timeline.projectId,
            timelineId: this.timeline.id
        });
    }

    _getCrumbs() {
        return [
            {
                name: `Project Dashboard: ${this.project.name}`,
                stateName: 'app.team.dashboard-timelines',
                stateParams: {
                    teamId: this.timeline.teamId,
                    projectId: this.timeline.projectId
                }
            },
            {
                name: this.timeline.name
            }
        ];
    }

    updateSort(sortName, isReversed) {
        this.SORT.set(sortName, isReversed);
        this.adaptedItems = this._$filter('orderBy')(this.adaptedItems, sortName, this.SORT.isReversed, this.SORT.naturalSort);
    }
}

DashboardTimelineDetailPageController.$inject = [
    '$state',
    '$filter',
    '$q',
    'Adapter',
    'Team',
    'Projects',
    'SORT',
    'CurrentSession',
    'Notifications'
];

export default DashboardTimelineDetailPageController;
