import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';

import { moveAwayFromUTC, moveTowardsUTC } from '@app/shared/date-time';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { ApiError, BrowseTree } from '@app/shared/models';

class TimelineUpdateController {
    // bindings
    public $transition$: Transition;

    constructor(Team, Binders, Projects, $state, MESSAGES, REGEX, CurrentSession, $q, ApiError, Notifications) {
        this._Teams = Team;
        this._Binders = Binders;
        this._Projects = Projects;
        this._$state = $state;
        this._MESSAGES = MESSAGES;
        this._REGEX = REGEX;
        this._CurrentSession = CurrentSession;
        this._$q = $q;
        this._ApiError = ApiError;
        this._Notifications = Notifications;

        this.addedItems = [];
        this.removedItems = [];
        this.loadingData = false;
        this.isProcessing = false;

        this.loadBinder = this._loadBinder.bind(this);
    }

    $onInit(): void {
        const stateParams = this.$transition$.params();
        this._currentTeam = this._CurrentSession.getCurrentTeam();
        const perm = this._currentTeam.permissions || {};
        if (!(perm.viewDashboard && (perm.manageTimelines || perm.updateTimeline))) {
            return this._$state.go('app.select-team');
        }


        this.loadingData = true;
        this._$q.all({
            binders: this._Binders
                .getBinders(this._currentTeam.id, { includeArchived: false })
                .toPromise()
                .catch(this._ApiError.handleError),
            project: this._Projects.getProject(this._currentTeam.id, stateParams.projectId).toPromise(),
            timeline: this._Projects.getFullTimeline(this._currentTeam.id, stateParams.timelineId).toPromise()
        }).then(
            (data) => {
                this.binders = sortByLexicographically(data.binders, 'name');
                const timeline = _.pick(data.timeline, [
                    'id',
                    'name',
                    'teamId',
                    'projectId',
                    'items',
                    'projectedStart',
                    'projectedEnd',
                    'isComplete'
                ]);
                timeline.projectedStart = moveTowardsUTC(timeline.projectedStart);
                timeline.projectedEnd = moveTowardsUTC(timeline.projectedEnd);
                this.timeline = timeline;

                this.project = data.project;

                this._timelineId = this.timeline.id;
                this.items = this.timeline.items || [];
                delete this.timeline.items;
                delete this.timeline.id;

                this.selectedItems = this.items.map((item) => ({
                    id: item.id,
                    type: item.type,
                    binderId: item.binderId
                }));
                this.crumbs = this._getCrumbs();
                this.loadingData = false;
            },
            ({ error }: ApiError) => {
                this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
            }
        );
    }

    _loadBinder(params): BrowseTree | Promise<void> {

        if (params.objectType !== 'binder') {
            return Promise.resolve();
        }

        return this._Teams.loadTreeWithDocs(this._currentTeam.id, params.objectId, false)
            .toPromise()
            .then((data) => {
                return sortBrowseTreeLexicographically(data, 'name');
            });
    }

    onNameChange(event: { name: string }) {
        this.timeline.name = _.trim(event.name);
    }

    onIsCompleteChange(event: { isComplete: boolean}) {
        this.timeline.isComplete = event.isComplete;
    }

    onSelectedItemsChange(event: { selectedItems }) {
        this.selectedItems = event.selectedItems;
    }

    /**
     * Validate the timeline
     * @private
     * @return {field: Object, global: []} Return an array of errors
     */
    _validateForm() {
        const errors = {
            hasErrors: false,
            field: {},
            global: []
        };

        if (!this.timeline.projectedStart) {
            errors.hasErrors = true;
            errors.field.projectedStart = 'Please set a valid projected start date.';
        }

        if (!this.timeline.projectedEnd) {
            errors.hasErrors = true;
            errors.field.projectedEnd = 'Please set a valid projected end date.';
        }

        if (!this.timeline.name) {
            errors.hasErrors = true;
            errors.field.name = 'Please choose a name.';
        }
        else if (!this._REGEX.names.test(this.timeline.name)) {
            errors.hasErrors = true;
            errors.field.name = `Please choose a valid name. ${this._MESSAGES.validNameMessage}`;
        }

        if (
            this.timeline.projectedStart
            && this.timeline.projectedEnd
            && this.timeline.projectedStart > this.timeline.projectedEnd
        ) {
            errors.hasErrors = true;
            errors.global.push('Projected start date must be before projected end date.');
        }

        return errors;
    }

    onSubmit() {
        const errors = this._validateForm();
        if (errors.hasErrors) {
            this.fieldErrors = errors.field;
            this.globalErrors = errors.global;
            if (errors.global && errors.global.length) {
                this._Notifications.error(errors.global[0]);
            }
            return;
        }

        this.isProcessing = true;
        const updatePayload = _.merge({}, this.timeline, this._timelineChanges());
        this._moveDates(updatePayload);
        this._Projects.editTimeline(this._currentTeam.id, this._timelineId, updatePayload)
            .toPromise()
            .then((timeline) => {
                this._Notifications.success(`Timeline "${timeline.name}" Updated!`);
                this.isProcessing = false;
                this._$state.reload();
            }, ({ error }: ApiError) => {
                this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                this.isProcessing = false;
            });
    }

    onCancel() {
        this._$state.go('app.team.manage-project-timeline', {
            teamId: this._currentTeam.id,
            projectId: this.timeline.projectId,
            timelineId: this._timelineId
        });
    }

    _moveDates(payload): void {
        payload.projectedStart = moveAwayFromUTC(payload.projectedStart);
        payload.projectedEnd = moveAwayFromUTC(payload.projectedEnd);
    }

    _timelineChanges() {
        const existing = this._normalizeSelectedItems(this.items);
        const selected = this._normalizeSelectedItems(this.selectedItems);

        const removeItems = _.differenceBy(existing, selected, 'objectId');
        const addItems = _.differenceBy(selected, existing, 'objectId');

        return {
            addItems,
            removeItems
        };
    }

    _normalizeSelectedItems(items) {
        return items.map((item) => ({ objectType: item.type, objectId: item.id }));
    }

    _getCrumbs() {
        const teamId = this._currentTeam.id;
        return [
            {
                name: 'Manage Projects',
                stateName: 'app.team.manage-projects',
                stateParams: { teamId }
            },
            {
                name: this.project.name,
                stateName: 'app.team.manage-project',
                stateParams: {
                    projectId: this.project.id,
                    teamId
                }
            },
            {
                name: this.timeline.name
            }
        ];
    }
}

TimelineUpdateController.$inject = [
    'Team',
    'Binders',
    'Projects',
    '$state',
    'MESSAGES',
    'REGEX',
    'CurrentSession',
    '$q',
    'ApiError',
    'Notifications'
];

export default TimelineUpdateController;
