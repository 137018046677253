import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const valueMatch = function valueMatch(value: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || control.value !== value) {
            return { valueMatch: true };
        }
        return null;
    };
};
