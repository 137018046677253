import {
    Component, Input, OnInit, Output, EventEmitter,
    OnDestroy
} from '@angular/core';
import { filter, takeUntil, tap } from 'rxjs/operators';
import {
    FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { Document, MonitorReview, Paywall } from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FeatureFlagService } from '../../../../core/feature-flag.service';
import { FEATURE_FLAGS } from '../../../../core/constants/feature-flags';
import template from './monitor-review-approve.component.html';
import { UpdatedFormResponse } from '../monitor-review-integration-form/monitor-review-integration-form.component.types';
import { Approval, MonitorReviewOrApproveEvent, StudyLabel } from './monitor-review-approve.component.types';


@Component({
    template
})
export class MonitorReviewApproveComponent implements OnInit, OnDestroy {
    private _inheritedLabels;
    @Input() document: Document;
    @Input() monitorReviews: MonitorReview[];
    @Input() paywallOrgs: Paywall[];
    @Input() studyLabel: StudyLabel;
    @Input() isReview: boolean;
    @Input()
    set inheritedLabels(labels) {
        this._inheritedLabels = this._mapLabelValues(labels);
    }

    get inheritedLabels() {
        return this._inheritedLabels;
    }

    @Output() onApprovalSubmit = new EventEmitter<MonitorReviewOrApproveEvent>()

    recipientIds: string[];
    approveForm: FormGroup;
    selected = {
        paywall: undefined,
        studyValue: undefined,
        documentVersion: undefined
    };

    formResponses = {};
    integrationFormValid = false;
    isProcessing = false;
    filteredStudyLabel: StudyLabel;
    requireOpenQueryConfirmation: boolean;
    openQueryConfirmed: boolean;
    submitText: string;
    warningText: string;
    selectOnlyRelatedUPID: boolean;

    private destroy$ = new Subject<void>();

    private readonly uniqueProtocolIdLabel = 'Unique Protocol ID';

    constructor(private FeatureFlags: FeatureFlagService,
        private fb: FormBuilder,
        private Modal: BsModalRef) {
    }

    ngOnInit(): void {
        this.filteredStudyLabel = { ...this.studyLabel };
        this._setDefaultState();

        this.FeatureFlags.getFlag(FEATURE_FLAGS.UPID_RELATED_TO_MONITOR_GROUP, true).pipe(
            filter((flag) => flag !== undefined),
            tap((value) => {
                this.selectOnlyRelatedUPID = value;
                if (this.selectOnlyRelatedUPID) {
                    this.filterStudyLabelsByStudyId(this.approveForm.value.paywall);
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.submitText = this.isReview ? 'Review' : 'Approve';
        this.warningText = this.isReview
            ? 'I understand that marking this document as reviewed will close the open query for'
            : 'I understand that marking this document as approved will close the open query for';
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    showIntegrationForm() {
        return this.approveForm.value.paywall
        && this.approveForm.value.paywall.integrations
        && this.approveForm.value.paywall.integrations?.length
        && !this.isReview;
    }

    handleFormResponsesChange(result: UpdatedFormResponse) {
        this.formResponses = result.updatedFormResponse;
        this.integrationFormValid = result.isFormValid;
    }

    onUpdatedLabel(labelName, labelValue): void {
        this.selected[labelName] = labelValue;
        if (labelName in this.formResponses) {
            this.formResponses[labelName] = labelValue;
        }
        this.checkReviewStatus();
    }

    onUpdatedPaywall(paywallValue?: Paywall): void {
        if (this.selectOnlyRelatedUPID) {
            this.filterStudyLabelsByStudyId(paywallValue);
        }
        this._setAvailableIntegrationResponses(paywallValue);
        this.checkReviewStatus();
    }

    checkReviewStatus(): void {
        if (!this.approveForm.value.paywall || !this.selected.studyValue) {
            return;
        }

        const foundReview = this._findMonitorReview();
        this.requireOpenQueryConfirmation = foundReview && foundReview.status === 'open';
        if (this.requireOpenQueryConfirmation) {
            this.approveForm.addControl('openQueryConfirmation', new FormControl(false, Validators.requiredTrue));
        }

    }

    _preventClosingIfProcessing(event, reason, closed): void {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;
        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    _setDefaultState(): void {
        this.selected.documentVersion = this.document.version;
        this.initForm();

        if (this.paywallOrgs.length === 1) {
            [this.approveForm.value.paywall] = this.paywallOrgs;
            this.onUpdatedPaywall(this.paywallOrgs[0]);
        }

        if (this.studyLabel.values.length === 1) {
            [this.selected.studyValue] = this.studyLabel.values;
            this.onUpdatedLabel(this.studyLabel.name, this.studyLabel.values[0].value);
        }
        else {
            this.checkReviewStatus();
        }
    }

    _mapLabelValues(labeledEntities) {

        return labeledEntities.reduce((accum, curr) => {

            accum[curr.labelName] = accum[curr.labelName] || [];
            if (accum[curr.labelName].indexOf(curr.value) < 0) {
                accum[curr.labelName].push(curr.value);
            }
            return accum;
        }, {});
    }


    _setAvailableIntegrationResponses(paywallValue?: Paywall): void {
        if (!(paywallValue && paywallValue.integrations.length)) {
            return;
        }

        paywallValue.integrations[0].formStructure.forEach((formField) => {
            const { prefillLabelName = '' } = formField;
            if (prefillLabelName) {
                const availableValues = this.inheritedLabels[prefillLabelName] || [];
                formField.availableValues = Array.from(new Set(availableValues));

                this.formResponses[prefillLabelName] = this.selected[prefillLabelName]
                    || (formField.availableValues.length === 1 && formField.availableValues[0])
                    || '';
            }
        });
    }

    cancel() {
        this.Modal.hide();
    }

    save() {
        this.isProcessing = true;
        const paywallValue = this.approveForm.value.paywall;

        const approval: Approval = {
            studyLabelId: this.studyLabel.id,
            studyLabelValueId: this.selected.studyValue.id,
            documentVersion: this.selected.documentVersion,
            paywallId: paywallValue.id,
            documentId: this.document.id,
            recipientIds: this.recipientIds
        };

        if (paywallValue.integrations && paywallValue.integrations.length && !this.isReview) {
            approval.integrationId = paywallValue.integrations[0].id;
            approval.formResponses = Object.keys(this.formResponses).map((fieldLabelName) => ({
                fieldLabelName,
                fieldResponse: this.formResponses[fieldLabelName]
            }));
        }

        const monitorReview = this._findMonitorReview();
        this.onApprovalSubmit.emit({ approval, monitorReview });
        this.isProcessing = false;
    }

    _findMonitorReview() {
        return this.monitorReviews.find((review) => {
            return review.paywallId === this.approveForm.value.paywall?.id
                && review.studyLabelValueId === this.selected.studyValue.id;
        });
    }

    updateSelectedRecipientIds($event): void {
        this.recipientIds = $event.selectedRecipientIds;
    }

    initForm(): void {
        this.approveForm = new FormGroup({
            version: new FormControl(this.selected.documentVersion, Validators.required),
            paywall: new FormControl(this.paywallOrgs[0], Validators.required),
            study: new FormControl(null, Validators.required)
        });

        this.approveForm.get('study').valueChanges.subscribe((value) => {
            if (value) {
                this.selected.studyValue = value;
            }
            this.onUpdatedLabel(this.uniqueProtocolIdLabel, value?.value ?? null);
        });
        this.approveForm.get('paywall').valueChanges.subscribe((value) => {
            this.onUpdatedPaywall(value);
        });

    }

    private filterStudyLabelsByStudyId(paywall: Paywall): void {
        this.filteredStudyLabel.values = this.studyLabel.values.filter((labelValue) => {
            return labelValue.studyId ? paywall.studyIds?.includes(labelValue.studyId) ?? true : false;
        });

        if (this.filteredStudyLabel.values.length === 1) {
            [this.selected.studyValue] = this.filteredStudyLabel.values;
        }
        else {
            this.selected.studyValue = null;
        }
        this.onUpdatedLabel(this.uniqueProtocolIdLabel, this.selected.studyValue.value);
    }
}
