/**
 * resolve.onSubmit {Function} -
 * resolve.warningText {String} - the text to appear as warning well
 * resolve.bodyText {String} - the text to appear as modal body message
 * resolve.requireReason {Boolean} - if reason must be included to confirm
 * resolve.confirmLabel {String} - if user must confirm by typing a name,
 * the label for the confirmation input
 * resolve.confirmValue {String} - if user must confirm by typing a name,
 * the value to be confirmed
 * resolve.confirmVerb {String} - verb for action (defaults to delete)
 * resolve.confirmVerbProcessing {String} - 'ing' verb for action
 */
const confirmDestroyWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
    <confirm-destroy
        [warning-text]="vm.resolve.warningText"
        [body-text]="vm.resolve.bodyText"
        [require-reason]="vm.resolve.requireReason"
        [confirm-label]="vm.resolve.confirmLabel"
        [confirm-value]="vm.resolve.confirmValue"
        [confirm-verb]="vm.resolve.confirmVerb"
        [confirm-verb-processing]="vm.resolve.confirmVerbProcessing"
        (save)="vm.resolve.onSubmit($event)"
        (dismiss)="vm.modalInstance.close(); vm.resolve.onCancel()"
    ></confirm-destroy>
    `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default confirmDestroyWrapperComponent;
