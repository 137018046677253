import template from './duplicate-modal-wrapper.html';
import { DuplicateModalWrapper as controller } from './duplicate-modal-wrapper.controller';

export const DuplicateModalWrapperComponent = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};
