import template from './create-names-list-wrapper.html';
import { CreateNamesListModalWrapper as controller } from './create-names-list-wrapper.controller';

export const CreateNamesListModalWrapperComponent = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};
