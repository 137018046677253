import { Folder, Document } from '@app/shared/models';

export class DuplicateModalWrapper {
    public modalInstance: ng.ui.bootstrap.IModalInstanceService;
    public resolve: {
        items: (Folder | Document)[];
        parentEntity: Folder;
    };

    onDismissed(): void {
        this.modalInstance.close();
    }
}
