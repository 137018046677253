import { Transition } from '@uirouter/angularjs';
import { SessionsService } from '@app/shared/sessions/sessions.service';

class HelpDeskController {
    public $transition$: Transition;

    constructor(
        private $window: ng.IWindowService,
        private Sessions: SessionsService
    ) { }

    $onInit(): void {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { return_to } = this.$transition$.params();
        this.Sessions.getHelpDeskUrl(return_to)
            .subscribe(({ url }) => {
                this.$window.location.href = url;
            });
    }
}

HelpDeskController.$inject = [
    '$window',
    'SessionsService'
];

export default HelpDeskController;
