import { DocumentService } from '@app/shared/documents/document.service';
import { StateProvider, StateService, Transition } from '@uirouter/angularjs';

const resolve = {
    loadedDocument: ['$transition$', 'DocumentService', '$state', function loadDocument($transition$: Transition, DocumentService: DocumentService, $state: StateService) {
        const stateParams = $transition$.params();
        return DocumentService.load(stateParams.documentId, stateParams.version, stateParams.contentVersion).toPromise()
            .catch(() => {
                const fromState = $transition$.from();
                const fromParams = $transition$.params('from');

                $state.go(fromState, fromParams);
            });
    }]
};

const documentsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.document-show', {
        url: '/documents/:documentId/versions/:version?taskId&contentVersion&openRequestSignatureModal',
        views: {
            '': {
                component: 'documentShow'
            }
        },
        resolve
    });

    $stateProvider.state('app.team.document-show-most-recent', {
        url: '/documents/:documentId?taskId&contentVersion&signatureRequestId?monitorReviewId',
        views: {
            '': {
                component: 'documentShow'
            }
        },
        resolve
    });

    $stateProvider.state('app.team.view-manage-qc-review', {
        url: '/documents/:documentId/versions/:version/manage-qc-review',
        views: {
            '': {
                component: 'qcReviewViewManage'
            }
        }
    });
};

documentsRoute.$inject = ['$stateProvider'];

export default documentsRoute;
