const objectPickerWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
        <object-picker
            [objects]="vm.resolve.objects"
            (dismiss)="vm.modalInstance.close('cancel')"
            (change)="vm.resolve.objectsChanged($event)"
        ></object-picker>
        `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default objectPickerWrapperComponent;
