import template from './dashboard-timeline-detail-page.html';
import controller from './dashboard-timeline-detail-page.controller';

const dashboardTimelineDetailPageComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        $transition$: '<'
    }
};

export default dashboardTimelineDetailPageComponent;
