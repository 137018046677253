
export const SignLogEntriesWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
    <sign-log-entries
        [doc]="vm.resolve.doc"
        [current-team]="vm.resolve.currentTeam"
        [current-user]="vm.resolve.currentUser"
        [log-entry-signatures]="vm.resolve.logEntrySignatures"
        [job-title]="vm.resolve.jobTitle"
        (dismiss)="vm.modalInstance.close($event)"
        (save)="vm.resolve.save($event)"
    ></sign-log-entries>
    `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};
