import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {
    Component, OnInit, Output, EventEmitter
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import template from './document-set-date.component.html';
import styles from './document-set-date.component.scss';

@Component({
    selector: 'document-set-date',
    template,
    providers: [{ provide: '$scope', useExisting: '$rootScope' }], // date-time-picker workaround
    styles: [String(styles)]

})

export class DocumentSetDateComponent implements OnInit {
    @Output() save = new EventEmitter<{date: Date}>();

    date: Date;
    minDate: moment.Moment;
    maxDate: moment.Moment;
    isValid: boolean;

    constructor(
        private modalRef: BsModalRef
    ) {
    }

    ngOnInit(): void {
        this.minDate = moment().subtract(2, 'years');
        this.maxDate = moment(new Date('01/01/2100'));
    }

    cancel(): void {
        this.modalRef.hide();
    }

    checkValidity(value: moment.MomentInput): void {
        this.isValid = moment(value).isBetween(this.minDate, this.maxDate);
        this.date = moment(value).toDate();
    }

    calculateDateValue(offset: 'week' | 'month' | 'year'): {value: Date} {
        const value = moment().add(1 as moment.DurationInputArg1, offset as moment.DurationInputArg2);
        return { value: moment(value).toDate() };
    }

    setDate({ value }: { value: Date }) {
        this.date = value;
    }

    populateDate(offset: 'week' | 'month' | 'year'): void {
        const $event = this.calculateDateValue(offset);
        this.setDate($event);
    }

    addDate(): void {
        this.save.emit({ date: this.date });
        this.modalRef.hide();
    }

}
