// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import {
    HttpRequest, HttpParams, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor, HttpParameterCodec
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { SessionsService } from '../shared/sessions/sessions.service';
import { Auth0Service } from '../shared/sessions/auth0.service';
import { ModalsService } from '../shared/modal-helper/modals.service';
import { PasswordExpiredComponent } from '../components/password-policy/components/password-expired.modal/password-expired.component';

class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {

    constructor(
        private Session: SessionsService,
        private Auth0: Auth0Service,
        private modalsService: ModalsService
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const params = new HttpParams({
            encoder: new CustomEncoder(),
            fromString: request.params.toString()
        });

        const { url } = request;

        return this.Auth0.getAccessTokenSilently().pipe(
            concatMap((token: string) => {
                const requestClone = request.clone({
                    params,
                    headers: request.headers.set('Authorization', `Bearer ${token}`),
                    url
                });

                return next.handle(requestClone);
            }),
            catchError((error: HttpErrorResponse) => {
                const { status, headers } = error;
                if (status === 401) {
                    this.Session.destroy();
                }

                const passwordExpiredHeaderExists = headers?.get('x-fhc-password-expired');
                if (status === 403 && passwordExpiredHeaderExists) {
                    this.showPasswordExpiredModal();
                }
                return throwError(error);
            })
        );
    }

    private showPasswordExpiredModal(): void {
        this.modalsService.show(PasswordExpiredComponent, {
            initialState: {},
            class: 'modal-md'
        });
    }
}
