import { Folder, Binder } from '@app/shared/models';

export class CreateNamesListModalWrapper {
    public modalInstance: ng.ui.bootstrap.IModalInstanceService;
    public resolve: {
        destination: Binder | Folder;
        customNames: string[];
    };

    onDismissed(names: string[]): void {
        this.modalInstance.dismiss(names);
    }
}
