import { Transition } from '@uirouter/angularjs';
import { Team } from '@app/shared/models';

class InactivityWarningController {
    resolve: {
        team: Team
    };

    inactivityLogOffTime: number;

    $onInit(): void {
        if (this.resolve.team && this.resolve.team.settings.inactivityTimeout) {
            this.inactivityLogOffTime = this.resolve.team.settings.inactivityTimeout;
        }
        else {
            this.inactivityLogOffTime = 15;
        }
    }

    constructor($rootScope, $transitions: Transition) {
        $rootScope.$on('IdleEnd', this._closeModal.bind(this));

        const deregister = $transitions.onExit({}, () => {
            this._closeModal();
            deregister();
        });
    }

    _closeModal(): void {
        this.modalInstance.dismiss('cancel');
    }
}

InactivityWarningController.$inject = ['$rootScope', '$transitions'];

export default InactivityWarningController;
