import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
    AddLabelValuesParams,
    CheckIsAssigned,
    CheckIsAssignedResponse,
    DeleteLabelParams,
    DeleteLabelValuesParams,
    Label,
    LabelsServiceBaseParams,
    LabelValue,
    UpdateLabelPropsParams,
    UpdateLabelValues
} from './labels.service.types';
import { BinderLabel, LabeledEntity } from '../models';

@Injectable()
export class LabelsService {
    readonly url = {
        base: (teamId: string): string => `/api/teams/${teamId}/labels`,
        single: (teamId: string, labelId: string): string => `/api/teams/${teamId}/labels/${labelId}`,
        values: (teamId: string, labelId: string): string => `/api/teams/${teamId}/labels/${labelId}/values`,
        isAssigned: (teamId: string, labelId: string): string => `/api/teams/${teamId}/labels/${labelId}/is-assigned`,
        assignment: (teamId: string): string => `/api/teams/${teamId}/labeled-entities`
    };

    constructor(private $http: HttpClient) { }

    getLabels(teamId: string): Observable<Label[]> {
        const url = this.url.base(teamId);
        return this.$http.get<{ items: Label[] }>(url)
            .pipe(pluck('items'));
    }

    getSingleLabel({ teamId, labelId }: LabelsServiceBaseParams): Observable<Label> {
        const url = this.url.base(teamId);
        return this.$http.get<{ items: Label[] }>(url)
            .pipe(
                map(({ items }) => items.find((label) => label.id === labelId))
            );
    }

    createLabel(teamId: string, label: Label): Observable<Label> {
        const url = this.url.base(teamId);
        return this.$http.post<Label>(url, label);
    }

    updateLabelProps({ teamId, labelId, updates }: UpdateLabelPropsParams): Observable<Label> {
        const { name, description } = updates;
        const params: UpdateLabelPropsParams['updates'] = { };
        if (name) {
            params.name = name;
        }
        if (description || description === '') {
            params.description = description;
        }
        const url = this.url.single(teamId, labelId);

        return this.$http.patch<Label>(url, params);
    }

    addLabelValues({ teamId, labelId, addedValues }: AddLabelValuesParams): Observable<LabelValue[]> {
        const url = this.url.values(teamId, labelId);
        return this.$http.post<LabelValue[]>(url, addedValues);
    }

    deleteLabelValues({ teamId, labelId, removedValues = [] }: DeleteLabelValuesParams): Observable<void> {
        const url = this.url.values(teamId, labelId);
        const options = { body: removedValues };
        return this.$http.request<void>('DELETE', url, options);
    }

    updateLabelValues({ teamId, labelId, updatedValues = [] }: UpdateLabelValues): Observable<LabelValue[]> {
        const url = this.url.values(teamId, labelId);
        return this.$http.patch<LabelValue[]>(url, updatedValues);
    }

    deleteLabel({ teamId, labelId, reason }: DeleteLabelParams): Observable<void> {
        const url = this.url.single(teamId, labelId);
        const options = { params: { reason } };
        return this.$http.delete<void>(url, options);
    }

    checkIsAssigned({ teamId, labelId, values = [] }: CheckIsAssigned): Observable<CheckIsAssignedResponse> {
        const url = this.url.isAssigned(teamId, labelId);
        const params = values && values.reduce((httpParam, param) => {
            return param ? httpParam.append('valueIds', param) : httpParam;
        }, new HttpParams());
        const options = { params };
        return this.$http.get<boolean>(url, options).pipe(
            map((res) => ({ isAssigned: res }))
        );
    }

    removeAssignedLabels(teamId: string, removedLabels: BinderLabel[]): Observable<void> {
        const url = this.url.assignment(teamId);
        const options = {
            body: removedLabels
        };
        return this.$http.request<void>('DELETE', url, options);
    }

    assignLabels(teamId: string, addedLabels: BinderLabel[]): Observable<LabeledEntity[]> {
        const url = this.url.assignment(teamId);
        return this.$http.post<LabeledEntity[]>(url, addedLabels);
    }
}
