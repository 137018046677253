import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { SharedModule } from '@app/shared/shared.module';
import { WidgetsModule } from '@app/widgets/widgets.module';

import { HttpClientModule } from '@angular/common/http';
import {
    MatChipsModule, MatIconModule, MatFormFieldModule, MatInputModule
} from '@angular/material';
import { SingleSignOnComponent } from './components/single-sign-on/single-sign-on.component';
import { TeamSipLinkCreateComponent } from './components/team-sip-link-create/team-sip-link-create.component';
import { ManageTeamSipLinksComponent } from './components/manage-team-sip-links/manage-team-sip-links.component';
import { ManageTeamStudiesComponent } from './components/manage-team-studies/manage-team-studies.component';
import { SingleSignOnService } from './single-sign-on.service';
import { TeamSipLinksService } from './team-sip-links.service';
import { ManageTeamSecurityComponent } from './components/manage-team-security/manage-team-security.component';
import { ManageTeamStudiesContainer } from './containers/manage-team-studies/manage-team-studies-container.component';
import { LabelUpdateComponent } from './components/label-update/label-update.component';
import { LabelCreateComponent } from './components/label-create/label-create.component';
import { ManageTeamProfileComponent } from './components/manage-team-profile/manage-team-profile.component';
import { DayPickerComponent } from './components/day-picker/day-picker.component';
import { AccessDatesReportComponent } from './components/access-dates-report/access-dates-report.component';
import { AddUsersComponent } from './components/add-users/add-users.component';

@NgModule({
    imports: [
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot(),
        CommonModule,
        SharedModule,
        WidgetsModule,
        UIRouterModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule
    ],
    providers: [
        { provide: 'Window', useValue: window },
        SingleSignOnService,
        TeamSipLinksService
    ],
    declarations: [
        SingleSignOnComponent,
        TeamSipLinkCreateComponent,
        ManageTeamSipLinksComponent,
        ManageTeamStudiesComponent,
        ManageTeamSecurityComponent,
        ManageTeamStudiesContainer,
        LabelUpdateComponent,
        LabelCreateComponent,
        ManageTeamProfileComponent,
        DayPickerComponent,
        AccessDatesReportComponent,
        AddUsersComponent
    ],
    entryComponents: [
        SingleSignOnComponent,
        TeamSipLinkCreateComponent,
        ManageTeamSipLinksComponent,
        ManageTeamStudiesComponent,
        ManageTeamSecurityComponent,
        ManageTeamStudiesContainer,
        LabelUpdateComponent,
        LabelCreateComponent,
        ManageTeamProfileComponent,
        DayPickerComponent,
        AccessDatesReportComponent,
        AddUsersComponent
    ],
    exports: [
    ]
})
export class TeamsModule {}
