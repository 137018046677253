const regex = {
    email: /^(([^<>()[\]\.%`,;:\s@\"]+(\.[^<>()[\]\.%`,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.%`,;:\s@\"]+\.)+[^<>()[\]\.%`,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
    names: /^[^\\/:<>"|?*´§¤°]{1,250}$/,
    longText: /^[a-zA-Z\u00C0-\u1EF30-9-_!.,;@#$%^&+=~`é{}()';[\]\s]{1,2000}$/,
    extremelyLongText: /^[a-zA-Z\u00C0-\u1EF30-9-_!.,;@#$%^&+=~`é{}()';[\]\s]{1,10000}$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\S]{8,}$/,
    password10: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\S]{10,}$/,
    signingPasscode: /.{4,}/,
    dates: {
        day: /^\d{1,2}$/,
        year: /^\d{4}$/
    },
    listSeparators: /[;\s|]/g,
    tagName: RegExp(/^((?![>^:!#]).)*$/),
    labelNameValue: RegExp(/^((?![>^:!#]).)*$/),
    documentType: RegExp(/^((?![>^:!#]).)*$/),
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, // eslint-disable-line no-useless-escape
    phone: /^[-+\d()/ ]+$/,
    noLeadingOrTrailingWithespace: /^\S$|^\S[ \S]*\S$/,
    noSpecialCharsNoLengthLimit: /^[^\\/:<>"|?*´§¤°]+$/,
    domain: /(^([^<>()[\]\.%`,;:\s@\"]+\.)+[^<>()[\]\.%`,;:\s@\"]{2,})$/i // eslint-disable-line no-useless-escape
};

export default regex;
