const idleDirective = (
    $interval: ng.IIntervalService,
    $timeout: ng.ITimeoutService,
    $window: ng.IWindowService,
    Idle
): ng.IDirective => ({
    restrict: 'A',
    link(scope: ng.IScope, elem: ng.IAugmentedJQuery): void {
        let timeout;
        let timestamp = $window.localStorage.lastEventTime;

        const fiveHundredMillseconds = 500;
        const fiveSeconds = 5000;

        // Need to watch the element for any interruption events
        // but don't want to check on every event, so add 500ms debounce.
        elem.on(Idle._options().interrupt, () => {
            if (timeout) {
                $timeout.cancel(timeout);
            }

            timeout = $timeout(() => {
                $window.localStorage.setItem('lastEventTime', new Date().getTime().toString());
            }, fiveHundredMillseconds);
        });

        // Every 5s, check to see if any tab has had activity.
        // If so, reset the idle timer.
        $interval(() => {
            if ($window.localStorage.lastEventTime > timestamp) {
                Idle.watch();
                timestamp = $window.localStorage.lastEventTime;
            }
        }, fiveSeconds);
    }
});

idleDirective.$inject = [
    '$interval',
    '$timeout',
    '$window',
    'Idle'
];

export default idleDirective;
