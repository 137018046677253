import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';

import { studiesRoute } from './studies.route';
import { StudyViewComponent } from './containers/study-view/study-view.component';
import { StudyDetailsComponent } from './components/study-details/study-details.component';
import { StudyFormComponent } from './components/study-form/study-form.component';
import { StudyLinkEntitiesComponent } from './components/study-link-entities/study-link-entities.component';
import { MonitorGroupsViewComponent } from './containers/monitor-groups/monitor-groups-view.component';
import { MonitorGroupFormComponent } from './containers/monitor-group-form/monitor-group-form.component';
import MonitorGroupsListModalWrapperComponent from './components/monitor-groups-list/monitor-groups-list-modal.wrapper';
import { MonitorGroupsListModalComponent } from './components/monitor-groups-list/monitor-groups-list-modal.component';

const dependencies = [
    uiRouter
];

export const studiesModule = angular
    .module('app.studies', dependencies)
    .directive('studyView', downgradeComponent({ component: StudyViewComponent }) as ng.IDirectiveFactory)
    .component('monitorGroupsListModalWrapper', MonitorGroupsListModalWrapperComponent)
    .directive('monitorGroups', downgradeComponent({ component: MonitorGroupsViewComponent }) as ng.IDirectiveFactory)
    .directive('studyLinkEntities', downgradeComponent({ component: StudyLinkEntitiesComponent }) as ng.IDirectiveFactory)
    .directive('monitorGroupForm', downgradeComponent({ component: MonitorGroupFormComponent }) as ng.IDirectiveFactory)
    .directive('monitorGroupsListModal', downgradeComponent({ component: MonitorGroupsListModalComponent }) as ng.IDirectiveFactory)
    .directive('studyForm', downgradeComponent({ component: StudyFormComponent }) as ng.IDirectiveFactory)
    .directive('studyDetails', downgradeComponent({ component: StudyDetailsComponent }) as ng.IDirectiveFactory)
    .config(studiesRoute);
