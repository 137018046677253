export class SigningPinExpiryNotificationWrapperController {
    public modalInstance: ng.ui.bootstrap.IModalInstanceService;
    public resolve: {
        signingPinExpired: boolean;
    };

    onDismissed(): void {
        this.modalInstance.close();
    }
}
