import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';

import { CHECKBOX_STATES } from '@app/core/constants';
import { SignatureTypes, SigningReasons } from '@app/shared/models';
import template from './documents-pending-signatures-tab.component.html';
import styles from './documents-pending-signatures-tab.component.scss';
import { DocumentsPendingSignatureTabDataRow, DocumentsPendingSignatureRequestsDataRow, PendingSignatureRequest } from './documents-pending-signatures-tab.types';

@Component({
    selector: 'documents-pending-signatures-tab',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: '$scope', useExisting: '$rootScope' }] // date-time-picker workaround
})
export class DocumentsPendingSignaturesTabComponent {
    @Output() cancelRequests = new EventEmitter<{ ids: string[] }>();
    @Output() undoChanges = new EventEmitter<{ ids: string[] }>();
    @Output() changesSignByDate = new EventEmitter<{ date: Date; id: string }>();
    @Output() changesNotifyRequestor = new EventEmitter<{ notify: boolean; id: string }>();
    @Output() changesRemindSigner = new EventEmitter<{ remind: boolean; id: string }>();
    @Output() changesReason = new EventEmitter<{ reason: SigningReasons; id: string }>();
    @Output() changesSignatureType = new EventEmitter<{ type: SignatureTypes; id: string }>();
    @Input() data: DocumentsPendingSignatureTabDataRow[];

    checkedIds: Set<string> = new Set();
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    signingReasons = Object.values(SigningReasons).sort();
    pendingSignaturesCount = 0;
    documentsHaveFixedSignatureType: boolean;
    readonly currentDate = new Date();

    ngOnInit(): void {
        this.refresh();
    }

    onCancelRequest(): void {
        this.onUndoChanges();
        this.cancelRequests.emit({ ids: Array.from(this.checkedIds) });
    }

    onUndoChanges(): void {
        this.undoChanges.emit({ ids: Array.from(this.checkedIds) });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !changes.data.isFirstChange()) {
            this.pendingSignaturesCount = 0;
            this.refresh();
        }
    }

    toggleCheckedAllRows(): void {
        if (
            this.headerCheckboxState === CHECKBOX_STATES.NOT_SELECTED
            || this.headerCheckboxState === CHECKBOX_STATES.PARTIALLY_SELECTED
        ) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
            this.data.forEach((d: DocumentsPendingSignatureRequestsDataRow) => {
                if (d.pendingSignatureRequest) {
                    d.pendingSignatureRequest.checked = this.headerCheckboxState;
                    this.checkedIds.add(d.pendingSignatureRequest.id as string);
                }
            });
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
            this.data.forEach((d: DocumentsPendingSignatureRequestsDataRow) => {
                if (d.pendingSignatureRequest) {
                    d.pendingSignatureRequest.checked = this.headerCheckboxState;
                    this.checkedIds.delete(d.pendingSignatureRequest.id as string);
                }
            });
        }
    }

    onSignatureTypeSelect(type: SignatureTypes, pendingRequest: PendingSignatureRequest): void {
        pendingRequest.method = type;
        this.changesSignatureType.emit({ type, id: pendingRequest.id });
    }

    onSignatureReasonSelect(reason: SigningReasons, pendingRequest: PendingSignatureRequest): void {
        pendingRequest.reason = reason;
        this.changesReason.emit({ reason, id: pendingRequest.id });
    }

    onSignByDateSelect({ value }, id: string): void {
        this.changesSignByDate.emit({ date: value, id });
    }

    private handleHeaderCheckboxStateOnRowChange(): void {
        if (!this.checkedIds.size) {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
        }
        else if (this.checkedIds.size === this.pendingSignaturesCount) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.PARTIALLY_SELECTED;
        }
    }

    toggleCheckedRow(pendingRequest: PendingSignatureRequest): void {
        if (pendingRequest.checked && pendingRequest.checked === CHECKBOX_STATES.SELECTED) {
            pendingRequest.checked = CHECKBOX_STATES.NOT_SELECTED;
            this.checkedIds.delete(pendingRequest.id as string);
        }
        else {
            pendingRequest.checked = CHECKBOX_STATES.SELECTED;
            this.checkedIds.add(pendingRequest.id as string);
        }
        this.handleHeaderCheckboxStateOnRowChange();
    }

    onNotifyChanged(pendingRequest: PendingSignatureRequest): void {
        if (pendingRequest.notifyRequestorWhenSigned && pendingRequest.notifyRequestorWhenSigned === true) {
            pendingRequest.notifyRequestorWhenSigned = false;
        }
        else {
            pendingRequest.notifyRequestorWhenSigned = true;
        }
        this.changesNotifyRequestor.emit({ notify: pendingRequest.notifyRequestorWhenSigned, id: pendingRequest.id });
    }

    onRemindSignerChanged(pendingRequest: PendingSignatureRequest): void {
        if (pendingRequest.remindSigner && pendingRequest.remindSigner === true) {
            pendingRequest.remindSigner = false;
        }
        else {
            pendingRequest.remindSigner = true;
        }
        this.changesRemindSigner.emit({ remind: pendingRequest.remindSigner, id: pendingRequest.id });
    }

    private refresh(): void {
        let fixedSignatureTypeCount = 0;
        this.data.forEach((row: DocumentsPendingSignatureRequestsDataRow) => {
            if (row.signatureTypeOptions && row.signatureTypeOptions.length === 1) {
                fixedSignatureTypeCount += 1;
            }

            if (row.pendingSignatureRequest) {
                this.pendingSignaturesCount += 1;
            }

            if (row.pendingSignatureRequest && row.pendingSignatureRequest.checked) {
                this.checkedIds.add(row.pendingSignatureRequest.id as string);
            }
        });
        this.documentsHaveFixedSignatureType = !!fixedSignatureTypeCount;
        this.handleHeaderCheckboxStateOnRowChange();
    }
}
