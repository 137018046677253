import { CurrentSessionService } from '@app/core/current-session.service';
import { UsersService } from '@app/shared/users/users.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import {
    User, Team, UserPrivacyConsent, AuditTrailSubject
} from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ModalHelperService } from '@app/shared/modal-helper/modal-helper.service';
import { ReasonEvent } from '@app/widgets/reason/reason-modal.component.types';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';

export class UserProfileController {
    user: User;
    currentTeam: Team;
    reason: string;
    crumbs = [{ name: 'User Profile' }];

    constructor(
        private Users: UsersService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService,
        private AuditTrail: AuditTrailService,
        private modalHelper: ModalHelperService,
        private ApiError: ApiErrorFactory,
        private Modals: ModalsService
    ) { }

    $onInit(): void {
        this.user = this.CurrentSession.getCurrentUser();
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    openAuditTrailModal(): void {
        const item = {
            id: this.user.id
        };

        const params = {
            subject: AuditTrailSubject.USER_PROFILE,
            teamId: null,
            objectId: item.id,
            overwrittenObjectId: null,
            limitToOverwritten: false,
            ...this.AuditTrail.auditPagination
        };

        this.AuditTrail.getAudits(params).toPromise().then((audits) => {
            this.Modals.show(AuditTrailModalComponent, {
                class: 'modal-lg',
                initialState: {
                    data: audits,
                    item,
                    subject: AuditTrailSubject.USER_PROFILE,
                    pagination: this.AuditTrail.auditPagination,
                    onPageChange: this.AuditTrail.getAudits.bind(this.AuditTrail)
                }
            });
        }).catch(this.ApiError.handleError);
    }

    saveUserProfileUpdate({ profile, onSave }): ng.IPromise<void> {
        const isUserNameChange = Boolean(profile && (profile.firstName || profile.lastName));

        return this.modalHelper.open<void>({
            animation: false,
            size: 'md',
            component: 'reason-modal-wrapper',
            resolve: {
                header: (): string => 'Reason for Change',
                reasonLabel: (): string => 'Please provide a reason for the recent profile changes:',
                onSubmit: () => ({ data: { reason }, onSuccess, onError }: ReasonEvent): void => {
                    this.Users.update(this.user.id, { profile: { ...profile, reason } })
                        .subscribe((user) => {
                            onSave();
                            onSuccess();
                            if (user) {
                                this.user = { ...this.user, ...user };
                                this.CurrentSession.setCurrentUser(this.user);
                                this.Notifications.success('User Profile Successfully updated!');
                            }
                        }, onError);
                }
            }
        });
    }

    saveUserPrivacyConsentUpdate(privacyConsent: Partial<UserPrivacyConsent>): ng.IPromise<void> {
        return this.Users.update(this.user.id, { privacyConsent }).toPromise()
            .then((user) => {
                if (user) {
                    this.user = { ...this.user, ...user };
                    this.CurrentSession.setCurrentUser(this.user);
                    this.Notifications.success('Your preferences have been saved. You can always update your communication and cookie preferences on your Profile Page under the Privacy Options tab.');
                }
            });
    }
}

UserProfileController.$inject = [
    'Users',
    'CurrentSession',
    'Notifications',
    'AuditTrail',
    'modalHelper',
    'ApiError',
    'ModalsService'
];
