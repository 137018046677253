import * as _ from 'lodash';
import {
    Observable, BehaviorSubject, forkJoin, of, throwError
} from 'rxjs';
import {
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import {
    switchMap, catchError, tap, filter
} from 'rxjs/operators';
import { StateService } from '@uirouter/angularjs';
import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Team, Study, StudyEntity, Crumb, Paywall
} from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StudiesService } from '@app/shared/studies/studies.service';
import { LabelsService } from '@app/shared/labels/labels.service';
import {
    UpdateLinkedEntitiesParams, UpdateLinkedEntitiesResponse, BulkSiteActionResponse, UpdateMonitorGroupStudyIdsParams
} from '@app/shared/studies/studies.service.types';
import { Label } from '@app/shared/labels/labels.service.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { WarningModalComponent } from '@app/widgets/warning-modal/warning-modal.component';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { messages } from './study-view-notifications';
import { Tabs, State } from './study-view.types';
import template from './study-view.component.html';
import styles from './study-view.component.scss';
@Component({
    selector: 'study-view',
    styles: [String(styles)],
    template
})


export class StudyViewComponent implements OnInit, OnDestroy {
    afterSaveGoTo = ''
    studyProfileEntryNavMenuLocation = false

    private study = new BehaviorSubject<Partial<Study>>(undefined);
    public study$ = this.study.asObservable();
    private uniqueProtocolIds = new BehaviorSubject<string[]>(undefined);
    public uniqueProtocolIds$ = this.uniqueProtocolIds.asObservable();
    private studyMonitorGroups = new BehaviorSubject<Partial<Paywall[]>>(undefined);
    public studyMonitorGroups$ = this.studyMonitorGroups.asObservable();
    currentTeam: Team;
    // bindings
    public crumbs: Crumb[] = [];
    public activeIndex = 0;
    public loadingStudy = false;
    private uniqueProtocolIdLabel: Label;
    private shouldProceedAfterWarning = false;
    private isStudySiteTeamEnabled = false;
    public tabs: Tabs = {
        activeIndex: 0,
        studyForm: {
            heading: 'Edit Study',
            index: 0,
            state: 'app.team.study-edit',
            permission: 'createEditTeamStudyProfiles'
        },
        studyDetails: {
            heading: 'Connect Study',
            index: 1,
            state: 'app.team.study-connect',
            permission: 'viewTeamStudyProfiles'
        }
    }

    private studyParams: { study: Study; next: boolean } = {
        study: null,
        next: false
    }

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Studies: StudiesService,
        private Notifications: NotificationsService,
        private Labels: LabelsService,
        private modalsService: ModalsService,
        private FeatureFlags: FeatureFlagService
    ) {}

    ngOnDestroy(): void {
        this.study.complete();
        this.uniqueProtocolIds.complete();
        this.studyMonitorGroups.complete();
    }

    ngOnInit(): void {
        this.getStudyProfileEntryFlag();
        this.getStudySiteTeamFeatureFlag();

        this.currentTeam = this.CurrentSession.getCurrentTeam();
        const stateParams = this.$state.params;
        const activeTab = this.getCurrentTab();
        if (!this.hasPermission(activeTab.permission)) {
            this.$state.go('home');
        }

        if (stateParams.studyId) {
            this.getStudy(this.currentTeam.id, stateParams.studyId);
            this.getStudyMonitorGroups(this.currentTeam.id, stateParams.studyId);
        }
        else {
            this.study.next({});
            this.studyMonitorGroups.next([]);
        }
        this.getUniqueProtocolIds(this.currentTeam.id);
        this.getUniqueProtocolIdLabel(this.currentTeam.id);
        this.crumbs = this.getCrumbs(this.study.getValue());
    }

    getStudyProfileEntryFlag(): void {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_PROFILE_SIDEMENU_ENTRY, false).pipe(
            filter((flag) => {
                return flag !== undefined;
            })
        ).subscribe((value) => {
            this.studyProfileEntryNavMenuLocation = value;
            this.afterSaveGoTo = value ? 'app.team.manage-studies-by-team' : 'app.team.manage-team-studies';
        });
    }

    getStudySiteTeamFeatureFlag(): void {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.EBINDERS_STUDY_SITE_TEAM, false).pipe(
            filter((flag) => {
                return flag !== undefined;
            })
        ).subscribe((value) => {
            this.isStudySiteTeamEnabled = value;
        });
    }

    getCurrentTab(): State {
        if (this.$state.current.name === 'app.team.study-connect') {
            this.activeIndex = this.tabs.studyDetails.index;
            return this.tabs.studyDetails;
        } if (this.$state.current.name === 'app.team.study-edit') {
            this.activeIndex = this.tabs.studyForm.index;
            return this.tabs.studyForm;
        }
        return this.tabs.studyForm;
    }

    hasPermission(permission: string): boolean {
        return this.currentTeam && this.currentTeam.permissions[permission];
    }

    onSubmit({ study, next, uniqueProtocolIdChanged }: {study: Study; next: boolean; uniqueProtocolIdChanged: boolean}): void {
        if (study) {
            this.studyParams.study = study;
            this.studyParams.next = next;
            const uniqueProtocolId = this.getStudyUniqueProtocolId(study);
            const UPIDExistsAsLabelValue = this.currentTeam.settings.features.labels
             && this.isUPIDSameAsLabelValue(uniqueProtocolId);
            (uniqueProtocolIdChanged && UPIDExistsAsLabelValue) ? this.openLabelExistModal(uniqueProtocolId) : this.patchStudy();
        }
    }

    private patchStudy(): void {
        const fn = this.studyParams.study.id ? this.updateStudy.bind(this) : this.createStudy.bind(this);
        fn(this.currentTeam.id, this.studyParams.study, this.studyParams.next);
    }

    private isUPIDSameAsLabelValue(uniqueProtocolId: string): boolean {
        if (!this.uniqueProtocolIdLabel) {
            return false;
        }
        const labelValues = this.uniqueProtocolIdLabel.values.map((v) => v.value);
        const matchedValue = this.uniqueProtocolIdLabel.values.find((v) => v.value === uniqueProtocolId);
        return labelValues.includes(uniqueProtocolId) || !!(matchedValue && matchedValue.id);
    }

    private openLabelExistModal(uniqueProtocolId: string): boolean {
        const { id: matchedValueId } = this.uniqueProtocolIdLabel.values.find((v) => v.value === uniqueProtocolId);
        this.Labels.checkIsAssigned({
            teamId: this.currentTeam.id,
            labelId: this.uniqueProtocolIdLabel.id,
            values: [matchedValueId]
        }).subscribe(({ isAssigned }) => {
            isAssigned ? this.openWarningModal(uniqueProtocolId) : this.openInfoModal(uniqueProtocolId);
        });
        return this.shouldProceedAfterWarning;
    }

    private openWarningModal(uniqueProtocolId: string): void {
        this.modalsService.show(WarningModalComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                header: 'Label already exists',
                content: `
                    <p>Your team already has a Unique Protocol ID label with value ${uniqueProtocolId} that is assigned to certain binders or folders.</p>
                    <p>To create the study profile, please remove the label value from those binders or folders first.</p>
                    <p class="strong">Tip:</p>
                    <p>In the binder/folder view, from the Actions button select Rename/Update and remove Unique Protocol ID: ${uniqueProtocolId}</p>
                `,
                primaryButton: 'Got It'
            }
        });
    }

    private openInfoModal(uniqueProtocolId: string): void {
        const warningModal = this.modalsService.show(WarningModalComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                header: 'Label already exists',
                content: `
                    <p>Your team already has a Unique Protocol ID label with value ${uniqueProtocolId}.</p>
                    <p>Please note if you proceed with this label value, it will override the existing one.</p>
                `,
                primaryButton: 'Proceed',
                secondaryButton: 'Cancel'
            }
        });
        warningModal.content.save.subscribe(() => {
            this.patchStudy();
        });
    }

    private getStudyUniqueProtocolId(study: Study): string {
        if (study && study.uniqueProtocolId) {
            return study.uniqueProtocolId;
        }
        let uniqueProtocolId: string;
        this.study.subscribe((s) => {
            uniqueProtocolId = s.uniqueProtocolId;
        });
        return uniqueProtocolId;
    }

    loadStudyEntities = (teamId: string, studyId: string, siteId?: string): Observable<StudyEntity[]> => {
        if (siteId) {
            return this.Studies.getSiteLinkedEntities(teamId, studyId, siteId);
        }
        return this.Studies.getStudyLinkedEntities(teamId, studyId);
    }

    linkStudyEntities = (
        teamId: string,
        studyId: string,
        siteId: string | undefined,
        updates: UpdateLinkedEntitiesParams
    ): Observable<UpdateLinkedEntitiesResponse> => {
        const successNotificaiton = tap<UpdateLinkedEntitiesResponse>((resp) => {
            if ([...resp.add, ...resp.remove].some((r) => r.statusCode !== 200)) {
                this.Notifications.error(messages.entitiesLinkErr);
                return;
            }
            this.Notifications.success(messages.entitiesLink);
        });
        const errorNotification = catchError<never, Observable<UpdateLinkedEntitiesResponse>>(({ error }) => {
            let msg = messages.entitiesLinkErr;
            if (error.statusCode === 413) {
                msg = error.message;
            }
            this.Notifications.error(msg);
            return of({
                add: ([] as UpdateLinkedEntitiesResponse),
                remove: ([] as UpdateLinkedEntitiesResponse)
            } as UpdateLinkedEntitiesResponse);
        });

        if (siteId) {
            return this.Studies.updateSiteLinkedEntities(teamId, studyId, siteId, updates)
                .pipe(successNotificaiton, errorNotification);
        }
        return this.Studies.updateStudyLinkedEntities(teamId, studyId, updates)
            .pipe(successNotificaiton, errorNotification);
    }

    linkMonitorGroups = (
        teamId: string,
        studyId: string,
        updates: UpdateMonitorGroupStudyIdsParams
    ): Observable<Paywall[]> => {
        const successNotificaiton = tap<Paywall[]>(() => {
            this.Notifications.success(messages.monitorGroupsLinked);
        });
        const errorNotification = catchError(() => {
            this.Notifications.error(messages.monitorGroupsLinkedErr);
            return of(null);
        });

        return this.Studies
            .updateMonitorGroupStudies({
                teamId,
                studyId,
                update: {
                    added: updates.added,
                    removed: updates.removed
                }
            })
            .pipe(successNotificaiton, errorNotification);
    }

    setActiveTab(index: number): void {
        this.activeIndex = index;
        const currentState = this.activeIndex === this.tabs.studyDetails.index
            ? this.tabs.studyDetails.state
            : this.tabs.studyForm.state;

        const study = this.study.getValue();
        if (!study || _.isEmpty(study)) {
            return;
        }
        this.$state.go(currentState, { teamId: this.currentTeam.id, studyId: study.id });
    }

    private getCrumbs(study?: Partial<Study>): Crumb[] {
        let crumbs:Crumb[] = [];
        if (this.studyProfileEntryNavMenuLocation) {
            crumbs = [{
                name: 'Studies',
                stateName: 'app.team.manage-studies-by-team',
                stateParams: { teamId: this.currentTeam.teamId }
            }];
        }
        else {
            crumbs = [{
                name: 'Team Settings',
                stateName: 'app.team.manage-team-studies',
                stateParams: { teamId: this.currentTeam.teamId }
            }];
        }
        if (study !== undefined) {
            crumbs.push({ name: _.isEmpty(study) ? 'Create Study' : `${study.uniqueProtocolId}: ${study.nickname}` });
        }
        return crumbs;
    }

    private getUniqueProtocolIds(teamId: string): void {
        this.Studies.getStudies(teamId, { withPagination: false })
            .subscribe(({ items: studies }) => {
                this.uniqueProtocolIds.next(studies.map((s) => s.uniqueProtocolId));
            });
    }

    private getUniqueProtocolIdLabel(teamId: string): void {
        if (!this.currentTeam.settings.features.labels) {
            return;
        }
        this.Labels.getLabels(teamId)
            .subscribe((labels) => {
                this.uniqueProtocolIdLabel = labels.find((l) => l.name === 'Unique Protocol ID');
            });
    }

    private getStudy(teamId: string, studyId: string): void {
        this.loadingStudy = true;
        forkJoin({
            study: this.Studies.getStudy(teamId, studyId),
            sites: this.Studies.getStudySites(teamId, studyId)
        }).subscribe((r) => {
            const sortedSites = r.sites.sort((s1, s2) => {
                const a = s1.siteName.toUpperCase();
                const b = s2.siteName.toUpperCase();
                if (!a) {
                    return 1;
                }
                return !b ? -1 : a.localeCompare(b);
            });

            this.loadingStudy = false;
            this.crumbs = this.getCrumbs(r.study);
            this.study.next({
                ...r.study,
                sites: sortedSites
            });
        });
    }

    private createStudy(teamId: string, study: Study, next: boolean): void {
        let respStudy: Study;

        this.Studies.createStudy(teamId, _.omit(study, 'sites')).pipe(
            tap(() => {
                this.Notifications.success(messages.studyCreated);
            }),
            switchMap((created) => {
                respStudy = created;
                return this.Studies.createStudySites(teamId, created.id, _.pick(study, 'sites').sites, this.isStudySiteTeamEnabled);
            }),
            tap<BulkSiteActionResponse[]>((resp) => {
                if (resp.some((r) => r.statusCode !== 200)) {
                    this.Notifications.error(messages.sitesCreatedErr);
                    return;
                }
                this.Notifications.success(messages.sitesCreated);
            })
        ).subscribe((value) => {
            this.study.next({
                ...respStudy,
                sites: [].concat(...value.map((v) => v.payload))
            });
            this.afterSave(respStudy.id, next);
        },
        (error: HttpErrorResponse) => {
            const message = error?.error?.message ?? messages.unknownErr;

            this.Notifications.error(message);

            if (error.status === 403) {
                this.$state.go('app.team.manage-studies-by-team');
            }
        });
    }

    private updateStudy(teamId: string, study: Study, next: boolean): void {
        const updatedStudy = _.omit(study, 'sites');
        const newSites = study.sites.filter((site) => !site.id);
        const oldSites = study.sites.filter((site) => site.id);

        const studySuccess = tap(() => {
            this.Notifications.success(messages.studyUpdated);
        });
        const studyError = catchError<never, Observable<Study>>((error: HttpErrorResponse) => {
            const message = (error && error.error && error.error.message);
            message ? this.Notifications.error(message) : this.Notifications.error(messages.studyUpdateErr);
            if (error.status === 403) {
                this.$state.go('app.team.manage-studies-by-team');
                return throwError(() => error);
            }
            return of({} as Study);
        });
        const siteUpdateSuccess = tap<BulkSiteActionResponse[]>((resp) => {
            if (resp.some((r) => r.statusCode !== 200)) {
                this.Notifications.error(messages.sitesUpdatedErr);
                return;
            }
            this.Notifications.success(messages.sitesUpdated);
        });
        const siteCreateSuccess = tap<BulkSiteActionResponse[]>((resp) => {
            if (resp.some((r) => r.statusCode !== 200)) {
                this.Notifications.error(messages.sitesCreatedErr);
                return;
            }
            this.Notifications.success(messages.sitesCreated);
        });

        forkJoin({
            study: Object.keys(updatedStudy).length > 1
                ? this.Studies.updateStudy(teamId, updatedStudy).pipe(studySuccess, studyError) : of({}),
            oldSites: oldSites.length
                ? this.Studies.updateStudySites(teamId, study.id, oldSites, this.isStudySiteTeamEnabled)
                    .pipe(siteUpdateSuccess, catchError((error: HttpErrorResponse) => {
                        this.Notifications.error(messages.unknownErr);
                        return throwError(() => error);
                    })) : of([]),
            newSites: newSites.length
                ? this.Studies.createStudySites(teamId, study.id, newSites, this.isStudySiteTeamEnabled)
                    .pipe(siteCreateSuccess, catchError((error: HttpErrorResponse) => {
                        this.Notifications.error(messages.unknownErr);
                        return throwError(() => error);
                    })) : of([])
        }).subscribe((r) => {
            const existingSites = this.study.getValue().sites;
            r.oldSites.map((v) => v.payload).forEach((site) => {
                Object.assign(existingSites.find((s) => s.id === site.id), site);
            });
            this.study.next({
                ..._.omit(study, 'sites'),
                ...r.study,
                sites: [
                    ...existingSites,
                    ...[].concat(...r.newSites.map((v) => v.payload))
                ]
            });
            this.afterSave(study.id, next);
        });
    }

    private getStudyMonitorGroups(teamId: string, studyId: string): void {
        this.Studies.getStudyMonitorGroups(teamId, studyId)
            .subscribe((r) => {
                this.studyMonitorGroups.next(r);
            });
    }

    private afterSave(studyId: string, next: boolean): void {
        if (next) {
            this.$state.go('app.team.study-connect', { teamId: this.currentTeam.id, studyId });
            return;
        }
        this.$state.go(this.afterSaveGoTo, { teamId: this.currentTeam.id });
    }
}
