
const TeamSipLinkCreateWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
    <team-sip-link-create
        [team]="vm.resolve.team"
        [sponsors]="vm.resolve.sponsors"
        (cancel)="vm.modalInstance.close('cancel')"
        (save)="vm.resolve.save($event)"
    ></team-sip-link-create>
    `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default TeamSipLinkCreateWrapperComponent;
