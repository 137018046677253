import {
    Component,
    EventEmitter, OnInit, Output
} from '@angular/core';
import { SORT, REGEX } from '@app/core/constants';
import * as _ from 'lodash';
import {
    FormArray, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { notBlank } from '@app/core/form-validators';
import style from './label-update.component.scss';
import template from './label-update.component.html';
import {
    Label, LabelInput, LabelValue, LabeUpdateEvent,
    ModelData
} from './label-update.component.types';
import {
    noLeadingTrailingSpaces,
    uniqueValueValidator
} from './label-update-validation.component';
@Component({
    template,
    styles: [String(style)]
})
export class LabelUpdateComponent implements OnInit {
    private _label: Label;
    set label(value: Label) {
        this._label = _.cloneDeep(value);
        this._label.values = this._label.values.sort((a, b) => {
            return this.SORT.naturalSort({ value: a.value }, { value: b.value });
        });
    }

    get label(): Label {
        return this._label;
    }

    @Output() onUpdateLabel = new EventEmitter<LabeUpdateEvent>();

    maxLength = 250;
    isProcessing = false;
    updateLabelForm: FormGroup
    addValueForm: FormGroup;
    newValueError = `Label and values must be unique, less than ${this.maxLength} characters and cannot contain characters > : ! # ^ or be blank.`;
    originalLabelName: string;
    originalLabelDesc: string;
    originalValuesHash: Record<string, LabelValue>;
    originalValuesIdHash: Record<string, LabelValue>;

    removedValues: LabelValue[] = [];
    addedValues: LabelValue[] = [];
    updatedValues: LabelValue[] = [];
    SORT = SORT;
    constructor(
        private fb: FormBuilder,
        private Modal: BsModalRef
    ) {}

    ngOnInit(): void {
        this.originalLabelName = this.label.name;
        this.originalLabelDesc = this.label.description;
        this.originalValuesHash = _.keyBy(this.label.values, 'value');
        this.originalValuesIdHash = _.keyBy(this.label.values, 'id');
        this.initAddForm();
        this.initUpdateForm();
        this.applyUniqueValueValidator();
    }

    applyUniqueValueValidator(): void {
        this.inputsLabels.controls.forEach((control) => {
            control.get('currentValue')?.setValidators([
                Validators.required,
                notBlank,
                Validators.maxLength(this.maxLength),
                Validators.pattern(REGEX.labelNameValue),
                noLeadingTrailingSpaces(),
                uniqueValueValidator(this.inputsLabels)
            ]);
            control.get('currentValue')?.updateValueAndValidity();
        });
    }

    cancel(): void {
        this.Modal.hide();
    }

    initAddForm(): void {
        this.addValueForm = this.fb.group({
            newValue: ['', [Validators.required, Validators.maxLength(this.maxLength), Validators.pattern(REGEX.labelNameValue), notBlank]]
        });
    }

    initUpdateForm(): void {
        this.updateLabelForm = this.fb.group({
            name: [{ value: this.originalLabelName, disabled: this.label.isPredefined },
                [Validators.required, notBlank, Validators.maxLength(this.maxLength), Validators.pattern(REGEX.labelNameValue)]],
            inputsLabels: this.fb.array(this.label.values.map((value) => this.createValueForm(value))),
            description: [{ value: this.originalLabelDesc, disabled: this.label.isPredefined },
                [Validators.maxLength(this.maxLength)]]
        });
    }

    get inputsLabels(): FormArray {
        return this.updateLabelForm?.get('inputsLabels') as FormArray;
    }

    createValueForm(labelValue: LabelValue): FormGroup {
        return this.fb.group({
            id: [labelValue.id],
            currentValue: [labelValue.value, [
                Validators.required,
                notBlank,
                Validators.maxLength(this.maxLength),
                Validators.pattern(REGEX.labelNameValue),
                noLeadingTrailingSpaces(),
                uniqueValueValidator(this.inputsLabels)
            ]],
            isEditing: [labelValue.isEditing],
            studyId: [labelValue.studyId]

        });
    }

    handleRenamedValue(label:LabelInput): void {
        if (label.currentValue.errors !== null) {
            return;
        }
        const { currentValue: { value: newValue } } = label;
        if (label.id.value && this.updateLabelForm.dirty) {
            const { id: { value: id } } = label;
            const updatedValue = this.originalValuesIdHash[id];
            if (updatedValue.value !== newValue) {
                this.label.values = this.label.values.map((item) => {
                    if (item.id === id) {
                        const newItem = { ...item, value: newValue };
                        this.updatedValues.push(newItem);
                    }
                    return item;
                });
            }
        }
    }

    validateAddedValue(): void {
        const { newValue } = this.addValueForm.value;
        const isDuplicated = this.updateLabelForm.value.inputsLabels.some((label) => label.currentValue === newValue);
        if (isDuplicated) {
            this.addValueForm.get('newValue').setErrors({ unique: true });
        }
    }

    removeValue(label: LabelInput, index: number): void {
        const { id: { value: id } } = label;
        const { currentValue: { value } } = label;
        this.inputsLabels.removeAt(index);
        this.updateLabelForm.markAsDirty();
        if (label.id.value) {
            this.label.values = this.label.values.filter((item) => item.value !== value);
            const removedValue = this.originalValuesIdHash[id];
            this.removedValues.push(removedValue);
        }
    }

    addValue(): void {
        if (this.addValueForm.invalid || this.addValueForm.pristine) {
            return;
        }

        const { id } = this.originalValuesHash[this.addValueForm.value.newValue] || {};
        const newValue = { value: this.addValueForm.value.newValue.trim(), id };
        if (id) {
            _.pullAllBy(this.removedValues, [{ id }], 'id');
            newValue.id = id;
        }
        this.label.values.push(newValue);
        this.inputsLabels.push(this.createValueForm(newValue));
        this.setUpForms();
    }

    setUpForms(): void {
        this.addValueForm.reset();
        this.updateLabelForm.markAsDirty();
    }

    /**
     * Retrieves the modal data for updating a label.
     * @returns The modal data object containing the removed, updated, and added values,
     *  as well as the updated name and description.
     */
    getModalData(): ModelData {
        const data: ModelData = {
            removedValues: this.removedValues,
            updatedValues: this.filterUpdatedValues(),
            addedValues: []
        };

        if (this.updateLabelForm.value.inputsLabels.length === this.label.values.length) {
            this.updateLabelForm.value.inputsLabels.forEach((el) => {
                if (!el.id) {
                    data.addedValues.push({
                        value: el.currentValue,
                        id: undefined
                    });
                }
            });
        }

        if (this.updateLabelForm.value.name !== this.originalLabelName) {
            data.name = this.updateLabelForm.value.name;
        }
        if (this.updateLabelForm.value.description !== this.originalLabelDesc) {
            data.description = this.updateLabelForm.value.description;
        }
        return data;
    }

    /**
     * Filters the updated values by removing any values that have been marked as removed.
     * @returns An array of LabelValue objects representing the updated values.
     */
    filterUpdatedValues(): LabelValue[] {
        return this.updatedValues.filter(
            (updated) => !this.removedValues.some((removed) => removed.id === updated.id)
        );
    }

    onSave(): void {
        this.isProcessing = true;
        const params = this.getModalData();
        const onSaveEvent = {
            ...params,
            onSuccess: () => {
                this.cancel();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };
        this.onUpdateLabel.emit(onSaveEvent);
    }
}
