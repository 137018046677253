import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { ArchiveContainerComponent } from '@app/components/archive/containers/archive-container/archive-container.component';
import { ArchiveTabsContainer } from '@app/components/archive/containers/archive-tabs/archive-tabs.component';
import { ArchivedListComponent } from '@app/components/archive/components/archived-list.component';
import { ArchiveWizardComponent } from './containers/archive-wizard/archive-wizard.component';
import { ArchivesService } from './archives.service';
import { ArchiveDownloadComponent } from './containers/archive-download/archive-download.component';
import archivesRoute from './archives.route';

const dependencies = [];

export const archiveModule = angular
    .module('app.archive', dependencies)
    .config(archivesRoute)
    .service('ArchivesService', downgradeInjectable(ArchivesService))
    .directive('archiveWizard', downgradeComponent({ component: ArchiveWizardComponent }))
    .directive('archiveDownload', downgradeComponent({ component: ArchiveDownloadComponent }) as ng.IDirectiveFactory)
    .directive('archiveContainer', downgradeComponent({ component: ArchiveContainerComponent }) as ng.IDirectiveFactory)
    .directive('archivedList', downgradeComponent({ component: ArchivedListComponent }) as ng.IDirectiveFactory)
    .directive('archiveTabs', downgradeComponent({ component: ArchiveTabsContainer }) as ng.IDirectiveFactory);
