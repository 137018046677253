import './timeline-update.scss';
import template from './timeline-update.html';
import controller from './timeline-update.controller';

const timelineUpdateComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        $transition$: '<'
    }
};

export default timelineUpdateComponent;
