const annotationsModalWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
    <annotations-modal
        [annotations]="vm.resolve.annotations"
        [doc]="vm.resolve.doc"
        [job-title]="vm.resolve.jobTitle"
        [number-of-signatures-added]="vm.resolve.numberOfSignaturesAdded"
        [pages]="vm.resolve.pages"
        [page-manipulation-actions]="vm.resolve.pageManipulationActions"
        (save)="vm.resolve.onSubmit($event)"
        (dismiss)="vm.modalInstance.close()"
    ></annotations-modal>
    `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default annotationsModalWrapperComponent;
