import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '@app/shared/shared.module';
import { ModalsModule } from '@app/widgets/modals/modals.module';
import { ReportsTableComponent } from './components/reports-table/reports-table.component';
import { ReportsTableService } from './components/reports-table/reports-table.service';
import { ReportService } from './report.service';
import { ChooseBindersFoldersComponent } from './components/choose-binders-folders/choose-binders-folders.component';
import { ObjectPickerComponent } from './components/object-picker/object-picker.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        WidgetsModule,
        EntityModule,
        ScrollingModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        ModalsModule
    ],
    providers: [
        ReportService,
        ReportsTableService
    ],
    declarations: [
        ObjectPickerComponent,
        ChooseBindersFoldersComponent,
        ReportsTableComponent
    ],
    entryComponents: [
        ObjectPickerComponent,
        ChooseBindersFoldersComponent,
        ReportsTableComponent
    ],
    exports: []
})
export class ReportsModule {}
