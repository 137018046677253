import template from './timeline-detail-page.html';
import controller from './timeline-detail-page.controller';

const timelineDetailPageComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        $transition$: '<'
    }
};

export default timelineDetailPageComponent;
