import { StateProvider } from '@uirouter/angularjs';

const resolve = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dashboardsFeatureGuard: ['AppFeatures', 'Notifications', '$q', 'currentTeam', async (AppFeatures, Notifications, $q, currentTeam): Promise<boolean> => {
        // inject currentTeam to be sure that app.team resolves first
        try {
            return await AppFeatures.assertDashboardEnabledForCurrentTeam().toPromise();
        }
        catch (error) {
            Notifications.error(error);
            return $q.reject();
        }
    }]
};

const projectsRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.dashboard-timeline-detail', {
        url: '/projects/:projectId/dashboards/timeline/:timelineId',
        views: {
            '': 'dashboardTimelineDetailPage'
        },
        resolve
    });

    $stateProvider.state('app.team.dashboard-timelines', {
        url: '/dashboard-timelines/:projectId',
        views: {
            '': 'dashboardTimelines'
        },
        params: {
            projectId: ''
        },
        resolve
    });

    $stateProvider.state('app.team.manage-projects', {
        url: '/projects',
        views: {
            '': 'projectsPage'
        },
        resolve
    });

    $stateProvider.state('app.team.manage-project', {
        url: '/projects/:projectId',
        views: {
            '': 'projectsDetailPage'
        },
        resolve
    });

    $stateProvider.state('app.team.manage-project-timeline', {
        url: '/projects/:projectId/timeline/:timelineId',
        views: {
            '': 'timelineDetailPage'
        },
        resolve
    });

    $stateProvider.state('app.team.timeline-update', {
        url: '/projects/:projectId/timelines/:timelineId/edit',
        views: {
            '': 'timelineUpdate'
        },
        resolve
    });
};

projectsRoute.$inject = ['$stateProvider'];

export default projectsRoute;
