import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, ChangeDetectorRef, AfterViewInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { notBlank } from '@app/core/form-validators';
import * as _ from 'lodash';
import {
    Team,
    TeamSignatureOptions,
    TeamSigningPINPolicy,
    TeamMonitorReviewStatusOptions
} from '@app/shared/models';
import messages from '@app/core/constants/messages';
import regex from '@app/core/constants/regex';
import {
    FormSignatureOptions,
    SigningPINPolicyFormFields,
    TeamProfileFormErrors,
    UpdatedTeamSettings,
    FormFieldErrors,
    MonitorReviewStatusOptions,
    MonitorReviewConfig,
    SignatureConfig,
    UpdateProfileData
} from './manage-team-profile.component.types';
import style from './manage-team-profile.component.scss';
import template from './manage-team-profile.component.html';

@Component({
    selector: 'manage-team-profile',
    template,
    styles: [String(style)]
})
export class ManageTeamProfileComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() team: Team;
    @Output() onUpdate = new EventEmitter<UpdateProfileData>();

    canEditProfile: boolean;
    canEditName: boolean;
    canEditPasswordPolicy: boolean;
    canEditSigningPINPolicy: boolean;
    canEditSignatureOptions: boolean;
    canEditMonitorReviewStatus: boolean;
    isProcessing = false;

    fieldErrors: FormFieldErrors;
    teamProfileForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.setFormFromTeam();
        this._checkAvailablePermissions();
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: ng.IOnChangesObject): void {
        if (changes.team) {
            this.setFormFromTeam();
        }
    }

    _checkAvailablePermissions(): void {
        const { permissions } = this.team;
        this.canEditProfile = permissions.updateTeamProfile;
        this.canEditName = permissions.renameTeam;
        this.canEditPasswordPolicy = permissions.managePasswordPolicy && !!this.team.settings.passwordPolicy;
        this.canEditSigningPINPolicy = permissions.manageSigningPINPolicy && !!this.team.settings.signingPINPolicy;
        this.canEditSignatureOptions = !!permissions.manageSignatureOptions;
        this.canEditMonitorReviewStatus = !!permissions.manageMonitorReviewStatus;
    }

    setFormFromTeam(): void {
        const signingPINPolicy = _.get(this.team, 'settings.signingPINPolicy');
        const passwordPolicy = _.get(this.team, 'settings.passwordPolicy');

        this.teamProfileForm = this.fb.group({
            name: [_.get(this.team, 'name', ''), [Validators.required, notBlank]],
            timezone: [_.get(this.team, 'settings.timezone', null), [Validators.required, notBlank]],
            automaticBinderOwnerPermissions: [_.get(this.team, 'settings.automaticBinderOwnerPermissions')],
            automaticDocumentOwnerPermissions: [_.get(this.team, 'settings.automaticDocumentOwnerPermissions')],
            enablePasswordPolicy: [_.get(this.team, 'settings.features.passwordPolicy', false)],
            passwordPolicyExpiryDays: [passwordPolicy.expiryDays, Validators.required],
            passwordPolicyLoginAlertsDays: [passwordPolicy.loginAlertsDays, Validators.required],
            signingPINPolicyIsEnabled: [signingPINPolicy.isEnabled],
            signingPINPolicyExpiryDays: [signingPINPolicy.expiryDays, Validators.required],
            signingPINPolicyLoginAlertsDays: [signingPINPolicy.loginAlertsDays, Validators.required],
            teamSignatureOptions: [this.mapSignatureOptionsToForm()],
            monitorReviewStatusOptions: [this.mapMonitorReviewOptionsToForm()],
            supportEmail: [_.get(this.team, 'settings.support.email', '')],
            supportPhone: [_.get(this.team, 'settings.support.phone', '')]
        });

        this.fieldErrors = {};
    }

    mapMonitorReviewOptionsToForm(): MonitorReviewStatusOptions {
        const monitorReviewConfig: MonitorReviewConfig = _.get(this, 'team.settings.monitorReviewStatus', {});

        if (!monitorReviewConfig.disableMonitorReviewedStatus && monitorReviewConfig.disableMonitorApprovedStatus) {
            return MonitorReviewStatusOptions.REVIEW;
        }
        if (monitorReviewConfig.disableMonitorReviewedStatus && !monitorReviewConfig.disableMonitorApprovedStatus) {
            return MonitorReviewStatusOptions.APPROVE;
        }

        return MonitorReviewStatusOptions.BOTH;
    }

    mapFormToMonitorReviewOptions(): Partial<TeamMonitorReviewStatusOptions> {
        switch (this.teamProfileForm.controls.monitorReviewStatusOptions?.value) {
            case 'review':
                return { disableMonitorApprovedStatus: true, disableMonitorReviewedStatus: false };
            case 'approve':
                return { disableMonitorApprovedStatus: false, disableMonitorReviewedStatus: true };
            case 'both':
            default:
                return { disableMonitorApprovedStatus: false, disableMonitorReviewedStatus: false };
        }
    }

    mapSignatureOptionsToForm(): FormSignatureOptions {
        const signatureConfig: SignatureConfig = _.get(this, 'team.settings.signatures', {});

        if (!signatureConfig.disableAddendum && signatureConfig.disableAnnotation) {
            return FormSignatureOptions.ADDENDUM;
        }
        if (signatureConfig.disableAddendum && !signatureConfig.disableAnnotation) {
            return FormSignatureOptions.ANNOTATION;
        }

        return FormSignatureOptions.BOTH;
    }

    mapFormToSignatureOptions(): Partial<TeamSignatureOptions> {
        switch (this.teamProfileForm.controls.teamSignatureOptions?.value) {
            case 'addendum':
                return { disableAddendum: false, disableAnnotation: true };
            case 'annotation':
                return { disableAddendum: true, disableAnnotation: false };
            case 'both':
            default:
                return { disableAddendum: false, disableAnnotation: false };
        }
    }

    onSelectTimezone(value: string): void {
        this.teamProfileForm.controls.timezone.setValue(value);
        if (value) {
            this.teamProfileForm.markAsDirty();
        }
    }

    _validateForm(): TeamProfileFormErrors {
        const {
            name,
            enablePasswordPolicy,
            passwordPolicyExpiryDays,
            passwordPolicyLoginAlertsDays,
            signingPINPolicyIsEnabled,
            signingPINPolicyExpiryDays,
            signingPINPolicyLoginAlertsDays,
            supportEmail,
            supportPhone,
            timezone
        } = this.teamProfileForm.controls;
        const errors: TeamProfileFormErrors = {
            hasErrors: false,
            field: {}
        };

        if (!timezone.value) {
            errors.hasErrors = true;
            errors.field.timezone = 'Please choose a timezone.';
        }

        if (!name.value) {
            errors.hasErrors = true;
            errors.field.name = 'Please choose a name.';
        }
        else if (!regex.names.test(name.value)) {
            errors.hasErrors = true;
            errors.field.name = `Please choose a valid name.  ${messages.validNameMessage}`;
        }

        if (enablePasswordPolicy.value) {
            if (parseInt(passwordPolicyExpiryDays.value, 10) <= 0) {
                errors.hasErrors = true;
                if (!errors.field.passwordPolicy) {
                    errors.field.passwordPolicy = {};
                }
                errors.field.passwordPolicy.expiryDays = 'Please enter a positive number.';
            }

            if (parseInt(passwordPolicyLoginAlertsDays.value, 10) <= 0) {
                errors.hasErrors = true;
                if (!errors.field.passwordPolicy) {
                    errors.field.passwordPolicy = {};
                }
                errors.field.passwordPolicy.loginAlertsDays = 'Please enter a positive number.';
            }
        }

        if (signingPINPolicyIsEnabled.value) {
            const expiryDays = parseInt(signingPINPolicyExpiryDays.value, 10);
            const loginAlertsDays = parseInt(signingPINPolicyLoginAlertsDays.value, 10);
            let signingPINPolicyErrors = {};

            if (expiryDays <= 0) {
                errors.hasErrors = true;
                signingPINPolicyErrors = { ...signingPINPolicyErrors, expiryDays: 'Please enter a positive number.' };
            }
            if (loginAlertsDays <= 0) {
                errors.hasErrors = true;
                signingPINPolicyErrors = { ...signingPINPolicyErrors, loginAlertsDays: 'Please enter a positive number.' };
            }
            errors.field.signingPINPolicy = signingPINPolicyErrors;
        }

        if (supportEmail.value && !regex.email.test(supportEmail.value)) {
            errors.hasErrors = true;
            errors.field.supportEmail = 'Please enter a valid email address.';
        }

        if (supportPhone.value && !regex.phone.test(supportPhone.value)) {
            errors.hasErrors = true;
            errors.field.supportPhone = 'Please enter a valid phone number.';
        }

        return errors;
    }

    onSubmit(): void {
        const validation = this._validateForm();
        if (validation.hasErrors) {
            this.fieldErrors = validation.field;
            return;
        }
        this.fieldErrors = {};
        this.isProcessing = true;
        const updatedFields = this._getUpdatedFields();

        const updateProfileData = {
            updatedFields,
            onSuccess: () => {
                this.isProcessing = false;
                this.teamProfileForm.markAsPristine();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        this.onUpdate.emit(updateProfileData);
    }

    _getUpdatedFields(): UpdatedTeamSettings {
        const {
            name,
            enablePasswordPolicy,
            passwordPolicyExpiryDays,
            passwordPolicyLoginAlertsDays,
            timezone,
            signingPINPolicyIsEnabled,
            signingPINPolicyExpiryDays,
            signingPINPolicyLoginAlertsDays,
            automaticBinderOwnerPermissions,
            automaticDocumentOwnerPermissions,
            supportEmail,
            supportPhone
        } = this.teamProfileForm.controls;
        const updatedFields: UpdatedTeamSettings = {
        };

        if (this.team.name !== name.value) {
            updatedFields.name = name.value;
        }
        if (this.team.settings.automaticDocumentOwnerPermissions !== automaticDocumentOwnerPermissions.value) {
            updatedFields.automaticDocumentOwnerPermissions = automaticDocumentOwnerPermissions.value;
        }
        if (this.team.settings.automaticBinderOwnerPermissions !== automaticBinderOwnerPermissions.value) {
            updatedFields.automaticBinderOwnerPermissions = automaticBinderOwnerPermissions.value;
        }
        if (this.team.settings.timezone !== timezone.value) {
            updatedFields.timezone = timezone.value;
        }

        if (this.team.settings.support.email !== supportEmail.value || this.team.settings.support.phone !== supportPhone.value) {
            _.set(updatedFields, 'support.email', supportEmail.value);
            _.set(updatedFields, 'support.phone', supportPhone.value);
        }

        if (_.get(this, 'team.settings.features.passwordPolicy') !== enablePasswordPolicy.value) {
            updatedFields.enablePasswordPolicy = enablePasswordPolicy.value;
        }

        if (enablePasswordPolicy.value) {
            updatedFields.passwordPolicy = {};
            // check for password policy changes only if feature is enabled
            if (!_.isEqual(this.team.settings.passwordPolicy.expiryDays, passwordPolicyExpiryDays)
                || !_.isEqual(this.team.settings.passwordPolicy.loginAlertsDays, passwordPolicyLoginAlertsDays)) {
                updatedFields.passwordPolicy.expiryDays = passwordPolicyExpiryDays.value;
                updatedFields.passwordPolicy.loginAlertsDays = passwordPolicyLoginAlertsDays.value;
                updatedFields.passwordPolicy.emailRemindersDays = this.team.settings.passwordPolicy.emailRemindersDays;
                updatedFields.passwordPolicy.keepLast = this.team.settings.passwordPolicy.keepLast;
            }
        }

        const updatedSigningPINPolicy = this.getSigningPINPolicyUpdatedFields({
            isEnabled: signingPINPolicyIsEnabled.value,
            expiryDays: signingPINPolicyExpiryDays.value,
            loginAlertsDays: signingPINPolicyLoginAlertsDays.value
        });
        if (updatedSigningPINPolicy !== null) {
            updatedFields.signingPINPolicy = updatedSigningPINPolicy;
        }

        const newSignatureOptions: Partial<TeamSignatureOptions> = this.mapFormToSignatureOptions();
        const oldSignatureOptions: Partial<TeamSignatureOptions> = _.get(this, 'team.settings.signatures', {});
        if (
            newSignatureOptions.disableAddendum !== oldSignatureOptions.disableAddendum
            || newSignatureOptions.disableAnnotation !== oldSignatureOptions.disableAnnotation
        ) {
            updatedFields.disableAddendum = newSignatureOptions.disableAddendum;
            updatedFields.disableAnnotation = newSignatureOptions.disableAnnotation;
        }

        const newMonitorReviewStatusOptions: Partial<TeamMonitorReviewStatusOptions> = this.mapFormToMonitorReviewOptions();
        const oldMonitorReviewStatusOptions: Partial<TeamMonitorReviewStatusOptions> = _.get(this, 'team.settings.monitorReviewStatus', {});
        if (
            newMonitorReviewStatusOptions.disableMonitorApprovedStatus
            !== oldMonitorReviewStatusOptions.disableMonitorApprovedStatus
            || newMonitorReviewStatusOptions.disableMonitorReviewedStatus
            !== oldMonitorReviewStatusOptions.disableMonitorReviewedStatus
        ) {
            updatedFields.disableMonitorApprovedStatus = newMonitorReviewStatusOptions.disableMonitorApprovedStatus;
            updatedFields.disableMonitorReviewedStatus = newMonitorReviewStatusOptions.disableMonitorReviewedStatus;
        }

        return updatedFields;
    }

    private getSigningPINPolicyUpdatedFields(formFields: SigningPINPolicyFormFields): Partial<TeamSigningPINPolicy> | null {
        const updatedSettings: Partial<TeamSigningPINPolicy> = {};
        const { signingPINPolicy: lastSaved } = this.team.settings;

        if (formFields.isEnabled !== lastSaved.isEnabled) {
            updatedSettings.isEnabled = formFields.isEnabled;
        }
        if (+formFields.expiryDays !== lastSaved.expiryDays) {
            updatedSettings.expiryDays = +formFields.expiryDays;
        }
        if (+formFields.loginAlertsDays !== lastSaved.loginAlertsDays) {
            updatedSettings.loginAlertsDays = +formFields.loginAlertsDays;
        }

        return Object.keys(updatedSettings).length > 0 ? updatedSettings : null;
    }

}
