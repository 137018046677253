import * as _ from 'lodash';
import { AuditTrailSubject } from '@app/shared/models';
import { ManageAccessSubmitEvent } from '@app/widgets/manage-access/manage-access.component.types';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { ROLE_BLINDED_STATUSES } from '@app/core/constants';
import { RolesService } from '../../roles.service';
import { DuplicateRoleComponent } from '../../components/duplicate-role/duplicate-role.component';
import { DuplicateRoleEventParams } from '../../components/duplicate-role/duplicate-role.component.types';
import { DeleteRoleComponent } from '../../components/delete-role/delete-role.component';
import { DeleteRoleEvent } from '../../components/delete-role/delete-role.component.types';
import { CreateRoleComponent } from '../../components/create-role/create-role.component';
import { RenameRoleComponent } from '../../components/rename-role/rename-role.component';
import { FEATURE_FLAGS } from '../../../../core/constants/feature-flags';

class ManageRolesController {
    _Roles: RolesService;
    _Notifications: NotificationsService;

    areBlindedUnblindedRolesEnabled = false;
    roleBlindedStatuses = ROLE_BLINDED_STATUSES;

    constructor(
        $state, $timeout, Team, AccessDates, AuditTrail, SORT, modalHelper,
        PaginationProperties, CurrentSession, Roles: RolesService, Notifications: NotificationsService,
        private ApiError, private Modals: ModalsService,
        private FeatureFlags: FeatureFlagService
    ) {
        this._$state = $state;
        this._$timeout = $timeout;
        this._Teams = Team;
        this._Roles = Roles;
        this._AccessDates = AccessDates;
        this._AuditTrail = AuditTrail;
        this.SORT = SORT;
        this._modalHelper = modalHelper;
        this._CurrentSession = CurrentSession;
        this._Notifications = Notifications;

        this.maxSize = PaginationProperties.getMaxSize();
        this.showBoundaryLinkNumbers = PaginationProperties.showBoundaryLinkNumbers();
        this.pagination = {
            pageSize: 20,
            sortBy: 'name',
            sortDir: 'ASC',
            totalItems: 0
        };

        this.selectedRole;
        this.filterValue;
        this.crumbs = [{ name: 'Manage Roles' }];
    }

    $onInit(): void {
        this.currentTeam = this._CurrentSession.getCurrentTeam();
        if (!this.canAccessRolesPage()) {
            return this._$state.go('app.select-team');
        }

        if (!_.includes(['name'], this.SORT.by)) {
            this.SORT.set('name');
        }

        this.FeatureFlags
            .getFlag(FEATURE_FLAGS.BLINDED_UNBLINDED_ROLES, false)
            .subscribe((value) => {
                this.areBlindedUnblindedRolesEnabled = value;
            });

        this._fetchRoles();
    }

    addRole(): void {
        if (this.currentTeam.permissions.manageTeamRolesAndPermissions) {

            const createRoleModal = this.Modals.show(CreateRoleComponent, {
                animated: true,
                class: 'modal-md',
                initialState: {
                    hasBlindedUnblindedPermission:
                        this.currentTeam.permissions.manageBlindedUnblindedRoles && this.areBlindedUnblindedRolesEnabled
                }
            });

            createRoleModal.content.onCreate.subscribe((event) => {
                this._Roles.createRole(this.currentTeam.id, {
                    name: event.name,
                    blindedStatus: event.blindedStatus
                })
                    .subscribe((role) => {
                        this._Notifications.clearAll();
                        this._Notifications.success(`Role ${role.name} created!`);
                        this.roles.items.unshift(role);
                        role.success = true;
                        this._$timeout(() => {
                            role.success = false;
                        }, 5000);
                        event.onSuccess();
                    }, (errorData) => {
                        if (errorData.error && errorData.error.message) {
                            this._Notifications.error(errorData.error.message);
                        }
                        else {
                            this._Notifications.unexpectedError();
                        }
                        event.onError();
                        throw errorData;
                    });
            });
        }
    }

    assignRole() {
        if (!this.currentTeam.permissions.viewTeamUsersRolesPermissions || !this.selectedRole) {
            return;
        }

        const { teamId } = this.selectedRole;
        const roleId = this.selectedRole.id;

        this._modalHelper.open({
            animation: false,
            component: 'manage-access-wrapper',
            size: 'lg',
            keyboard: false,
            resolve: {
                onSubmit: () => (event: ManageAccessSubmitEvent): void => {
                    const { creates, updates, deletes } = event.data;
                    this._Teams.updateRoleAssignmentMultiple(teamId, { creates, updates, deletes })
                        .toPromise()
                        .then(() => {
                            event.onSuccess();
                        })
                        .catch(() => {
                            event.onError();
                        });
                },
                items: () => this._AccessDates.getUsersForRole({ teamId, roleId }).toPromise().then((data) => data),
                subject: () => _.clone(this.selectedRole),
                canAssignDates: () => this.currentTeam.permissions.manageTeamAccessControl,
                canAssignRoles: () => this.currentTeam.permissions.assignTeamRoles,
                itemType: () => 'user'
            }
        });
    }

    renameRole() {
        if (!(this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedRole)) {
            return;
        }

        const renameRole = this.Modals.show(RenameRoleComponent, {
            class: 'modal-md',
            animated: true,
            initialState: {
                role: _.cloneDeep(this.selectedRole)
            }
        });

        renameRole.content.onRename.subscribe((eventRename) => {
            const { role, onSuccess, onError } = eventRename;
            this._Roles.updateRole(role)
                .subscribe((role) => {
                    this._Notifications.success('Role Updated!');
                    _.merge(_.find(this.roles.items, { id: role.id }), role);
                    role.success = true;
                    this._$timeout(() => {
                        role.success = false;
                    }, 5000);
                    onSuccess();
                }, (errorData) => {
                    if (errorData.error && errorData.error.message) {
                        this._Notifications.error(errorData.error.message);
                    }
                    else {
                        this._Notifications.unexpectedError();
                    }
                    onError();
                    throw errorData;
                });
        });
    }

    duplicateRole() {
        if (!(this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedRole)) {
            return;
        }

        const duplicateRoleModal = this.Modals.show(DuplicateRoleComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                role: this.selectedRole
            }
        });

        duplicateRoleModal.content.onDuplicate.subscribe((event: DuplicateRoleEventParams) => {
            this._Roles.duplicateRole(event.role)
                .subscribe(
                    (role) => {
                        event.onSuccess();
                        this._Notifications.success('Role duplicated!');
                        this.roles.items.unshift(role);
                        role.success = true;
                        this._$timeout(() => {
                            role.success = false;
                        }, 5000);
                        this.deselectAll();
                        return role;
                    },
                    (errorData) => {
                        event.onError();
                        if (errorData.error && errorData.error.message) {
                            this._Notifications.error(errorData.error.message);
                        }
                        else {
                            this._Notifications.unexpectedError();
                        }
                        throw errorData;
                    }
                );
        });
    }

    deleteRole() {
        if (!(this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedRole)) {
            return;
        }

        const deleteRoleModal = this.Modals.show(DeleteRoleComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                role: this.selectedRole
            }
        });

        deleteRoleModal.content.onDestroy.subscribe((data: DeleteRoleEvent) => {
            this._Roles.deleteRole(data.role, data.reason)
                .subscribe(
                    () => {
                        this._Notifications.success('Role Deleted!');
                        _.remove(this.roles.items, { id: data.role.id });

                        if (!this.roles.items.length) {
                            this.pagination.pageNum = Math.max(this.pagination.pageNum - 1, 1);
                        }

                        this._fetchRoles();
                        data.onSuccess();
                    },
                    () => {
                        data.onError();
                    }
                );
        });
    }

    openAuditTrailModal() {
        if ((this.currentTeam.permissions.viewTeamAuditTrail && this.selectedRole)) {

            // Special case. Since role cannot be an object in permissons, it cannot
            // be decrated with permissions like teams, binders, etc... So here
            // we supply the downloadTeamAuditTrail privilege which is the criteria
            // for downloading any audit trail at the role level.
            const permissionDecoratedRole = _.assign({}, this.selectedRole, {
                permissions: {
                    downloadTeamAuditTrail: this.currentTeam.permissions.downloadTeamAuditTrail
                }
            });

            const params = {
                subject: AuditTrailSubject.ROLE,
                teamId: permissionDecoratedRole.teamId,
                objectId: permissionDecoratedRole.id,
                overwrittenObjectId: permissionDecoratedRole.overwrittenPlaceholderId || null,
                limitToOverwritten: false,
                ...this._AuditTrail.auditPagination
            };

            this._AuditTrail.getAudits(params).toPromise().then((audits) => {
                this.Modals.show(AuditTrailModalComponent, {
                    class: 'modal-lg',
                    initialState: {
                        data: audits,
                        item: permissionDecoratedRole,
                        subject: params.subject,
                        pagination: this._AuditTrail.auditPagination,
                        onPageChange: this._AuditTrail.getAudits.bind(this._AuditTrail)
                    }
                });
            }).catch(this.ApiError.handleError);
        }
    }

    select(role) {
        if (role.selected) {
            role.selected = false;
            this.selectedRole = undefined;
        }
        else {
            this.deselectAll();
            role.selected = true;
            this.selectedRole = role;
        }
    }

    deselectAll() {
        if (this.selectedRole) {
            this.selectedRole.selected = false;
        }
        this.selectedRole = undefined;
    }

    toggleActions($event, role) {
        $event.stopPropagation();
        if (this.selectedRole !== role) {
            this.select(role);
        }
    }

    canActOnSelection() {
        return this.selectedRole
            && (this.currentTeam.permissions.viewTeamAuditTrail
                || this.currentTeam.permissions.manageTeamRolesAndPermissions
                || this.currentTeam.permissions.viewTeamUsersRolesPermissions);
    }

    openDirectPermissionReport(role) {
        this._$state.go('app.team.permission-report', {
            teamId: this.currentTeam.id,
            subjectId: role.id,
            subjectType: 'role',
            filter: 'PERM'
        });
    }

    canAccessRolesPage() {
        return this.currentTeam
            && (this.currentTeam.permissions.manageTeam || this.currentTeam.permissions.viewTeamUsersRolesPermissions);
    }

    canManageTeamRolesAndPermissions() {
        return this.currentTeam && this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedRole;
    }

    sortChanged(sortProperty) {

        if (this.pagination.sortBy === sortProperty) {
            this.pagination.sortDir = this.pagination.sortDir === 'ASC' ? 'DESC' : 'ASC';
        }

        this.pagination.sortBy = sortProperty;
        this._fetchRoles();
    }

    pageChanged() {

        this._fetchRoles();
    }

    applyFilter(filter) {
        this.pagination.pageNum = 1;
        this.filterValue = filter;
        this._fetchRoles();
    }

    openRolesTemplates() {
        this._$state.go('app.team.manage-roles-templates', { teamId: this.currentTeam.id });
    }

    _fetchRoles() {
        const params = {
            teamId: this.currentTeam.id,
            ...(this.filterValue && {
                filter: {
                    value: this.filterValue,
                    type: 'name'
                }
            }),
            ...this.pagination
        };
        this.deselectAll();
        this.loadingRoles = true;

        this._Roles
            .getRoles(params.teamId, {
                pageNum: params.pageNum,
                pageSize: params.pageSize,
                sortBy: params.sortBy,
                sortDir: params.sortDir,
                filter: params.filter
            }).toPromise()
            .then((roles) => {
                this.loadedRoles = true;
                this.roles = roles.roles;
                this.pagination.totalItems = this.roles.recordCount;
            })
            .finally(() => {
                this.loadingRoles = false;
            });
    }

    _openModal(component, resolve = {}) {
        return this._modalHelper.open({
            animation: false,
            size: 'md',
            component,
            resolve
        }, this.deselectAll.bind(this));
    }
}

ManageRolesController.$inject = [
    '$state',
    '$timeout',
    'Team',
    'AccessDates',
    'AuditTrail',
    'SORT',
    'modalHelper',
    'PaginationProperties',
    'CurrentSession',
    'Roles',
    'Notifications',
    'ApiError',
    'ModalsService',
    'FeatureFlagService'
];

export default ManageRolesController;
