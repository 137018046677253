const DuplicateLogTemplateComponentWrapper: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
        <duplicate-log-template
            [original-template-name]="vm.resolve.originalTemplateName"
            [original-template-id]="vm.resolve.originalTemplateId"
            [team-id]="vm.resolve.teamId"
            (dismiss)="vm.modalInstance.close('cancel')"
            (duplicated)="vm.resolve.duplicated($event)">
        </duplicate-log-template>
        `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default DuplicateLogTemplateComponentWrapper;
