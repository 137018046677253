import {
    Component, OnDestroy, OnInit
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Team,
    Document,
    Crumb,
    BrowseTree,
    Binder,
    Folder
} from '@app/shared/models';
import { DocumentService } from '@app/shared/documents/document.service';
import { StateService } from '@uirouter/core';
import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { SessionsService } from '@app/shared/sessions/sessions.service';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { TeamService } from '@app/shared/teams/team.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { TeamInboxCheckerService } from '@app/shared/teams-inbox/team-inbox-checker.service';
import { Config } from '@florencehealthcare/doc-viewer';
import template from './inbox-document-preview.component.html';
import styles from './inbox-document-preview.component.scss';
import { AcknowledgeTypes } from '../../team-inbox.constants';
import { InboxDocument } from '../../team-inbox.types';
import { TeamInboxStateService } from '../../team-inbox.state.service';
import { TeamInboxService } from '../../team-inbox.service';

@Component({
    selector: 'inbox-document-preview',
    template,
    styles: [String(styles)]
})
export class InboxDocumentPreviewComponent implements OnInit, OnDestroy {
    $destroy = new Subject<void>();
    document: InboxDocument;
    currentTeam: Team;
    doc: Document;
    crumbs: Crumb[];
    selectedDestination: (Binder | Folder);
    predefinedDestination: string;
    predefinedDestinationSelected: boolean;
    loadingBinders = true;
    binders: Binder[];
    requiredAction: string;
    isDocumentAccepted = false;
    shouldReset = false;
    subHeaders = {
        [AcknowledgeTypes.MOVE]: 'Move the document out of the inbox and to the suggested location',
        [AcknowledgeTypes.ACCEPT_AND_MOVE]: 'By moving the document I acknowledge that site has received it',
        [AcknowledgeTypes.MOVE_AND_REQUEST_SIGNATURE]: 'By moving the document I acknowledge that site has received it',
        [AcknowledgeTypes.ACCEPT_AND_SIGN]: 'By moving the document I acknowledge that site has received it'
    }

    docViewerToolsConfig: Config = {
        annotationsFeatureConfig: {
            highlight: false,
            timestamp: false,
            freeTextTool: false
        },
        signaturesFeatureConfig: {
            enabled: false,
            stampSignatureText: '',
            addendum: false,
            stamp: false
        },
        initialDocument: {
            filename: '',
            url: ''
        },
        redact: false,
        previewOnly: true,
        manipulatePages: false,
        timezone: '',
        token: '',
        licenseKey: '',
        webviewerServerUrl: '',
        disableSignatureFieldsReadOnly: false,
        webviewerServerRangeRequests: true
    };

    constructor(
        private CurrentSession: CurrentSessionService,
        private AppConfig: AppConfigService,
        private TeamInboxState: TeamInboxStateService,
        private Documents: DocumentService,
        private $state: StateService,
        private Teams: TeamService,
        private Binders: BindersService,
        private Sessions: SessionsService,
        private Notifications: NotificationsService,
        private TeamInboxChecker: TeamInboxCheckerService,
        private TeamInbox: TeamInboxService
    ) { }

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        if (!(this.currentTeam?.permissions?.manageTeamInbox || this.currentTeam?.permissions?.performActionInTeamInbox)) {
            this.$state.go('app.select-team');
        }

        // document viewer
        this.Sessions.heartbeat().then((token: string) => {
            this.docViewerToolsConfig.token = token;
        });
        this.docViewerToolsConfig.licenseKey = this.AppConfig.config.docViewerLicenseKey;
        this.docViewerToolsConfig.webviewerServerUrl = this.AppConfig.config.webviewerServerUrl;
        this.document = this.TeamInboxState.getInboxDocument();
        this.requiredAction = this.document.requiredAction;
        this.resolvePredefinedDocumentDestination();

        this.loadDocument();
        this.loadBinders();
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    resolvePredefinedDocumentDestination(): void {
        const { suggestedLocation } = this.document;
        this.predefinedDestination = suggestedLocation
            ? this.getDestinationPath(suggestedLocation)
            : '';
        if (this.predefinedDestination) {
            this.predefinedDestinationSelected = true;
        }
        this.resolveSelectedLocation();
    }

    getDestinationPath(suggestedLocation: Binder | Folder): string {
        if (suggestedLocation.type === 'binder') {
            return suggestedLocation.name;
        }

        // Handles the case when the suggested location cannot be found
        if (!suggestedLocation.path) {
            return '';
        }

        return `${suggestedLocation.binderName}/${suggestedLocation.path}`;
    }

    resolveSelectedLocation(): void {
        this.selectedDestination = this.predefinedDestinationSelected
            ? this.document.suggestedLocation
            : null;
    }

    loadDocument(): void {
        this.Documents.load(this.$state.params.documentId, this.$state.params.version, null)
            .subscribe((res) => {
                this.doc = res;
                this.docViewerToolsConfig.initialDocument.url = this.constructDocumentFileUrl();
                this.docViewerToolsConfig.initialDocument.filename = this.doc.name + this.doc.ext;
                this.resolveCrumbs();
            });
    }

    loadBinders(): void {
        this.Binders.getBinders(this.currentTeam.id, { includeArchived: false })
            .pipe(takeUntil(this.$destroy))
            .subscribe(
                (binders) => {
                    this.binders = sortByLexicographically(binders, 'name');
                    this.loadingBinders = false;
                }
            );
    }

    private resolveCrumbs(): void {
        this.crumbs = [
            {
                name: 'Inbox',
                stateName: 'app.team.team-inbox',
                stateParams: {
                    teamId: this.$state.params.teamId
                }
            },
            {
                name: this.doc.title
            }
        ];
    }

    private returnToInbox(): void {
        this.$state.go('app.team.team-inbox');
    }

    handleError(message: string): void {
        message
            ? this.Notifications.error(message)
            : this.Notifications.unexpectedError();
    }

    loadItem = async (params: BrowseParams): Promise<BrowseTree> => {
        params.includeDocs = false;
        params.includeArchived = false;
        const data = await this.Teams.browse(this.currentTeam.id, params).toPromise();
        return sortBrowseTreeLexicographically(data, 'name');
    }

    onSelectionChange = ($event: { selectedItems: (Binder | Folder)[] }): void => {
        [this.selectedDestination] = $event.selectedItems;
        if (!this.selectedDestination) {
            this.resolvePredefinedDocumentDestination();
        }
        else {
            this.predefinedDestinationSelected = false;
        }
        this.shouldReset = false;
    }

    isItemSelectable({ type }): boolean {
        return type !== 'document';
    }

    onPredefinedDestinationChange() {
        this.predefinedDestinationSelected = !this.predefinedDestinationSelected;
        if (!this.predefinedDestinationSelected) {
            this.selectedDestination = null;
        }
        else {
            this.shouldReset = true;
            this.resolveSelectedLocation();
        }
    }

    isActionEnabled(): boolean {
        if (!this.selectedDestination) {
            return false;
        }
        if (this.shouldDocumentBeAccepted() && !this.isDocumentAccepted) {
            return false;
        }
        return true;
    }

    performAction(): void {
        this.Documents.moveTo(this.selectedDestination, [this.doc])
            .pipe(take(1))
            .subscribe(() => {
                this.TeamInboxChecker.decreaseCounter();
                this.TeamInbox.patchInboxDocument({
                    teamId: this.currentTeam.id,
                    documentId: this.doc.id as string,
                    action: this.requiredAction
                }).subscribe(() => {
                    this.Notifications.success('Document successfully accepted');
                }, () => {
                    this.Notifications.error('Failed to successfully accept the document');
                }, () => {
                    this.returnToInbox();
                });
            }, (error) => {
                this.handleError(error);
            });
    }

    shouldDocumentBeAccepted(): boolean {
        switch (this.requiredAction) {
            case AcknowledgeTypes.ACCEPT_AND_MOVE:
            case AcknowledgeTypes.MOVE_AND_REQUEST_SIGNATURE:
            case AcknowledgeTypes.ACCEPT_AND_SIGN:
                return true;
            case AcknowledgeTypes.MOVE:
                return false;
            default:
                return true;
        }
    }

    getButtonTextByRequiredAction(requiredAction: string): string {
        switch (requiredAction) {
            case AcknowledgeTypes.ACCEPT_AND_MOVE:
            case AcknowledgeTypes.MOVE_AND_REQUEST_SIGNATURE:
            case AcknowledgeTypes.ACCEPT_AND_SIGN:
                return AcknowledgeTypes.ACCEPT_AND_MOVE;
            case AcknowledgeTypes.MOVE:
                return AcknowledgeTypes.MOVE;
            default:
                return AcknowledgeTypes.MOVE;
        }
    }

    acceptDocument(): void {
        this.isDocumentAccepted = !this.isDocumentAccepted;
    }

    getSubHeader(): string {
        return this.subHeaders[this.requiredAction];
    }

    private getDocumentId(): string {
        return typeof this.doc.id !== 'string' ? this.doc.id.documentId : this.doc.id;
    }

    private constructDocumentFileUrl(): string {
        return `${this.AppConfig.config.ebindersApiUrl}/documents/${this.getDocumentId()}/versions/${this.doc.version}/file`;
    }
}
