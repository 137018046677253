import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { StudyRoleIdName, StudyTeamPreview } from '../study-roles.types';

@Injectable()
export class StudyRolesApiService {
    constructor(private http: HttpClient) { }

    private readonly url = {
        studyRoles: '/api/constants/study-roles',
        filePreview: (teamId: string) => `/api/teams/${teamId}/study-roles/file-preview`
    }

    private isLoading = new BehaviorSubject<boolean>(null);
    isLoading$ = this.isLoading.asObservable();

    getStudyRoles$(): Observable<StudyRoleIdName[]> {
        this.isLoading.next(true);

        return this.http.get<StudyRoleIdName[]>(this.url.studyRoles)
            .pipe(
                tap(() => this.isLoading.next(false)),
                catchError((error) => {
                    this.isLoading.next(false);
                    return throwError(() => error);
                }),
                retry(3)
            );
    }

    getFilePreview$(teamId: string, file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('filename', file.name);
        formData.append('size', `${file.size}`);

        const headers = new HttpHeaders();
        headers.set('Content-Type', undefined);

        return this.http.post<StudyTeamPreview[]>(this.url.filePreview(teamId), formData, { headers });
    }
}
