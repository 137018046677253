import { NgModule } from '@angular/core';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DownloadsIndexComponent } from './containers/downloads-index/downloads-index.component';

@NgModule({
    imports: [
        WidgetsModule,
        BrowserModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot()
    ],
    providers: [],
    declarations: [DownloadsIndexComponent],
    entryComponents: [DownloadsIndexComponent],
    exports: []
})
export class DownloadsModule {}
