import controller from './help-desk.controller';
import template from './help-desk.html';
import './help-desk.scss';

const helpDeskComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        $transition$: '<'
    }
};

export default helpDeskComponent;
