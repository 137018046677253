import * as angular from 'angular';
import eventDirectiveFactory from './event-directive-factory';

const onOutsideMouseup = eventDirectiveFactory('mouseup', {
    attachEventsToWindow: true,
    nativeEvent: true,
    eventHandlerName: 'onOutsideMouseup',
    eventFilter: ({ $event, element: el }) => {
        const { target } = $event;
        const nodesAreSame = (el.isSameNode && el.isSameNode(target)) || (el.id && el.id === target.id) || el === target;
        // Only invoke the callback if the target element isn't equal to the current element or one of its children
        return !nodesAreSame && !el.contains(target);
    }
});

const dependencies = [
];

const domEvents = angular
    .module('app.widgets.domEvents', dependencies)
    .directive('imgOnload', eventDirectiveFactory('load', { eventHandlerName: 'imgOnload' }))
    .directive('onError', eventDirectiveFactory('error', { eventHandlerName: 'onError' }))
    .directive('onFocusIn', eventDirectiveFactory('focusin', { eventHandlerName: 'onFocusIn' }))
    .directive('onOutsideMouseup', onOutsideMouseup)
    .directive('onResize', eventDirectiveFactory('resize', { attachEventsToWindow: true }))
    .directive('onScroll', eventDirectiveFactory('scroll', { shouldDebounce: true }))
    .directive('onTouchCancel', eventDirectiveFactory('touchcancel', { eventHandlerName: 'onTouchCancel', nativeEvent: true }))
    .directive('onTouchEnd', eventDirectiveFactory('touchend', { eventHandlerName: 'onTouchEnd', nativeEvent: true }));

export default domEvents;
