import { CloseReason } from './modals.service.types';

const IGNORED_PROMISE_REJECTIONS = [
    'cancel',
    'escape key press',
    'backdrop click'
];

export class ModalHelperService {
    constructor(private $uibModal: ng.ui.bootstrap.IModalService) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    open<R extends CloseReason>(params: ng.ui.bootstrap.IModalSettings, cb?: () => any): ng.IPromise<R> {
        return this.$uibModal
            .open({
                backdrop: 'static',
                ...params
            })
            .result
            .then(cb)
            .catch((reason) => {
                if (IGNORED_PROMISE_REJECTIONS.indexOf(reason) === -1) {
                    throw reason;
                }
            });
    }
}

ModalHelperService.$inject = ['$uibModal'];
