'use strict';

const entities = {
    TEAM: 'team',
    ROLE: 'role',
    USER: 'user',
    MARKETING_NOTIFICATION: 'marketing-notification',
    SYSTEM_NOTIFICATION: 'system-notification',
    USER_SYSTEM_NOTIFICATION_READING: 'user-system-notifications-reading',
    USER_ROLE: 'user-role',
    PROJECT: 'project',
    TIMELINE: 'timeline',
    BINDER: 'binder',
    FOLDER: 'folder',
    DOCUMENT: 'document',
    FORM: 'form',
    ATTACHMENT: 'attachment',
    DELETED_DOCUMENT: 'deleted_document',
    DELETED_LOG_ENTRIES: 'deleted-log-entities',
    SUBSCRIPTION: 'subscription',
    PERMISSION: 'permission',
    ORIGINAL_STATE_PERMISSION: 'original-state-permission',
    TAG: 'tag',
    TASK: 'task',
    ANNOUNCEMENT: 'announcement',
    ANNOUNCEMENT_RECIPIENT: 'announcement-recipient',
    REPORT: 'report',
    DIGEST: 'digest',
    DIGEST_SUBSCRIPTIONS: 'digest-subscription',
    DIGEST_SUBSCRIPTIONS_QUEUE: 'digest-subscription-queue',
    DOCUMENT_PROPERTIES: 'document-property',
    DOCUMENT_CATEGORIES: 'document-category',
    DOCUMENT_HISTORIES: 'document-histories',
    SAML_CONFIG: 'saml-config',
    SP_METADATA: 'sp-metadata',
    DOWNLOAD: 'download',
    DOWNLOAD_ITEMS: 'download-item',
    COUNTERS: 'counter',
    RUN_ACTION_GROUP_REQUESTS: 'run-action-group-request',
    REQUESTED_DOCUMENTS: 'requested-document',
    REPORT_ITEMS: 'report-item',
    LABEL: 'label',
    LABELED_ENTITY: 'labeled-entity',
    LOG_TEMPLATES: 'log-template',
    LOG_ENTRIES: 'log-entry',
    SIGNATURE_REQUESTS: 'signature-request',
    MONITOR_REVIEWS: 'monitor-review',
    MONITOR_REVIEW_EVENTS: 'monitor-review-event',
    PAYWALLS: 'paywall',
    PAYWALL_TEAMS: 'paywall-team',
    PAYWALL_USERS_MONITOR_REVIEW: 'paywall-user-monitor-review',
    STUDY: 'study',
    STUDY_ROLES: 'study-roles',
    SITE: 'site',
    STUDY_ENTITY: 'study-entity',
    TEAM_SIP_INTEGRATION_LINK: 'team-sip-integration-link',
    SIP_SPONSOR_NAME: 'sip-sponsor-name',
    REGION: 'region',
    DOCUMENT_SIP_STATUS: 'document-sip-status',
    EVENT_BUS_BACKUP_MESSAGE: 'event-bus-backup-message',
    STUDY_TEMPLATES: 'study-templates',
    QC_REVIEWS: 'qc-reviews',
    ROLE_CATEGORIES: 'role-categories',
    ANNOTATIONS: 'annotations'
};

const collections = {
    TEAM: 'teams',
    ROLE: 'roles',
    USER: 'users',
    MARKETING_NOTIFICATION: 'marketing-notifications',
    SYSTEM_NOTIFICATION: 'system-notifications',
    USER_SYSTEM_NOTIFICATION_READING: 'user-system-notifications-readings',
    USER_ROLE: 'user-roles',
    PROJECT: 'projects',
    TIMELINE: 'timelines',
    BINDER: 'binders',
    FOLDER: 'folders',
    DOCUMENT: 'documents',
    ATTACHMENT: 'attachments',
    FORM: 'forms',
    DELETED_DOCUMENT: 'deleted_documents',
    DELETED_LOG_ENTRIES: 'deleted-log-entities',
    SUBSCRIPTION: 'subscriptions',
    PERMISSION: 'permissions',
    ORIGINAL_STATE_PERMISSION: 'original-state-permissions',
    TAG: 'tags',
    TASK: 'tasks',
    ANNOUNCEMENT: 'announcements',
    ANNOUNCEMENT_RECIPIENT: 'announcement-recipients',
    REPORT: 'reports',
    DIGEST: 'digests',
    DIGEST_SUBSCRIPTIONS: 'digests-subscriptions',
    DIGEST_SUBSCRIPTIONS_QUEUE: 'digests-subscriptions-queues',
    DOCUMENT_PROPERTIES: 'document-properties',
    DOCUMENT_CATEGORIES: 'document-categories',
    DOCUMENT_HISTORIES: 'document-histories',
    SAML_CONFIG: 'saml-configs',
    SP_METADATA: 'sp-metadata',
    DOWNLOAD: 'downloads',
    DOWNLOAD_ITEMS: 'download-items',
    COUNTERS: 'counters',
    RUN_ACTION_GROUP_REQUESTS: 'run-action-group-requests',
    REQUESTED_DOCUMENTS: 'requested-documents',
    REPORT_ITEMS: 'report-items',
    LABEL: 'labels',
    LABELED_ENTITY: 'labeled-entities',
    LOG_TEMPLATES: 'log-templates',
    LOG_ENTRIES: 'log-entries',
    SIGNATURE_REQUESTS: 'signature-requests',
    MONITOR_REVIEWS: 'monitor-reviews',
    MONITOR_REVIEW_EVENTS: 'monitor-review-events',
    PAYWALLS: 'paywalls',
    PAYWALL_TEAMS: 'paywall-teams',
    PAYWALL_USERS_MONITOR_REVIEW: 'paywall-users-monitor-review',
    STUDY: 'studies',
    STUDY_ROLES: 'study-roles',
    SITE: 'sites',
    STUDY_ENTITY: 'study-entities',
    TEAM_SIP_INTEGRATION_LINK: 'team-sip-integration-links',
    SIP_SPONSOR_NAME: 'sip-sponsor-names',
    REGION: 'regions',
    DOCUMENT_SIP_STATUS: 'document-sip-statuses',
    EVENT_BUS_BACKUP_MESSAGE: 'event-bus-backup-messages',
    STUDY_TEMPLATES: 'study-templates',
    QC_REVIEWS: 'qc-reviews',
    ROLE_CATEGORIES: 'role-categories',
    ANNOTATIONS: 'annotations'
};

module.exports = {
    entities,
    collections
};
