import * as angular from 'angular';

import partialTextHighlightFilter from './partial-text-highlight.filter';
import replaceSpecialCharsFilter from './replace-special-chars.filter';
import capitalizeFilter from './capitalize.filter';

const dependencies = [
];

const stringFilters = angular
    .module('app.widgets.stringFilters', dependencies)
    .filter('partialTextHighlight', partialTextHighlightFilter)
    .filter('replaceSpecialChars', replaceSpecialCharsFilter)
    .filter('capitalize', capitalizeFilter);

export default stringFilters;
