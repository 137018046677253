import template from './signing-pin-expiry-notification-wrapper.html';
import { SigningPinExpiryNotificationWrapperController as controller } from './signing-pin-expiry-notification-wrapper.controller';

export const signingPinExpiryNotificationWrapperComponent = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};
