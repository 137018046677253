export const DATE_PICKER_DEFAULT_OPTIONS = {
    initialPicker: 'date',
    enableDate: true,
    enableTime: true,
    closeOnDateSelection: true,
    closeOnTimeNow: true,
    appendToBody: true,
    showWeeks: false
};

export type DisableDateParams = { date: Date; mode: 'day' | 'month' | 'year' };

export type DatePickerOptions = typeof DATE_PICKER_DEFAULT_OPTIONS & {
    dateDisabled?: (params: DisableDateParams) => boolean;
}

export const DATE_PICKER_DEFAULT_BUTTON_BAR = {
    show: true,
    now: {
        show: false,
        text: 'Now',
        cls: 'sr-only'
    },
    today: {
        show: false,
        text: 'Today',
        cls: 'sr-only'
    },
    clear: {
        show: true,
        text: 'Clear',
        cls: 'btn-sm btn-default'
    },
    date: {
        show: true,
        text: 'Date',
        cls: 'btn-sm btn-default'
    },
    time: {
        show: true,
        text: 'Time',
        cls: 'btn-sm btn-default'
    },
    close: {
        show: true,
        text: 'Close',
        cls: 'btn-sm btn-default'
    },
    cancel: {
        show: false,
        text: 'Cancel',
        cls: 'btn-sm btn-default'
    }
};

export type DatePickerButtonBar = typeof DATE_PICKER_DEFAULT_BUTTON_BAR;
