import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import helpDeskRoute from './help-desk.route';
import helpDeskComponent from './containers/help-desk/help-desk.component';

const dependencies = [
    uiRouter
];

const helpDesk = angular
    .module('helpDesk', dependencies)
    .config(helpDeskRoute)
    .component('helpDeskComponent', helpDeskComponent);

export default helpDesk;
