import * as _ from 'lodash';
import { StateService } from '@uirouter/angularjs';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import { BindersService } from '@app/shared/binders/binders.service';
import { TeamService } from '@app/shared/teams/team.service';
import { Observable, of } from 'rxjs';
import { CurrentSessionService } from '@app/core/current-session.service';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import {
    Crumb,
    Team,
    Report,
    SIPStatusTypes,
    Study,
    Binder,
    Folder,
    Tag
} from '@app/shared/models';
import { DynamicFilterTypeItem } from '@app/widgets/dynamic-filter/dynamic-filter.component';

import { MonitorReviewStatus } from '@app/widgets/tables/generic-table/generic-table.component.types';
import { ModalHelperService } from '@app/shared/modal-helper/modal-helper.service';
import { getDateWithDaysOffset } from '@app/shared/date-time/get-date-with-days-offset.util';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { ReportGroupsNames, ReportNames } from '../../report-names';
import {
    ReportData,
    CompleteReportDataParams
} from '../../reports.service.types';
import {
    ReportsIndexStateService
} from '../../reports-index-state.service';
import { ReportService } from '../../report.service';
import {
    AdditionalFilter,
    DateFilterConfig,
    ReportLabel,
    ReportMonitorStatus,
    ReportQCReviewStatus,
    ReportSipStatus,
    TableUpdateParams
} from '../../components/reports-table/reports-table.component.types';
import { ReportsTableService } from '../../components/reports-table/reports-table.service';

class ReportsController {
    public currentTeam: Team;
    public reportParams: CompleteReportDataParams;
    public reportTypes: Report[];
    public selectedReport: Report;
    public report: ReportData;
    public filterBy: string;
    public selectedObjects: (Binder | Folder)[];
    public isLoading: boolean;
    public isTagReport: boolean;
    public isMonitorReviewApproveReport: boolean;
    public isDocumentsRecentlyUploadedReport: boolean;
    public isDocumentsOverviewReport: boolean;
    public allTags;
    public allMonitorStatuses: { name: string }[];
    public selectedTags;
    public selectedMonitorStatuses;
    public allFilteredBindersSelected: boolean;
    public allTagsSelected: boolean;
    public allMonitorStatusesSelected: boolean;
    public isLabelReport: boolean;
    public allLabels;
    public selectedLabels;
    public allLabelsSelected: boolean;
    public isSIPStatusReport: boolean;
    public allSIPStatuses;
    public selectedSIPStatuses;
    public allSIPStatusesSelected: boolean;
    public isQCStatusReport: boolean;
    public allQCStatuses;
    public selectedQCStatuses;
    public allQCStatusesSelected: boolean;
    public isStudyReport: boolean;
    public isReportInXDays: boolean;
    public numberOfDays: number;
    public allStudies: Study[] = [];
    public selectedStudy: Study | { id?} = {};
    public crumbs: Crumb[] = [{ name: 'Reports' }];
    private ReportNames = ReportNames;
    private ReportGroupsNames = ReportGroupsNames;
    hasDateInThePast = false;
    hasDateInTheFutureAndPast = false;
    hasDatePicker = false;
    public dateFilterConfig: DateFilterConfig;
    private readonly defaultTodaysDate = new Date(new Date().setHours(0, 0, 0, 0));
    filterTypes: DynamicFilterTypeItem[] = [];
    selectedFilterType: DynamicFilterTypeItem;
    isReportListLoading = true;
    isReportLoading = true;
    isSipReportAvailable = false;
    isApprovalsReportAvailable = false;
    binderFilterValue = '';
    showReportSuccessfull = true;
    isQCReportAvailable = false;
    isAllPlaceholdersReportEnabled;
    isNewDocumentsReportEnabled;

    public reportTabHeaders = {
        [this.ReportNames.pastDueDates]: 'Past Due',
        [this.ReportNames.dueDates]: 'All',
        [this.ReportNames.pastExpirationDates]: 'Expired',
        [this.ReportNames.expirationDates]: 'Expiring',
        [this.ReportNames.signaturesCompleted]: 'Completed',
        [this.ReportNames.signaturesMyQueue]: 'Signatures',
        [this.ReportNames.signaturesPending]: 'Pending',
        [this.ReportNames.signaturesDeclined]: 'Declined',
        [this.ReportNames.signaturesSignByDate]: 'Expiring',
        [this.ReportNames.tasksClosed]: 'Closed',
        [this.ReportNames.tasksMyQueue]: 'Tasks',
        [this.ReportNames.tasksPending]: 'Pending',
        [this.ReportNames.approvalsApproved]: 'Approved',
        [this.ReportNames.approvalsRejected]: 'Rejected',
        [this.ReportNames.approvalsCancelled]: 'Cancelled',
        [this.ReportNames.approvalsPendingSignatures]: 'Pending Signatures',
        [this.ReportNames.approvalsReadyForApproval]: 'Ready For Approval',
        [this.ReportNames.approvalsPendingForm]: 'Pending Form Completion',
        [this.ReportNames.labels]: 'All',
        [this.ReportNames.tags]: 'All',
        [this.ReportNames.studies]: 'Study Profile',
        [this.ReportNames.monitorReviewsOpen]: 'Queried',
        [this.ReportNames.monitorReviewsApproved]: 'Monitored',
        [this.ReportNames.sipDocumentStatuses]: 'Document Statuses',
        [this.ReportNames.documentsRecentlyUploaded]: 'Recent',
        [this.ReportNames.documentsOverview]: 'Overview - All',
        [this.ReportNames.sipDocumentsSent]: 'Documents Sent',
        [this.ReportNames.qcReport]: 'QC Review',
        [this.ReportNames.qcReviewMyQueue]: 'QC Reviews'
    }

    public reportGroups = [
        {
            name: ReportGroupsNames.myQueue,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.signaturesMyQueue],
                    reportName: this.ReportNames.signaturesMyQueue
                },
                {
                    index: 2,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.tasksMyQueue],
                    reportName: this.ReportNames.tasksMyQueue
                },
                {
                    index: 3,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.qcReviewMyQueue],
                    reportName: this.ReportNames.qcReviewMyQueue
                }
            ]
        },
        {
            name: ReportGroupsNames.documents,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.expirationDates],
                    reportName: this.ReportNames.expirationDates
                },
                {
                    index: 2,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.documentsRecentlyUploaded],
                    reportName: this.ReportNames.documentsRecentlyUploaded
                },
                {
                    index: 3,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.documentsOverview],
                    reportName: this.ReportNames.documentsOverview,
                    minWidth: '1750px'
                }
            ]
        },
        {
            name: ReportGroupsNames.labels,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.labels],
                    reportName: this.ReportNames.labels
                }
            ]
        },
        {
            name: ReportGroupsNames.placeholders,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.dueDates],
                    reportName: this.ReportNames.dueDates
                }
            ]
        },
        {
            name: ReportGroupsNames.reviews,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.monitorReviewsApproved],
                    reportName: this.ReportNames.monitorReviewsApproved
                },
                {
                    index: 2,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.monitorReviewsOpen],
                    reportName: this.ReportNames.monitorReviewsOpen
                }
            ]
        },
        {
            name: ReportGroupsNames.signatures,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.signaturesCompleted],
                    reportName: this.ReportNames.signaturesCompleted
                },
                {
                    index: 2,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.signaturesDeclined],
                    reportName: this.ReportNames.signaturesDeclined
                },
                {
                    index: 3,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.signaturesSignByDate],
                    reportName: this.ReportNames.signaturesSignByDate
                },
                {
                    index: 4,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.signaturesPending],
                    reportName: this.ReportNames.signaturesPending
                }
            ]
        },
        {
            name: ReportGroupsNames.studyProfiles,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.studies],
                    reportName: this.ReportNames.studies
                }
            ]
        },
        {
            name: ReportGroupsNames.tags,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.tags],
                    reportName: this.ReportNames.tags
                }
            ]
        },
        {
            name: ReportGroupsNames.tasks,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.tasksClosed],
                    reportName: this.ReportNames.tasksClosed
                },
                {
                    index: 2,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.tasksPending],
                    reportName: this.ReportNames.tasksPending
                }
            ]
        },
        {
            name: ReportGroupsNames.qc,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.qcReport],
                    reportName: this.ReportNames.qcReport
                }
            ]
        },
        {
            name: ReportGroupsNames.approvals,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.approvalsApproved],
                    reportName: this.ReportNames.approvalsApproved
                },
                {
                    index: 2,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.approvalsCancelled],
                    reportName: this.ReportNames.approvalsCancelled
                },
                {
                    index: 3,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.approvalsPendingForm],
                    reportName: this.ReportNames.approvalsPendingForm
                },
                {
                    index: 4,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.approvalsPendingSignatures],
                    reportName: this.ReportNames.approvalsPendingSignatures
                },
                {
                    index: 5,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.approvalsReadyForApproval],
                    reportName: this.ReportNames.approvalsReadyForApproval
                },
                {
                    index: 6,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.approvalsRejected],
                    reportName: this.ReportNames.approvalsRejected
                }
            ]
        },
        {
            name: ReportGroupsNames.sip,
            isVisible: true,
            activeTabIndex: 1,
            reports: [
                {
                    index: 1,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.sipDocumentStatuses],
                    reportName: this.ReportNames.sipDocumentStatuses
                },
                {
                    index: 2,
                    isVisible: true,
                    header: this.reportTabHeaders[this.ReportNames.sipDocumentsSent],
                    reportName: this.ReportNames.sipDocumentsSent
                }
            ]
        }
    ];

    public selectedReportGroup;
    public allBinders;

    constructor(
        private $q: ng.IQService,
        private $state: StateService,
        private $filter: ng.IFilterService,
        private ReportsIndexState: ReportsIndexStateService,
        private Binders: BindersService,
        private Teams: TeamService,
        private ReportsTable: ReportsTableService,
        private SORT,
        private CurrentSession: CurrentSessionService,
        private Labels,
        private Studies,
        private modalHelper: ModalHelperService,
        private ApiError: ApiErrorFactory,
        private featureFlags: FeatureFlagService,
        private ReportService: ReportService
    ) {
        this.isLoading = true;
        this.SORT = SORT;

        // properties for maintaining report data
        this.reportTypes = [];

        // properties for handling tag selection
        this.isTagReport = false;
        this.selectedTags = [];
        this.allTagsSelected = false;

        // properties for handling monitor review status selection
        this.isMonitorReviewApproveReport = false;
        this.selectedMonitorStatuses = [];
        this.allMonitorStatusesSelected = false;

        // property for handling documents recent default sorting
        this.isDocumentsRecentlyUploadedReport = false;

        // properties for handling label selection
        this.isLabelReport = false;
        this.selectedLabels = [];
        this.allLabelsSelected = false;

        // properties for handling SIP status selection
        this.isSIPStatusReport = false;
        this.selectedSIPStatuses = [];
        this.allSIPStatusesSelected = false;

        this.isQCStatusReport = false;
        this.selectedQCStatuses = [];
        this.allQCStatusesSelected = false;

        // properties for handling study selection
        this.isStudyReport = false;

        // property for handling digest notifications of old reports
        this.isReportInXDays = false;
    }

    $onInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        const { id: teamId } = this.currentTeam;

        this.featureFlags.getFlag(FEATURE_FLAGS.EBINDERS_SIP_REPORT, false).subscribe((data) => {
            this.isSipReportAvailable = data;
        });
        this.featureFlags.getFlag(FEATURE_FLAGS.EBINDERS_APPROVAL_REPORT, false).subscribe((data) => {
            this.isApprovalsReportAvailable = data;
        });
        this.featureFlags.getFlag(FEATURE_FLAGS.EBINDERS_QC_REPORT, false).subscribe((data) => {
            this.isQCReportAvailable = data;
        });
        this.featureFlags.getFlag(FEATURE_FLAGS.EBINDERS_DOCUMENTS_GENERAL_REPORT, false).subscribe((data) => {
            this.isNewDocumentsReportEnabled = data;
        });

        this.featureFlags.getFlag(FEATURE_FLAGS.ALL_PLACEHOLDERS_REPORT, false).subscribe((data) => {
            this.isAllPlaceholdersReportEnabled = data;
            if (!this.isAllPlaceholdersReportEnabled && this.isAllPlaceholdersReportEnabled !== undefined) {
                this.reportGroups.map((reportGroup) => {
                    if (reportGroup.name === ReportGroupsNames.placeholders) {
                        reportGroup.reports[0].header = 'Pending';
                    }
                    return reportGroup;
                });
            }
        });


        this.$q.all({
            reports: this.ReportService.loadReports(teamId).toPromise(),
            binders: this.Binders
                .getBinders(teamId, { includeArchived: false })
                .toPromise()
                .catch(this.ApiError.handleError),
            tags: this.Teams.getTags(teamId).toPromise(),
            labels: this.currentTeam.settings.features.labels && this.Labels.getLabels(teamId).toPromise(),
            studies: this.currentTeam.permissions.viewTeamStudyProfiles
                && this.Studies.getStudies(teamId, { withPagination: false }).toPromise()
        }).then((data) => {
            this.reportTypes = data.reports;
            this.allBinders = data.binders;
            this.allTags = this.$filter('orderBy')(data.tags, 'name', false, this.SORT.naturalSort);
            this.allMonitorStatuses = Object.keys(MonitorReviewStatus).map((statusName) => ({ name: statusName }));
            this.allLabels = this.$filter('orderBy')(data.labels || [], 'name', false, this.SORT.naturalSort);
            this.allStudies = this.$filter('orderBy')(data.studies ? data.studies.items : [], 'uniqueProtocolId', false, this.SORT.naturalSort);
            this.allSIPStatuses = Object.values(SIPStatusTypes).map((statusName) => ({ name: statusName }));
            this.allQCStatuses = Object.values(qcReviewStatuses).map((statusName) => ({ name: statusName }));

            this.isLoading = false;

            this._hideDisabledReportGroups();

            const stateParams = this.$state.params as unknown as { reportId: string | undefined, teamId: string };
            this.reportParams = this.initializeReportParams({ ..._.cloneDeep(stateParams), teamId });

            // If there is no reportId, we should default to the first report in the first group and get the reports data
            if (!this.reportParams.reportId) {
                this.selectReportGroup(this.reportGroups[0], this.reportGroups[0].reports[0]);
                this.isReportListLoading = false;
                return;
            }

            // If there is a reportId, we need to see if our storage contains selected binders/folders or not
            // If there are binders/folders, than it means we're switching reports or groups
            // If there are no binders/folders, it means user came to the report through link, and we need to select all binders
            if (this.reportParams.binders || this.reportParams.folders) {
                this.selectedObjects = [...this.reportParams.binders, ...(this.reportParams.folders || [])];
            }
            else {
                this.selectedObjects = this.allBinders;
                this.reportParams.binders = this.allBinders;
            }

            if (this.selectedObjects?.length || this.reportParams.reportId !== '') {
                this._initializeReportGroups(data.reports);
            }

            // check if that report is available in the team
            switch (this.selectedReportGroup.name) {
                case ReportGroupsNames.sip:
                    if (!this.isSipReportAvailable) {
                        this.selectReportGroup(this.reportGroups[0], this.reportGroups[0].reports[0]);
                    }
                    break;
                case ReportGroupsNames.approvals:
                    if (!this.isApprovalsReportAvailable) {
                        this.selectReportGroup(this.reportGroups[0], this.reportGroups[0].reports[0]);
                    }
                    break;
                default:
                    break;
            }
        });

    }

    _loadReportData(): void {
        const isDownloadOnlyReport = this.isStudyReport;
        const reportFn: () => Observable<ReportData> = isDownloadOnlyReport
            ? (): Observable<ReportData> => of({} as ReportData)
            : (): Observable<ReportData> => this.ReportService.loadReportData(this.reportParams);

        this.isReportLoading = true;

        reportFn().subscribe((report: ReportData) => {
            if (this.reportParams?.binders?.length) {
                this.selectedObjects = this.reportParams?.binders;
            }
            if (this.reportParams?.folders?.length) {
                this.selectedObjects.push(...this.reportParams.folders);
            }
            this.report = report;
            this.$state.go('app.team.reports', this.reportParams, { reload: false });
            this.reportParams = { ...this.reportParams };
            this.showReportSuccessfull = true;
            this.isReportLoading = false;
        });
    }


    _hideDisabledReportGroups() {
        if (!this.currentTeam.settings.features.labels) {
            const labelsGroupIndex = this.reportGroups.findIndex((report) => report.name === ReportGroupsNames.labels);
            this.reportGroups[labelsGroupIndex].isVisible = false;
        }
        if (!this.currentTeam.settings.features.monitorReview
            || !this.reportTypes.filter((report) => [
                ReportNames.monitorReviewsApproved,
                ReportNames.monitorReviewsOpen
            ].includes(report.name as ReportNames))?.length
        ) {
            const reviewsGroupIndex = this.reportGroups.findIndex((report) => report.name === ReportGroupsNames.reviews);
            this.reportGroups[reviewsGroupIndex].isVisible = false;
        }
        if (!this.isApprovalsReportAvailable) {
            const approvalsGroupIndex = this.reportGroups.findIndex((report) => report.name === ReportGroupsNames.approvals);
            this.reportGroups[approvalsGroupIndex].isVisible = false;
        }
        if (!this.isSipReportAvailable) {
            const sipGroupIndex = this.reportGroups.findIndex((report) => report.name === ReportGroupsNames.sip);
            this.reportGroups[sipGroupIndex].isVisible = false;
        }

        if (!this.isQCReportAvailable) {
            const qcGroupIndex = this.reportGroups.findIndex((report) => report.name === ReportGroupsNames.qc);
            this.reportGroups[qcGroupIndex].isVisible = false;
        }

        if (!this.isQCReportAvailable && this.reportTypes.filter((report) => [
            ReportNames.qcReviewMyQueue
        ].includes(report.name as ReportNames))?.length) {
            const groupIndex = this.reportGroups.findIndex((report) => report.name === ReportGroupsNames.myQueue);
            const reportIndex = this.reportGroups[groupIndex].reports
                .findIndex((report) => report.reportName === ReportNames.qcReviewMyQueue);
            this.reportGroups[groupIndex].reports[reportIndex].isVisible = false;
        }

        if (!this.isNewDocumentsReportEnabled) {
            const groupIndex = this.reportGroups.findIndex((report) => report.name === ReportGroupsNames.documents);
            // set Documents General to not be visible
            const reportIndex = this.reportGroups[groupIndex].reports
                .findIndex((report) => report.reportName === ReportNames.documentsOverview);
            this.reportGroups[groupIndex].reports[reportIndex].isVisible = false;
        }
    }

    _initializeReportGroups(reports: Report[]) {

        const reportName = reports.find((report) => report.id === this.reportParams.reportId).name;

        if (reportName.includes('Due -')) {
            const reportGroup = this.reportGroups.find((group) => {
                return group.name === ReportGroupsNames.placeholders;
            });

            this._initializeReportsInXDaysGroup(reportName, reportGroup);
            return;

        }
        if (reportName.includes('Expiring -')) {
            const reportGroup = this.reportGroups.find((group) => {
                return group.name === ReportGroupsNames.documents;
            });

            this._initializeReportsInXDaysGroup(reportName, reportGroup);
            return;
        }
        this.reportGroups.forEach((reportGroup) => {
            reportGroup.reports.forEach((report) => {
                if (report.reportName === reportName) {
                    reportGroup.activeTabIndex = report.index;
                    this.selectReportGroup(reportGroup, report);
                    this.isReportListLoading = false;
                }
            });
        });
    }

    _initializeReportsInXDaysGroup(reportName: string, reportGroup) {
        this._setReportInXDaysFlag(reportName);
        this.selectReportGroup(reportGroup, reportGroup.reports[0]);
        this.isReportListLoading = false;
    }

    _setReportInXDaysFlag(reportName: string) {
        switch (reportName) {
            case 'Documents Expiring - In 2 Days':
            case 'Placeholders Due - In 2 Days':
                this.isReportInXDays = true;
                this.numberOfDays = 2;
                break;
            case 'Documents Expiring - In 14 Days':
            case 'Placeholders Due - In 14 Days':
                this.isReportInXDays = true;
                this.numberOfDays = 14;
                break;
            case 'Documents Expiring - In 30 Days':
            case 'Placeholders Due - In 30 Days':
                this.isReportInXDays = true;
                this.numberOfDays = 30;
                break;
            case 'Documents Expiring - In 60 Days':
            case 'Placeholders Due - In 60 Days':
                this.isReportInXDays = true;
                this.numberOfDays = 60;
                break;
            default:
                this.isReportInXDays = false;
                break;
        }
    }

    areItemsSelected() {
        return !!(this.selectedObjects && this.selectedObjects.length);
    }

    clearReportData(): void {
        delete this.report;
    }

    initializeDropdownSelections(report: Report) {
        switch (report.name) {
            case ReportNames.labels:
                this.selectedLabels = this.allLabels;
                break;
            case ReportNames.tags:
                this.selectedTags = this.allTags;
                break;
            case ReportNames.monitorReviewsApproved:
                this.selectedMonitorStatuses = this.allMonitorStatuses;
                break;
            case ReportNames.sipDocumentStatuses:
                this.selectedSIPStatuses = this.allSIPStatuses;
                break;
            case ReportNames.qcReport:
            case ReportNames.qcReviewMyQueue:
                this.selectedQCStatuses = this.allQCStatuses;
                break;
            default:
                break;
        }
    }

    selectReport(report: Report): void {

        if (report.id !== this.selectedReport?.id) {
            this.initializeDropdownSelections(report);
        }
        this.selectedReport = _.find(this.reportTypes, { id: report.id });

        this.isTagReport = this._isReport(this.ReportNames.tags);
        this.isMonitorReviewApproveReport = this._isReport(this.ReportNames.monitorReviewsApproved);
        this.isDocumentsRecentlyUploadedReport = this._isReport(this.ReportNames.documentsRecentlyUploaded);
        this.isDocumentsOverviewReport = this._isReport(this.ReportNames.documentsOverview);
        this.isLabelReport = this._isReport(this.ReportNames.labels);
        this.isStudyReport = this._isReport(this.ReportNames.studies);
        this.isSIPStatusReport = this._isReport(this.ReportNames.sipDocumentStatuses);
        this.isQCStatusReport = this._isReport(this.ReportNames.qcReport)
            || this._isReport(this.ReportNames.qcReviewMyQueue);

        const reportsWithDateInThePast = [
            this.ReportNames.documentsRecentlyUploaded,
            this.ReportNames.labels,
            this.ReportNames.monitorReviewsApproved,
            this.ReportNames.monitorReviewsOpen,
            this.ReportNames.signaturesCompleted,
            this.ReportNames.signaturesDeclined,
            this.ReportNames.signaturesPending,
            this.ReportNames.tasksClosed,
            this.ReportNames.tasksMyQueue,
            this.ReportNames.tasksPending,
            this.ReportNames.qcReport,
            this.ReportNames.qcReviewMyQueue,
            this.ReportNames.documentsOverview
        ];
        const reportsWithDateInTheFutureAndPast = [
            this.ReportNames.dueDates,
            this.ReportNames.expirationDates,
            this.ReportNames.signaturesMyQueue,
            this.ReportNames.signaturesSignByDate
        ];

        this.hasDateInThePast = reportsWithDateInThePast.includes(this.selectedReport.name as ReportNames);
        this.hasDateInTheFutureAndPast = reportsWithDateInTheFutureAndPast.includes(this.selectedReport.name as ReportNames);
        this.hasDatePicker = this.hasDateInThePast || this.hasDateInTheFutureAndPast;

        if (this.hasDatePicker && !this.isReportInXDays) {
            this.setInitialDateFilterConfig();
        }
        else if (this.hasDatePicker && this.isReportInXDays) {
            this.setInitialDateFilterConfig(undefined, this.numberOfDays);
        }

        if (!this.isStudyReport) {
            this.selectedStudy = {};
        }

        const reportParamsFromStorage = this.ReportsIndexState.getReportParams(this.currentTeam.id, this.selectedReport.id);
        if (reportParamsFromStorage) {
            this.reportParams = reportParamsFromStorage;

            if (this.reportParams?.binders?.length || this.reportParams?.folders?.length) {
                this.selectedObjects = [];
                this.reportParams?.binders?.length && this.selectedObjects.push(...this.reportParams.binders);
                this.reportParams?.folders?.length && this.selectedObjects.push(...this.reportParams.folders);
            }

            if (this.reportParams?.monitorStatuses) {
                this.selectedMonitorStatuses = this.reportParams.monitorStatuses.map((status) => {
                    return {
                        isSelected: true,
                        name: status
                    };
                });
            }

            if (this.reportParams?.sipStatuses) {
                this.selectedSIPStatuses = this.reportParams.sipStatuses.map((status) => {
                    return {
                        isSelected: true,
                        name: status
                    };
                });
            }

            if (this.reportParams?.qcStatuses) {
                this.selectedQCStatuses = this.reportParams.qcStatuses.map((status) => {
                    return {
                        isSelected: true,
                        name: status
                    };
                });
            }

            if (this.reportParams?.labelIds) {
                this.selectedLabels = this.allLabels.filter((label) => this.reportParams.labelIds.includes(label.id));
            }

            if (this.reportParams?.tagIds) {
                this.selectedTags = this.allTags.filter((tag) => this.reportParams.tagIds.includes(tag.id));
            }

            if (this.reportParams?.studyIds) {
                this.selectedStudy = this.allStudies.find((study) => this.reportParams.studyIds.includes(study.id));
            }
        }
        this.showReport();
    }

    displayReportTable() {
        return (!this.isReportLoading
            && (!this.isLabelReport || !!this.selectedObjects?.length || !!this.selectedLabels?.length)
            && (!this.isTagReport || !!this.selectedObjects?.length || !!this.selectedTags?.length)
            && (!this.isMonitorReviewApproveReport || !!this.selectedObjects?.length || !!this.selectedMonitorStatuses?.length)
            && (this.selectedReport.name !== ReportNames.sipDocumentStatuses
                || !!this.selectedObjects?.length
                || !!this.selectedSIPStatuses?.length
            ))
            || (this.selectedReport.name === ReportNames.studies);
    }

    get showSpinner() {
        return !this.displayReportTable() && this.reportParams?.reportId;
    }

    get showBinderFolderSelectionText() {
        return ((this.isReportLoading && !this.reportParams?.reportId)) || !this.showReportSuccessfull;
    }

    showReport(): void {
        if (!this.hasAllSelectionsToShowTable()) {
            this.showReportSuccessfull = false;
            return;
        }

        const withPermissions = this.selectedReport.name === this.ReportNames.tasksPending
            || this.selectedReport.name === this.ReportNames.signaturesPending
            || this.selectedReport.name === this.ReportNames.signaturesSignByDate;
        const { filterBy, teamId, filterField } = this.reportParams;

        let additionalFilters = null;
        const rememberedParams = this.ReportsIndexState.getReportParams(this.currentTeam.id, this.selectedReport.id);

        if (rememberedParams?.additionalFilters) {
            additionalFilters = rememberedParams.additionalFilters;
        }

        const selectedTagIds = this.isTagReport && this.selectedTags.map((tag) => tag.id);
        const selectedMonitorStatuses = this.isMonitorReviewApproveReport
            && this.selectedMonitorStatuses.map((monitorStatus) => monitorStatus.name);

        const selectedLabelIds = this.isLabelReport && this.selectedLabels.map((label) => label.id);

        const selectedStudyIds = this.isStudyReport && this.selectedStudy && this.selectedStudy.id && [this.selectedStudy.id];
        const sipStatuses = this.isSIPStatusReport && this.selectedSIPStatuses.map(({ name }) => name);
        const qcStatuses = this.isQCStatusReport && this.selectedQCStatuses.map(({ name }) => name);


        const reportSpecificParams = {
            tagIds: selectedTagIds,
            monitorStatuses: selectedMonitorStatuses,
            labelIds: selectedLabelIds,
            studyIds: selectedStudyIds,
            sipStatuses,
            qcStatuses,
            sortBy: this.ReportsTable.getDefaultSort(this.selectedReport.name),
            ...(this.hasDatePicker && (this.dateFilterConfig.start !== null || this.dateFilterConfig.end !== null)) && {
                dateFilter: {
                    start: this.dateFilterConfig.start,
                    end: this.dateFilterConfig.end
                },
                filterField
            },
            sortDir: 'ASC',
            ...this.isMonitorReviewApproveReport && {
                sortDir: 'DESC'
            },
            ...this.isDocumentsRecentlyUploadedReport && {
                sortDir: 'DESC'
            },
            ...this.isDocumentsOverviewReport && {
                sortDir: 'DESC'
            },
            ...(additionalFilters && additionalFilters.length) && { additionalFilters },
            filterBy,
            pageNum: 1,
            pageSize: 20
        };

        const teamReportParams = {
            teamId,
            reportId: this.selectedReport.id,
            withPermissions,
            binders: this._filterEntitiesByType(this.selectedObjects, 'binder'),
            folders: this._filterEntitiesByType(this.selectedObjects, 'folder')
        };

        this.reportParams = {
            ...reportSpecificParams,
            ...teamReportParams
        } as CompleteReportDataParams;

        this.ReportsIndexState
            .setReportParams(this.currentTeam.id, this.selectedReport.id, reportSpecificParams, teamReportParams);
        this._loadReportData();
    }

    _filterEntitiesByType(entitiesArray: (Binder | Folder)[], type: 'binder' | 'folder') {
        const seenIds = new Set();

        return entitiesArray?.reduce((resultArray, item) => {
            if (item.type === type && !seenIds.has(item.id)) {
                seenIds.add(item.id);
                resultArray.push(item);
            }
            return resultArray;
        }, []) || [];
    }

    onTagsSelectionChange(selectedTags: Tag[]) {
        this.selectedTags = selectedTags;
        this.reportParams.tagIds = this.selectedTags.map((tag) => tag.id);
        this.ReportsIndexState.setReportParams(this.currentTeam.id, this.selectedReport.id, this.reportParams);
        this.showReport();
    }

    onLabelsSelectionChange(selectedLabels: ReportLabel) {
        this.selectedLabels = selectedLabels;
        this.reportParams.labelIds = this.selectedLabels.map((label) => label.id);
        this.ReportsIndexState.setReportParams(this.currentTeam.id, this.selectedReport.id, this.reportParams);
        this.showReport();
    }

    onMonitorStatusesSelectionChange(selectedMonitorStatuses: ReportMonitorStatus[]) {
        this.selectedMonitorStatuses = selectedMonitorStatuses;
        this.reportParams.monitorStatuses = this.selectedMonitorStatuses.map((status: ReportMonitorStatus) => status.name);
        this.ReportsIndexState.setReportParams(this.currentTeam.id, this.selectedReport.id, this.reportParams);
        this.showReport();
    }

    onSIPStatusesSelectionChange(selectedSIPStatuses: ReportSipStatus[]) {
        this.selectedSIPStatuses = selectedSIPStatuses;
        this.reportParams.sipStatuses = this.selectedSIPStatuses.map((status) => status.name);
        this.ReportsIndexState.setReportParams(this.currentTeam.id, this.selectedReport.id, this.reportParams);
        this.showReport();
    }

    onQCStatusesSelectionChange(selectedQcStatuses: ReportQCReviewStatus[]) {
        this.selectedQCStatuses = selectedQcStatuses;
        this.reportParams.qcStatuses = this.selectedQCStatuses.map((status) => status.name);
        this.ReportsIndexState.setReportParams(this.currentTeam.id, this.selectedReport.id, this.reportParams);
        this.showReport();
    }

    onStudySelectionChange(study: Study) {
        this.selectedStudy = study;
        this.reportParams.studyIds = this.selectedStudy.id;
        this.ReportsIndexState.setReportParams(this.currentTeam.id, this.selectedReport.id, this.reportParams);
        this.showReport();
    }

    updateTableSortOrPage(event: TableUpdateParams): void {
        const { pageNum, sortDir, sortBy } = event;

        if (this.reportParams.pageNum === pageNum
            && this.reportParams.sortDir === sortDir
            && this.reportParams.sortBy === sortBy) {
            return;
        }
        _.assign(this.reportParams, {
            pageNum,
            sortDir,
            sortBy,
            ...(this.hasDateInThePast && (this.dateFilterConfig.start !== null || this.dateFilterConfig.end !== null)) && {
                dateFilter: {
                    start: this.dateFilterConfig.start,
                    end: this.dateFilterConfig.end
                }
            },
            ...(this.hasDateInTheFutureAndPast
                && (this.dateFilterConfig.start !== null || this.dateFilterConfig.end !== null)) && {
                dateFilter: {
                    start: this.dateFilterConfig.start,
                    end: this.dateFilterConfig.end
                }
            }
        }, { reportId: this.selectedReport.id });
        this.ReportsIndexState.setReportParams(this.currentTeam.id, this.selectedReport.id, this.reportParams);

        this._loadReportData();
    }

    updateTableAdditionalFilters(additionalFilters: AdditionalFilter[]): void {
        _.assign(this.reportParams, {
            pageNum: 1,
            ...(this.hasDateInThePast && (this.dateFilterConfig.start !== null || this.dateFilterConfig.end !== null)) && {
                dateFilter: {
                    start: this.dateFilterConfig.start,
                    end: this.dateFilterConfig.end
                }
            },
            ...(this.hasDateInTheFutureAndPast
                && (this.dateFilterConfig.start !== null || this.dateFilterConfig.end !== null)) && {
                dateFilter: {
                    start: this.dateFilterConfig.start,
                    end: this.dateFilterConfig.end
                }
            },
            ...(additionalFilters && additionalFilters.length) && {
                additionalFilters
            }
        }, { reportId: this.selectedReport.id });

        if (this.reportParams?.additionalFilters?.length && !additionalFilters?.length) {
            delete this.reportParams.additionalFilters;
        }
        this.ReportsIndexState.setReportParams(this.currentTeam.id, this.selectedReport.id, this.reportParams);

        this._loadReportData();
    }

    openSelectObjectsModal(): void {
        this.modalHelper.open({
            animation: false,
            component: 'choose-binders-folders-wrapper',
            size: 'lg',
            resolve: {
                objects: () => this.selectedObjects,
                objectsChanged: () => (objects): void => {
                    this.selectedObjects = objects;
                    this.showReport();
                }
            }
        });
    }

    setStartFilter(value: Date): void {
        const startValue = value ? new Date(value.setHours(0, 0, 0, 0)) : null;
        this.dateFilterConfig.start = startValue;
        this.dateFilterConfig.minEnd = startValue;
        this.showReport();
    }

    setEndFilter(value: Date): void {
        const endValue = value ? new Date(value.setHours(23, 59, 59, 999)) : null;
        this.dateFilterConfig.end = endValue;
        this.dateFilterConfig.maxStart = endValue;

        /** When the date picker is only in the past if the user clears end (to)
         *  date picker, we want to preserve today's date as the maxStart and
         *  not null.
         */
        if (this.hasDateInThePast && !endValue) {
            this.dateFilterConfig.maxStart = this.defaultTodaysDate;
        }
        this.showReport();
    }

    hasAllSelectionsToShowTable(): boolean {
        return this.selectedReport && this.selectedReport.name
            && (
                !!(this.selectedObjects && this.selectedObjects.length)
                || (this.selectedReport.name === ReportNames.studies)
            );
    }

    _isReport(name: string): boolean {
        return this.selectedReport.name === name;
    }

    initializeReportParams(stateParams: { reportId: string, teamId: string }): CompleteReportDataParams {
        const reportParams = this.ReportsIndexState.getReportParams(this.currentTeam.id, stateParams.reportId);

        if (!reportParams || reportParams.teamId !== stateParams.teamId) {
            this.ReportsIndexState.clearReportParams(this.currentTeam.id);
            return { ...stateParams, pageNum: 1 };
        }
        const dateFilter = reportParams ? reportParams.dateFilter : null;
        this.setInitialDateFilterConfig(dateFilter);
        return reportParams;
    }

    private setInitialDateFilterConfig(params?: { start: Date; end: Date }, numberOfDays?: number): void {

        const reportParamsFromStorage = this.selectedReport
            ? this.ReportsIndexState.getReportParams(this.currentTeam.id, this.selectedReport.id) : null;

        let start = reportParamsFromStorage?.dateFilter?.start || null;
        let end = reportParamsFromStorage?.dateFilter?.end || null;

        if (params && params.start) {
            start = new Date(params.start);
        }
        if (params && params.end) {
            end = new Date(params.end);
        }

        if (numberOfDays !== undefined) {
            start = new Date();
            end = getDateWithDaysOffset(start, numberOfDays);
        }

        if (this.hasDateInThePast) {

            this.dateFilterConfig = {
                start,
                end,
                minStart: null,
                maxStart: end || this.defaultTodaysDate,
                minEnd: start,
                maxEnd: this.defaultTodaysDate
            };

        }
        else {
            this.dateFilterConfig = {
                start,
                end,
                minStart: null,
                maxStart: end,
                minEnd: start,
                maxEnd: null
            };
        }
    }

    selectReportGroup(reportGroup, report) {
        this.isReportLoading = true;
        this.selectedReportGroup = reportGroup;
        const foundReport = report
            || this.selectedReportGroup.reports.find((r) => r.index === this.selectedReportGroup.activeTabIndex);
        reportGroup.activeTabIndex = foundReport.index;
        this.selectReport(this.reportTypes.find((r) => r.name === foundReport.reportName));
    }

    $onDestroy() {
        this.ReportsIndexState.clearReportParams(this.currentTeam.id);
        this.ReportsTable.clearAllColumnFilters();
    }
}

ReportsController.$inject = [
    '$q',
    '$state',
    '$filter',
    'ReportsIndexState',
    'Binders',
    'Team',
    'ReportsTable',
    'SORT',
    'CurrentSession',
    'Labels',
    'Studies',
    'modalHelper',
    'ApiError',
    'FeatureFlagService',
    'ReportService'
];

export default ReportsController;
