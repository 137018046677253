import template from './user-profile.html';
import { UserProfileController as controller } from './user-profile.controller';
import './user-profile.scss';

const userProfileComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {}
};

export default userProfileComponent;
