import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';

import globalViewRoute from './global-view.route';
import { GlobalViewComponent } from './containers/global-view/global-view.component';
import { GlobalViewItemComponent } from './components/global-view-item/global-view-item.component';
import globalViewSelectRootComponent from './components/global-view-select-root/global-view-select-root.component';

const dependencies = [
    uiRouter
];

const globalView = angular
    .module('globalView', dependencies)
    .config(globalViewRoute)
    .directive('globalView', downgradeComponent({ component: GlobalViewComponent }) as ng.IDirectiveFactory)
    .directive('globalViewItem', downgradeComponent({ component: GlobalViewItemComponent }) as ng.IDirectiveFactory)
    .component('globalViewSelectRoot', globalViewSelectRootComponent);

export default globalView;
