/* eslint-disable no-prototype-builtins */

import { Transition } from '@uirouter/angularjs';
import { NotificationsService } from '@app/core/notifications/notifications.service';

const sessionResumedToasterNotification = (
    $transitions: Transition,
    Notifications: NotificationsService,
    $location: ng.ILocationService
): void => {
    $transitions.onSuccess({}, () => {
        const propertyToCatch = 'sessionResumed';
        const locationSearch = $location.search();

        if (locationSearch.hasOwnProperty(propertyToCatch)) {
            Notifications.success('Navigation to last page viewed before automatic sign-out');
            $location.search(propertyToCatch, null);
        }
    });
};

sessionResumedToasterNotification.$inject = [
    '$transitions',
    'Notifications',
    '$location'
];

export default sessionResumedToasterNotification;
