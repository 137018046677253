import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '@app/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UIRouterModule } from '@uirouter/angular';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { RolesService } from './roles.service';
import { PermissionsModule } from '../permissions/permissions.module';
import { CreateRoleComponent } from './components/create-role/create-role.component';
import { DeleteRoleComponent } from './components/delete-role/delete-role.component';
import { DuplicateRoleComponent } from './components/duplicate-role/duplicate-role.component';
import { RolePermissionsFormComponent } from './components/role-permissions-form/role-permissions-form.component';
import { RolesTemplatesByStudyNumberComponent } from './components/roles-templates-by-study-number/roles-templates-by-study-number.component';
import { RequestPreviewRoleTemplatesComponent } from './components/request-preview-role-templates/request-preview-role-templates.component';
import { AssignUsersToRolesComponent } from './components/assign-users-to-roles/assign-users-to-roles.component';
import { RolesTemplatesPreviewComponent } from './components/roles-templates-preview/roles-templates-preview.component';
import { RolesTemplatesByTemplateComponent } from './components/roles-templates-by-template/roles-templates-by-template.component';
import { RenameRoleComponent } from './components/rename-role/rename-role.component';
import { ManageRolesTemplatesComponent } from './containers/manage-roles-templates/manage-roles-templates.component';
import { RolePermissionsComponent } from './containers/role-permissions/role-permissions.component';

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        PaginationModule.forRoot(),
        AccordionModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        WidgetsModule,
        EntityModule,
        SharedModule,
        PermissionsModule,
        UIRouterModule
    ],
    providers: [
        RolesService,
        NotificationsService
    ],
    declarations: [
        DuplicateRoleComponent,
        DeleteRoleComponent,
        RolePermissionsFormComponent,
        RequestPreviewRoleTemplatesComponent,
        AssignUsersToRolesComponent,
        RolesTemplatesPreviewComponent,
        RolesTemplatesByStudyNumberComponent,
        RolesTemplatesByTemplateComponent,
        CreateRoleComponent,
        RenameRoleComponent,
        ManageRolesTemplatesComponent,
        RolePermissionsComponent
    ],
    entryComponents: [
        DuplicateRoleComponent,
        DeleteRoleComponent,
        RolePermissionsFormComponent,
        RequestPreviewRoleTemplatesComponent,
        AssignUsersToRolesComponent,
        RolesTemplatesPreviewComponent,
        RolesTemplatesByStudyNumberComponent,
        RolesTemplatesByTemplateComponent,
        CreateRoleComponent,
        RenameRoleComponent,
        ManageRolesTemplatesComponent,
        RolePermissionsComponent
    ],
    exports: []
})
export class RolesModule {}
