import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { UserEntersTeamAuditService } from '@app/core/user-enters-team/user-enters-team-audit.service';
import { DownloadCheckerService } from '../downloads/download-checker.service';
import { Auth0Service } from '../sessions/auth0.service';
import { Auth0ErrorService } from '../auth0-error/auth0-error.service';
import { PendoScriptInitializerService } from '../../core/analytics/pendo-script-initializer.service';
import { AppConfigService } from '../app-config/app-config.service';
import { TeamInboxCheckerService } from '../teams-inbox/team-inbox-checker.service';
@Injectable({
    providedIn: 'root'
})
export class AppInitializationService {

    constructor(
        private downloadChecker: DownloadCheckerService,
        private teamInboxChecker: TeamInboxCheckerService,
        private auth0Service: Auth0Service,
        private auth0ErrorService: Auth0ErrorService,
        private pendoScriptInitializer: PendoScriptInitializerService,
        private userEntersTeamAuditService: UserEntersTeamAuditService,
        private appConfigService: AppConfigService
    ) {}

    init(): Observable<void> {
        return this.auth0ErrorService.init().pipe(
            concatMap(() => this.appConfigService.init()),
            concatMap(() => this.auth0Service.init()),
            concatMap(() => of(this.downloadChecker.init())),
            concatMap(() => of(this.teamInboxChecker.init())),
            concatMap(() => of(this.pendoScriptInitializer.init())),
            concatMap(() => of(this.userEntersTeamAuditService.init())),
            map(() => undefined)
        );
    }
}
