import {
    Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from '@angular/core';
import {
    BehaviorSubject, combineLatest, Observable, of, Subject
} from 'rxjs';
import {
    filter, map, take, takeUntil, tap
} from 'rxjs/operators';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import * as _ from 'lodash';
import {
    Site, Study, StudyWithTeamSipIntegrationLink, GetTeamMember, User
} from '@app/shared/models';
import { MESSAGES, REGEX, SORT } from '@app/core/constants';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { notBlank } from '@app/core/form-validators';

import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { StudyRoleIdName, StudyTeamPreview } from '@app/shared/study-roles/study-roles.types';
import { ConfirmationModal } from '@app/shared/modals/confirmation-modal/confirmation-modal';
import template from './study-form.component.html';
import styles from './study-form.component.scss';
import { StudySiteViewMode } from './study-form.types';
import { FilteredSelectEvent } from '../../../../widgets/filtered-select/filtered-select.component';
import { StudyTeamImportComponent } from '../study-team-import/study-team-import.component';
import { StudyRolesService } from '../../../../shared/study-roles/study-roles.service';


@Component({
    selector: 'study-form',
    template,
    styles: [String(styles)]
})
export class StudyFormComponent implements OnChanges, OnInit, OnDestroy {
    @Input() study: Study;
    @Input() uniqueProtocolIds: string[];
    @Output() submit = new EventEmitter<{study: Partial<Study>; next: boolean; uniqueProtocolIdChanged: boolean}>();

    studyFields: string[];

    readonly validationDuplicateUPIDErrMessage = 'Unique Protocol ID field must be unique';

    studyForm = this.fb.group({
        uniqueProtocolId: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank,
            this.validateUniqueProtocolIdUnique()
        ]],
        nickname: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]],
        sponsor: ['', [
            Validators.required,
            Validators.pattern(REGEX.names),
            Validators.maxLength(250),
            notBlank
        ]],
        cro: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        device: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        condition: ['', [
            Validators.pattern(REGEX.names),
            Validators.maxLength(250)
        ]],
        sites: this.fb.array([], Validators.minLength(1)),
        teamSipIntegrationLink: this.fb.group({
            sipDatabaseStudyId: [{ value: '', disabled: true }]
        })
    });

    private readonly destroy$ = new Subject<void>();

    isStudySiteTeamEnabled = false;

    studySitesViewMode = new Map<number, StudySiteViewMode>();

    studyRoles$ = this.studyRolesService.studyRolesList$;

    private isLoading = new BehaviorSubject<boolean>(false);
    isLoading$ = this.isLoading.asObservable();

    private isSaveDisabled = new BehaviorSubject<boolean>(true);
    isSaveDisabled$ = this.isSaveDisabled.asObservable();

    private saveButtonTooltip = new BehaviorSubject<string>(null);
    saveButtonTooltip$ = this.saveButtonTooltip.asObservable();

    constructor(
        private fb: FormBuilder,
        private FeatureFlags: FeatureFlagService,
        private studyRolesService: StudyRolesService,
        private Modals: ModalsService
    ) {}

    ngOnInit(): void {
        this.getStudyFields();

        this.setInitialStudySitesViewMode(0);

        this.setStudySiteTeamFeatureFlag$().pipe(
            tap(() => {
                this.initializeForm(this.study);
            })
        ).subscribe();

        this.studyRolesService.setStudyRolesList$.pipe(
            take(1)
        ).subscribe();

        this.createIsSaveDisabledSubscription();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.study) {
            this.isLoading.next(changes.study.currentValue === undefined);
            this.initializeForm(changes.study.currentValue);
        }
    }

    private createIsSaveDisabledSubscription(): void {
        combineLatest([
            this.isLoading$,
            this.studyForm.statusChanges,
            this.studyForm.valueChanges
        ]).pipe(
            tap(([isLoading]) => {
                const isSaveDisabled = isLoading
                    || this.studyForm.pristine
                    || this.studyForm.invalid
                    || (!this.isStudyChanged && !this.isAnySiteChanged);

                this.isSaveDisabled.next(isSaveDisabled);

                this.setSaveButtonTooltip();
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private setSaveButtonTooltip(): void {
        const tooltip = this.getSaveButtonTooltip();

        this.saveButtonTooltip.next(tooltip);
    }

    onEditStudyTeam(index: number): void {
        this.setIsSiteInEditMode(index, true);
    }

    isSiteInEditMode(index: number): boolean {
        return this.studySitesViewMode.get(index)?.isEditMode;
    }

    isSiteTeamCollapsed(index: number): boolean {
        return this.studySitesViewMode.get(index)?.isCollapsed;
    }

    toggleSiteTeamCollapsed(index: number): void {
        this.studySitesViewMode.get(index).isCollapsed = !this.isSiteTeamCollapsed(index);
    }

    getStudySiteTeamMembers(id: string): GetTeamMember[] {
        const studySite = this.study?.sites?.find((site) => site.id === id);

        return studySite?.teamMembers || [];
    }

    onSave(next: boolean): void {
        this.submit.emit({
            study: {
                id: this.study.id,
                ...this.changedStudy,
                sites: this.changedSites
            },
            uniqueProtocolIdChanged: this.isUPIDChanged,
            next
        });
        this.studyForm.markAsPristine();
    }

    onReset(): void {
        this.Modals.show(ConfirmationModal, {
            initialState: {
                showModalCloseButton: true,
                confirmationTitle: 'Are you sure?',
                confirmationMessage: 'You will lose all the changes that you made.',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Confirm',
                handleConfirm: () => {
                    this.studyForm.reset();
                    this.initializeForm(this.study);

                    if (!this.study?.sites?.length) {
                        this.setInitialStudySitesViewMode(0);
                    }
                    else {
                        this.study.sites.forEach((site, i) => {
                            this.setInitialStudySitesViewMode(i);
                        });
                    }

                    this.Modals.hide(1);
                }
            }
        });
    }

    onTeamMembersAdded($event: FilteredSelectEvent<User>, siteIndex: number): void {
        $event.added.forEach((user) => {
            const siteTeamMembersFormArray = this.getSiteTeamMembersFormArray(siteIndex);

            this.addTeamMemberToFormArray(user, siteTeamMembersFormArray);

            this.sortSiteTeamMembersFormArray(siteTeamMembersFormArray);

            siteTeamMembersFormArray.markAsDirty();
            siteTeamMembersFormArray.updateValueAndValidity();
        });

    }

    onTeamMemberRemoved(userId: string, siteIndex: number): void {
        const siteTeamMembersFormArray = this.getSiteTeamMembersFormArray(siteIndex);

        const teamMemberIndexToRemove = siteTeamMembersFormArray.controls
            .findIndex(({ value }) => value.userId === userId);

        siteTeamMembersFormArray.removeAt(teamMemberIndexToRemove);
        siteTeamMembersFormArray.markAsDirty();
        siteTeamMembersFormArray.updateValueAndValidity();
    }

    onTeamMemberStudyRoleChanged(
        $event: {
            teamMemberIndex: number;
            studyRoleId: StudyRoleIdName['_id'];
        },
        siteIndex: number
    ): void {
        const siteTeamMembersFormArray = this.getSiteTeamMembersFormArray(siteIndex);

        const teamMemberFormGroup = this.getTeamMemberFormGroup(siteTeamMembersFormArray, $event.teamMemberIndex);

        teamMemberFormGroup.patchValue({
            studyRoleId: $event.studyRoleId,
            studyRole: {
                name: this.studyRolesService.getStudyRoleNameById($event.studyRoleId)
            }
        }, { emitEvent: false });
        teamMemberFormGroup.markAsDirty();
        teamMemberFormGroup.updateValueAndValidity();
    }


    revertSiteMembers(siteIndex: number): void {
        const initialSite = this.study?.sites?.[siteIndex];
        const siteTeamMembersFormArray = this.getSiteTeamMembersFormArray(siteIndex);

        if (!initialSite?.teamMembers.length && !siteTeamMembersFormArray.length) {
            this.setIsSiteInEditMode(siteIndex, false);
            return;
        }

        this.Modals.show(ConfirmationModal, {
            initialState: {
                showModalCloseButton: true,
                confirmationTitle: 'Are you sure?',
                confirmationMessage: 'You will lose all unsaved changes in Study Site team.',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Confirm',
                handleConfirm: () => {
                    siteTeamMembersFormArray.clear();
                    initialSite?.teamMembers?.forEach((teamMember) => {
                        siteTeamMembersFormArray.push(this.createTeamMemberFormGroup(teamMember));
                    });

                    this.setIsSiteInEditMode(siteIndex, false);
                    this.Modals.hide(1);
                }
            }
        });
    }

    addSite(teamMembers?: GetTeamMember[]): void {
        const siteFormGroup = this.createSiteForm();

        teamMembers?.forEach(() => {
            const teamMembersFormArray = siteFormGroup.get('teamMembers') as FormArray;
            teamMembersFormArray.push(this.createTeamMemberFormGroup());
        });

        this.ctrlsSites.push(siteFormGroup);

        this.studyForm.markAsDirty();
        const newSiteIndex = this.ctrlsSites.value.length - 1;
        this.setInitialStudySitesViewMode(newSiteIndex);
    }

    removeSite(index: number): void {
        const siteControl = this.ctrlsSites.at(index);

        if (siteControl.touched && this.canRemoveSite(index)) {
            this.Modals.show(ConfirmationModal, {
                initialState: {
                    showModalCloseButton: true,
                    confirmationTitle: 'Are you sure?',
                    confirmationMessage: 'You’re about to remove this Site from Study.',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Confirm',
                    handleConfirm: () => {
                        this.ctrlsSites.removeAt(index);

                        this.Modals.hide(1);
                    }
                }
            });
            return;
        }

        if (this.canRemoveSite(index)) {
            this.ctrlsSites.removeAt(index);
        }
    }

    canRemoveSite(index: number): boolean {
        return !(this.ctrlsSites.at(index) as FormGroup).getRawValue().id;
    }

    get ctrls(): { [key: string]: AbstractControl } {
        return this.studyForm.controls;
    }

    ctrlHasError(ctrl: AbstractControl): boolean {
        return ctrl.invalid && (ctrl.dirty || ctrl.touched);
    }

    validateUniqueProtocolIdUnique(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const takenUPIDs = this.uniqueProtocolIds && this.study
                ? this.uniqueProtocolIds.filter((upid) => upid !== this.study.uniqueProtocolId)
                : this.uniqueProtocolIds;

            if (this.uniqueProtocolIds && takenUPIDs.includes(control.value?.trim())) {
                return { isDuplicateUPID: true };
            }
            return null;
        };
    }

    formControlErrorMessage$(formControl: AbstractControl): Observable<{ message: string }> {
        return of(formControl.errors).pipe(
            map(() => this.getFormControlErrorMessage(formControl))
        );
    }

    private getFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message: string;

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = MESSAGES.mandatoryFieldMessage;
                break;
            case !!formControl.errors?.pattern:
            case !!formControl.errors?.maxlength:
                message = MESSAGES.validNameMessage;
                break;
            case formControl.errors?.isDuplicateUPID:
                message = this.validationDuplicateUPIDErrMessage;
                break;
            default:
                message = '';
        }

        return { message };
    }

    private getSaveButtonTooltip(): string | null {
        let tooltip: string;

        switch (true) {
            case !this.isSaveDisabled.getValue():
                tooltip = null;
                break;
            case this.isLoading.getValue():
                tooltip = 'Cannot save while page is loading.';
                break;
            case this.studyForm.pristine:
            case !this.isStudyChanged && !this.isAnySiteChanged:
                tooltip = 'No changes to save.';
                break;
            case this.hasInvalidTeamMemberStudyRole:
                tooltip = 'Each team member needs to have the role defined prior saving the Study profile.';
                break;
            case this.studyForm.invalid:
                tooltip = 'Form contains missing or invalid data.';
                break;
            default:
                tooltip = null;
        }

        return tooltip;
    }

    private get hasInvalidTeamMemberStudyRole(): boolean {
        if (this.ctrlsSites.valid) {
            return false;
        }
        const isAnySiteInvalid = this.ctrlsSites.controls.some(this.doesSiteTeamContainInvalidStudyRole);

        return isAnySiteInvalid;
    }

    private doesSiteTeamContainInvalidStudyRole(siteFormGroup: FormGroup): boolean {
        if (siteFormGroup.valid) {
            return false;
        }
        const teamMembersFormArray = siteFormGroup.get('teamMembers') as FormArray;

        if (teamMembersFormArray.valid) {
            return false;
        }

        return teamMembersFormArray.controls
            .some((teamMemberFormGroup) => teamMemberFormGroup.get('studyRoleId').invalid);
    }

    private setStudySiteTeamFeatureFlag$(): Observable<boolean> {
        return this.FeatureFlags
            .getFlag(FEATURE_FLAGS.EBINDERS_STUDY_SITE_TEAM, false)
            .pipe(
                filter((value) => value !== undefined),
                tap((value) => {
                    this.isStudySiteTeamEnabled = value;
                }),
                takeUntil(this.destroy$)
            );
    }

    private createSiteForm(): FormGroup {
        const group = this.fb.group({
            id: [{ value: '', disabled: true }],
            siteName: ['', [
                Validators.pattern(REGEX.names),
                Validators.maxLength(250)
            ]],
            pi: ['', [
                Validators.pattern(REGEX.names),
                Validators.maxLength(250),
                ...(this.isStudySiteTeamEnabled ? [Validators.required, notBlank] : [])
            ]],
            coordinator: ['', [
                Validators.pattern(REGEX.names),
                Validators.maxLength(250)
            ]],
            customSiteId: ['', [
                Validators.pattern(REGEX.names),
                Validators.maxLength(250),
                ...(this.isStudySiteTeamEnabled ? [Validators.required, notBlank] : [])
            ]],
            siteCountry: ['', [
                Validators.pattern(REGEX.names),
                Validators.maxLength(250)
            ]],
            teamMembers: this.fb.array([]),
            teamSipIntegrationLink: this.fb.group({
                sipSiteId: [{ value: '', disabled: true }],
                sipFacilityId: [{ value: '', disabled: true }]
            })
        });
        group.markAsDirty();
        return group;
    }

    private initializeForm(study: StudyWithTeamSipIntegrationLink): void {
        this.ctrlsSites.clear();
        if (study && Object.keys(study).length) {
            study.sites.forEach((site) => this.addSite(site.teamMembers));
            this.studyForm.patchValue(study);
            this.studyForm.markAsPristine();
        }
        if (!this.studyForm.value.sites.length) {
            this.addSite();
        }
    }

    private get ctrlsSites(): FormArray {
        return this.ctrls.sites as FormArray;
    }

    getSiteTeamMembersFormArray(index: number): FormArray {
        return this.ctrlsSites.at(index)?.get('teamMembers') as FormArray;
    }

    doesSiteHaveTeamMembers(index: number): boolean {
        return this.getSiteTeamMembersFormArray(index)?.controls.length > 0;
    }

    private getTeamMemberFormGroup(teamMembersFormArray: FormArray, teamMemberIndex: number): FormGroup {
        return teamMembersFormArray.at(teamMemberIndex) as FormGroup;
    }

    private addTeamMemberToFormArray(user: User, siteTeamMembersFormArray: FormArray): void {
        const teamMemberFormGroup = this.createTeamMemberFormGroup({ user });

        siteTeamMembersFormArray.push(teamMemberFormGroup);
    }

    private createTeamMemberFormGroup(teamMember?: GetTeamMember): FormGroup {
        return this.fb.group({
            userId: this.fb.control(
                teamMember?.user.id,
                [Validators.required]
            ),
            studyRoleId: this.fb.control(
                teamMember?.studyRole?._id,
                [Validators.required]
            ),
            user: this.fb.group({
                name: this.fb.control({ value: teamMember?.user.name, disabled: true }),
                email: this.fb.control({ value: teamMember?.user.email, disabled: true })
            }),
            studyRole: this.fb.group({
                name: this.fb.control({ value: teamMember?.studyRole?.name, disabled: true })
            })
        });
    }

    private get changedStudy(): Study | Record<string, unknown> | Record<string, never> {
        return this.isStudyChanged ? this.getTrimmedStudyValues() : {};
    }

    private getTrimmedStudyValues(): Study | Record<string, unknown> {
        const study = _.pick(this.studyForm.getRawValue(), this.studyFields);
        const trimmedStudy = Object.keys(study).reduce((acc, current) => {
            acc[current] = study[current]?.trim();
            return acc;
        }, {});
        return trimmedStudy;
    }

    private getStudyFields(): void {
        this.studyFields = Object.keys(this.ctrls)
            .filter((k) => this.ctrls[k] !== this.ctrlsSites && this.ctrls[k] !== this.ctrls.teamSipIntegrationLink);
    }

    private get changedSites(): Site[] {
        return this.ctrlsSites.controls
            .filter((c: FormGroup) => this.isSiteChanged(c))
            .map((c) => {
                const siteValue = (c as FormGroup).getRawValue();
                delete siteValue.teamSipIntegrationLink;
                return siteValue;
            });
    }

    private isSiteChanged(site: FormGroup): boolean {
        const changedSite = site.value;
        const { id } = site.getRawValue();
        if (!id) {
            return true;
        }
        const originalSite = this.study.sites
            .map((s) => ({
                ...s,
                teamMembers: s.teamMembers.map((tm) => _.omit(tm, ['studyRole', 'user']))
            }))
            .find((s) => s.id === id);

        return Object.keys(changedSite).some((k) => {
            if (Array.isArray(originalSite[k])) {
                const isArrayEqual = _.isEmpty(_.xorWith(originalSite[k], changedSite[k], _.isEqual));
                return !isArrayEqual;
            }
            return originalSite[k] !== changedSite[k];
        });
    }

    private get isAnySiteChanged(): boolean {
        return this.ctrlsSites.controls.some((siteFormGroup: FormGroup) => this.isSiteChanged(siteFormGroup));
    }

    private get isStudyChanged(): boolean {
        return this.studyFields.some((k) => this.ctrls[k]?.value !== this.study?.[k]);
    }

    private get isUPIDChanged(): boolean {
        if (!this.study.uniqueProtocolId) {
            return true;
        }
        return this.ctrls?.uniqueProtocolId.value?.trim() !== this.study.uniqueProtocolId?.trim();
    }

    private setInitialStudySitesViewMode(index: number): void {
        this.studySitesViewMode.set(index, new StudySiteViewMode());
    }

    private setIsSiteInEditMode(index: number, value: boolean): void {
        const site = this.studySitesViewMode.get(index);

        site.isEditMode = value;
    }

    openImportTeam(siteIndex: number): void {
        const modalRef = this.Modals.show(StudyTeamImportComponent, {
            class: 'modal-lg',
            initialState: {}
        });

        modalRef.content.onTeamImport.subscribe((importedStudyTeamMembers: StudyTeamPreview[]) => {
            const siteTeamMembersFormArray = this.getSiteTeamMembersFormArray(siteIndex);

            importedStudyTeamMembers.forEach((importedStudyTeamMember) => {
                const user = {
                    id: importedStudyTeamMember.userId,
                    email: importedStudyTeamMember.email,
                    name: importedStudyTeamMember.userName
                } as User;

                const studyRole = {
                    _id: importedStudyTeamMember.roleId,
                    name: importedStudyTeamMember.role
                };

                const teamMemberFormGroup = this.createTeamMemberFormGroup({ user, studyRole });

                siteTeamMembersFormArray.push(teamMemberFormGroup);
            });
            siteTeamMembersFormArray.markAsDirty();
            siteTeamMembersFormArray.updateValueAndValidity();
        });
    }

    private sortSiteTeamMembersFormArray(siteTeamMembersFormArray: FormArray) {
        const sortedTeamMembers = siteTeamMembersFormArray.getRawValue()
            .sort((a, b) => {
                return SORT.naturalSort({ value: a.user.name }, { value: b.user.name });
            });


        siteTeamMembersFormArray.patchValue(sortedTeamMembers);
    }
}
