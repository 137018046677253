import { SessionsService } from '@app/shared/sessions/sessions.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { IPromise, IRequestConfig } from 'angular';
import * as _ from 'lodash';
import { PasswordExpiredComponent } from '@app/components/password-policy/components/password-expired.modal/password-expired.component';
import { Auth0Service } from '../shared/sessions/auth0.service';

const authInterceptor = ($q: ng.IQService, $injector: ng.auto.IInjectorService, auth0: Auth0Service): ng.IHttpInterceptor => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseError = (response: any): ng.IPromise<any> => {
        if (response.status === 401) {
            const Sessions = $injector.get('SessionsService') as SessionsService;
            Sessions.destroy();
        }

        if (response.headers && _.isFunction(response.headers) && response.headers('x-fhc-password-expired')) {
            const modalHelper = $injector.get('ModalsService') as ModalsService;
            modalHelper.show(PasswordExpiredComponent, {
                initialState: {},
                class: 'modal-md'
            });
        }

        return $q.reject(response);
    };

    const request = (config: IRequestConfig): IRequestConfig | IPromise<IRequestConfig> => {
        return auth0.getAccessTokenSilently().toPromise().then((token: string) => {
            config.headers = {
                ...config.headers,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: `Bearer ${token}`
            };
            return config;
        });
    };

    return {
        responseError,
        request
    };
};

authInterceptor.$inject = ['$q', '$injector', 'Auth0Service'];

const authConfig = ($httpProvider: ng.IHttpProvider): void => {
    $httpProvider.interceptors.push(authInterceptor);
};

authConfig.$inject = ['$httpProvider'];

export default authConfig;
