const JobTitleRequiredWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
        <job-title-required
        (on-update-profile)="vm.resolve.onUpdateProfile($event)"
        (dismiss)="vm.modalInstance.close()">
        </job-title-required>
        `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default JobTitleRequiredWrapperComponent;
