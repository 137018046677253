import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { StudyRoleIdName, StudyRolesMap, StudyTeamPreview } from './study-roles.types';
import { StudyRolesApiService } from './study-roles-api/study-roles-api.service';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { MESSAGES } from '../../core/constants';

@Injectable()
export class StudyRolesService {
    constructor(
        private studyRolesApiService: StudyRolesApiService,
        private notificationsService: NotificationsService
    ) {}

    private studyRolesList = new BehaviorSubject<StudyRoleIdName[]>([]);
    studyRolesList$ = this.studyRolesList.asObservable();

    private studyRolesMap = new BehaviorSubject<StudyRolesMap>(new Map());
    studyRolesMap$ = this.studyRolesMap.asObservable();

    isLoadingStudyRoles$ = this.studyRolesApiService.isLoading$;

    setStudyRolesList$: Observable<StudyRoleIdName[]> = this.studyRolesApiService.getStudyRoles$().pipe(
        tap((studyRolesList) => {
            const studyRoleTuples = studyRolesList
                .map((studyRole) => [studyRole._id, studyRole.name]) as Iterable<[string, string]>;

            this.studyRolesList.next(studyRolesList);
            this.studyRolesMap.next(new Map(studyRoleTuples));
        }),
        catchError(() => {
            this.notificationsService.error(MESSAGES.failedToLoad('study roles list'));
            return of(null);
        }),
        shareReplay(1)
    );

    getStudyRoleNameById(studyRoleId: StudyRoleIdName['_id']): string {
        return this.studyRolesMap.getValue().get(studyRoleId);
    }

    getFilePreview(teamId: string, file: File): Observable<StudyTeamPreview[]> {
        return this.studyRolesApiService.getFilePreview$(teamId, file);
    }
}
