
import {
    Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef
} from '@angular/core';
import { Document, DocumentSubTypes } from '@app/shared/models';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEX, MESSAGES } from '@app/core/constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as CustomValidators from '@app/core/form-validators';
import { OnSubmitDocumentReplace } from './document-replace.component.types';

import template from './document-replace.component.html';
import styles from './document-replace.component.scss';

@Component({
    selector: 'document-replace',
    template,
    styles: [String(styles)]
})

export class DocumentReplaceComponent implements OnInit, AfterViewInit {
    @ViewChild('reasonInput', { static: false }) reasonInput: ElementRef;
    @Input() doc: Document;
    @Output() onSubmit = new EventEmitter<OnSubmitDocumentReplace>();

    documentReplaceForm: FormGroup;

    readonly lengthError = MESSAGES.invalidLengthMessage;
    readonly characterError = MESSAGES.invalidCharactersMessage;
    readonly nameRequiredError = 'Name is required';
    readonly namePattern = REGEX.noSpecialCharsNoLengthLimit;
    readonly renameShortcutsTooltip ='When checked shortcuts to this document will be renamed with the Document Name + &quot; Shortcut&quot;. Leave unchecked to keep existing shortcut names.';

    isProcessing: boolean;
    documentHasLockedShortcuts: boolean;

    canRenameShortcuts: boolean;

    file: File;
    title: string;
    clearExpiration: boolean;

    constructor(
        private fb: FormBuilder,
        private modalRef: BsModalRef
    ) {
        this.isProcessing = false;
        this.documentHasLockedShortcuts = false;

        this.clearExpiration = false;
        this.file = undefined;
    }

    ngAfterViewInit() {
        setTimeout(() => this.reasonInput.nativeElement.focus(), 0);
    }

    ngOnInit(): void {
        this.documentHasLockedShortcuts = this.doc.shortcuts
            && this.doc.shortcuts.some((shortcut) => shortcut.isLocked);
        this.title = this.doc.title;
        this.clearExpiration = this.doc.permissions.updateDocExpDate;
        this.canRenameShortcuts = this._canRenameShortcuts();
        this.createForm();
    }

    createForm() {
        this.documentReplaceForm = this.fb.group({
            reason: ['', Validators.required],
            title: [
                { value: this.title, disabled: !this.canRename() },
                [Validators.required, Validators.maxLength(250), Validators.pattern(this.namePattern), CustomValidators.notBlank]
            ],
            clearExpiration: [{ value: this.clearExpiration, disabled: !this.canClearExpirationDate() }],
            renameShortcuts: [{ value: '', disabled: !this._canRenameShortcuts() }]
        });
    }

    _canRenameShortcuts(): boolean {
        return this.doc.subType && this.doc.subType !== DocumentSubTypes.shortcut && this.doc.subType !== DocumentSubTypes.log;
    }

    close(): void {
        this.modalRef.hide();
    }

    canRename(): boolean {
        return this.doc.permissions.renameDocument;
    }

    canClearExpirationDate(): boolean {
        return this.doc.permissions.updateDocExpDate;
    }

    handleSubmit(): void {
        if (this.documentReplaceForm.invalid) {
            return;
        }
        this.isProcessing = true;

        this.onSubmit.emit({
            doc: this.doc,
            file: this.file,
            reason: this.documentReplaceForm.controls.reason.value,
            clearExpiration: this.documentReplaceForm.controls.clearExpiration.value,
            title: this.documentReplaceForm.controls.title.value,
            renameShortcuts: this.documentReplaceForm.controls.renameShortcuts.value,
            onSuccess: () => this.modalRef.hide(),
            onError: () => {
                this.isProcessing = false;
                this.modalRef.hide();
            }
        });
    }

    filesAdded($files): void {
        if ($files && $files[0]) {
            [this.file] = $files;
        }
    }
}
