import * as _ from 'lodash';
import * as angular from 'angular';

// Based on: https://github.com/angular/angular.js/blob/master/src/ng/directive/ngEventDirs.js#L3
const eventDirectiveFactory = (eventName, options = {}) => {
    const {
        eventHandlerName = `on${_.upperFirst(eventName)}`,
        attachEventsToWindow = false,
        shouldDebounce = false,
        nativeEvent = false,
        debounceOptions = { wait: 250, trailing: true, maxWait: 250 },
        // Optional filter function for limiting under what conditions callback is invoked
        // Will be passed $element and $event
        eventFilter = () => true
    } = options;

    const directive = ($parse, $window) => ({
        restrict: 'A',
        compile: ($element, attr) => {
            const fn = $parse(attr[eventHandlerName]);
            return function eventHandler(scope, element) {
                // Setup event callback, with optional debouncing
                let eventCallback = ($event) => {
                    const callback = () => {
                        if (!eventFilter({ $event, element: element[0] })) {
                            return;
                        }
                        scope.$apply(() => {
                            fn(scope, { $event });
                        });
                    };

                    if (shouldDebounce && $window.requestAnimationFrame) {
                        $window.requestAnimationFrame(callback);
                    }
                    else {
                        callback();
                    }
                };
                if (shouldDebounce) {
                    eventCallback = _.debounce(eventCallback, debounceOptions.wait, debounceOptions);
                }

                // Setup event listeners on the jquery element or native dom element depending on options
                const target = attachEventsToWindow ? angular.element($window) : element;
                nativeEvent ? target[0].addEventListener(eventName, eventCallback) : target.on(eventName, eventCallback);
                scope.$on('$destroy', () => {
                    nativeEvent ? target[0].removeEventListener(eventName, eventCallback) : target.off(eventName, eventCallback);
                });
            };
        }
    });

    directive.$inject = ['$parse', '$window'];

    return directive;
};

export default eventDirectiveFactory;
