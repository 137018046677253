import './manage-team-settings.scss';
import template from './manage-team-settings.html';
import controller from './manage-team-settings.controller';

const manageTeamSettingsDirective = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {}
};

export default manageTeamSettingsDirective;
