import { StateService } from '@uirouter/angularjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CurrentSessionService } from '@app/core/current-session.service';
import { ModalHelperService } from '@app/shared/modal-helper/modal-helper.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { GetRedirectUrlResponse } from './sessions.service.types';
import { Auth0Service } from './auth0.service';
import { User, Team } from '../models';
import { ModalsService } from '../modal-helper/modals.service';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
    providedIn: 'root'
})
export class SessionsService {
    public environment: {
        name: string;
        emailConnectorPath: string;
    } = this.getEnvironment();

    private readonly url = {
        helpdesk: '/api/sessions/helpdesk'
    };

    constructor(
        private $http: HttpClient,
        private $state: StateService,
        private modalHelper: ModalHelperService,
        @Inject('Window') private $window: Window,
        private AppConfig: AppConfigService,
        private Notifications: NotificationsService,
        private CurrentSession: CurrentSessionService,
        private Auth0: Auth0Service,
        private modalServices: ModalsService
    ) { }

    getHelpDeskUrl(returnTo: string): Observable<GetRedirectUrlResponse> {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return this.$http.get<GetRedirectUrlResponse>(this.url.helpdesk, { params: { return_to: returnTo } });
    }

    destroy({ sessionExpired = false } = {}): void {
        const currentUser = this.CurrentSession.getCurrentUser();
        if (!currentUser) {
            this.Auth0.logout({ returnTo: this.$window.location.origin, federated: true });
            return;
        }

        this.clearAll();
        this.Auth0.logout({
            returnTo: this.getLogoutRedirectUrl(currentUser, sessionExpired),
            federated: true
        });
    }

    getLogoutRedirectUrl = (
        user: User,
        sessionExpired: boolean
    ): string => {
        let returnTo = this.AppConfig.config.authHost;

        returnTo = this.$state.href('sign-in', {
            ...sessionExpired && {
                sessionExpired,
                userId: user.id,
                returnTo: this.$window.location.href
            }
        }, { absolute: true });

        return returnTo;
    }

    giveNotification(): void {
        this.Notifications.error('Unable to navigate to this page. Redirected to Binders.');
    }

    heartbeat(): Promise<string> {
        return (this.Auth0.getAccessTokenSilently() as Observable<string>)
            .pipe(
                catchError((error) => {
                    return throwError(error);
                })
            ).toPromise();
    }

    openInactivityWarning(): void {
        this.Auth0.isAuthenticated$.pipe(take(1))
            .subscribe((isLoggedIn) => {
                if (!isLoggedIn) {
                    return;
                }

                this.modalHelper.open({
                    animation: false,
                    size: 'md',
                    backdrop: true,
                    component: 'inactivity-warning',
                    resolve: {
                        team: (): Team => this.CurrentSession.getCurrentTeam()
                    }
                });
            });
    }

    clearAll(): void {
        console.log('local cleanup');
        this.$window.localStorage.clear();
        this.$window.sessionStorage.clear();

        this.CurrentSession.setCurrentUser(undefined);
        this.CurrentSession.setCurrentTeam(undefined);
        this.modalServices.closeAllModals();
    }

    // Development: @v2.researchbinders.com
    // UAT: @uat.v2.researchbinders.com
    // Build: @build.v2.researchbinders.com
    // Development: @dev.v2.researchbinders.com
    private getEnvironment(): { name: string; emailConnectorPath: string } {
        switch (this.$window.location.hostname) {
            case 'ebinder-kevin.ngrok.io':
                return { name: 'dev', emailConnectorPath: '@dev1.ebinders.org' };
            case 'ebinder2.ngrok.io':
                return { name: 'dev', emailConnectorPath: '@dev2.ebinders.org' };
            case 'ebinder3.ngrok.io':
                return { name: 'dev', emailConnectorPath: '@dev3.ebinders.org' };
            case 'ebinder4.ngrok.io':
                return { name: 'dev', emailConnectorPath: '@dev4.ebinders.org' };
            case 'buildv2.researchbinders.com':
                return { name: 'build', emailConnectorPath: '@build.ebinders.org' };
            case 'uatv2.researchbinders.com':
                return { name: 'UAT', emailConnectorPath: '@uat.ebinders.org' };
            case 'de.uatv2.researchbinders.com':
                return { name: 'UAT-DE', emailConnectorPath: '@de.uat.ebinders.org' };
            case 'us.uatv2.researchbinders.com':
                return { name: 'UAT-US', emailConnectorPath: '@us.uat.ebinders.org' };
            case 'us9.uatv2.researchbinders.com':
                return { name: 'UAT-KP', emailConnectorPath: '@us9.uat.ebinders.org' };
            case 'au.uatv2.researchbinders.com':
                return { name: 'UAT-AU', emailConnectorPath: '@au.uat.ebinders.org' };
            case 'qav2.researchbinders.com':
                return { name: 'QA', emailConnectorPath: '@qa.ebinders.org' };
            case 'de.qav2.researchbinders.com':
                return { name: 'QA-DE', emailConnectorPath: '@de.qa.ebinders.org' };
            case 'us.qav2.researchbinders.com':
                return { name: 'QA-US', emailConnectorPath: '@us.qa.ebinders.org' };
            case 'us9.qav2.researchbinders.com':
                return { name: 'QA-KP', emailConnectorPath: '@us9.qa.ebinders.org' };
            case 'de.fatev2.researchbinders.com':
                return { name: 'FATE-DE', emailConnectorPath: '@de.fate.ebinders.org' };
            case 'us.fatev2.researchbinders.com':
                return { name: 'FATE-US', emailConnectorPath: '@us.fate.ebinders.org' };
            case 'us.v2.researchbinders.com':
                return { name: 'PROD-US', emailConnectorPath: '@us.v2.ebinders.org' };
            case 'de.v2.researchbinders.com':
                return { name: 'PROD-DE', emailConnectorPath: '@de.v2.ebinders.org' };
            case 'us9.v2.researchbinders.com':
                return { name: 'PROD-KP', emailConnectorPath: '@us9.v2.ebinders.org' };
            case 'au.v2.researchbinders.com':
                return { name: 'PROD-AU', emailConnectorPath: '@au.v2.ebinders.org' };
            case 'prereleasev2.researchbinders.com':
                return { name: 'prerelease', emailConnectorPath: '@prerelease.ebinders.org' };
            case 'v2.researchbinders.com':
            case 'researchbinders.com':
                return { name: 'production', emailConnectorPath: '@v2.ebinders.org' };
            case 'v2performancetest.researchbinders.com':
                return { name: 'api-uat', emailConnectorPath: '@apiuat.ebinders.org' };
            case 'v2buildapi.researchbinders.com':
                return { name: 'api-build', emailConnectorPath: '@apibuild.ebinders.org' };
            case 'localhost':
            default:
                return { name: 'dev', emailConnectorPath: '@dev1.ebinders.org' };
        }
    }

}
