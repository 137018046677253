
export const LogEditMetadataWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
    <log-edit-metadata
        [document]="vm.resolve.document"
        [metadata-type]="vm.resolve.metadataType"
        (dismiss)="vm.modalInstance.close()"
        (save)="vm.resolve.save($event)"
    ></log-edit-metadata>
    `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};
