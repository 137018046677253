import { Component, Input } from '@angular/core';
import template from './action-bar-item.component.html';
import styles from './action-bar-item.component.scss';
import { ThemeColors, BarOptions } from './action-bar-item.types';

@Component({
    selector: 'action-bar-item',
    template,
    styles: [String(styles)]
})
export class ActionBarItemComponent {
    @Input() barOptions: BarOptions;
    @Input() theme: keyof typeof ThemeColors;
}
