import * as angular from 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';
import marketingNotificationsComponent from './containers/marketing-notifications/marketing-notifications.component';
import { MarketingNotificationsService } from './marketing-notifications.service';
import MarketingNotificationsModalService from './marketing-notifications-modal.service';

const dependencies = [];

const marketingNotificationsModule = angular
    .module('app.marketingNotifications', dependencies)
    .component('marketingNotifications', marketingNotificationsComponent)
    .factory('MarketingNotifications', downgradeInjectable(MarketingNotificationsService))
    .service('MarketingNotificationsModal', MarketingNotificationsModalService);

export default marketingNotificationsModule;
