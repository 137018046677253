import * as _ from 'lodash';

import { ConfirmDestroySubmitEvent } from '@app/widgets/confirm-destroy/confirm-destroy.component.types';
import { ApiError, Label } from '@app/shared/models';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { LabelUpdateComponent } from '../label-update/label-update.component';
import { LabelCreateComponent } from '../label-create/label-create.component';
import { LabelCreateEvent } from '../label-create/label-create.component.types';

class ManageLabelsController {
    constructor(Labels, modalHelper, SORT, $q, $timeout, ApiError, Notifications, private Modals: ModalsService) {
        this._Labels = Labels;
        this._modalHelper = modalHelper;
        this.SORT = SORT;
        this._$q = $q;
        this._$timeout = $timeout;
        this._ApiError = ApiError;
        this._Notifications = Notifications;
        this.loadingLabels = true;
        this.selectedLabel = null;
        this.numValuesShow = 3;
        this.isExampleVisible = false;
        this.labels = [];
    }

    $onInit(): void {
        this._Labels.getLabels(this.team.id).toPromise()
            .then((labels) => {
                this.labels = labels;
                this.loadingLabels = false;
            });
    }

    select(label) {
        if (!label) {
            return;
        }
        if (label.selected) {
            label.selected = false;
            this.selectedLabel = null;
        }
        else {
            this.labels.forEach((l) => {

                l.selected = false;
            });
            label.selected = true;
            this.selectedLabel = label;
        }
    }

    onLabelAdded(label:Label) {
        this._Notifications.success(`Created label ${label.name}.`);
        this.labels.push(label);
        label.success = true;
        this._$timeout(() => {
            label.success = false;
        }, 5000);
        return label;
    }

    openCreateLabelModal() {
        if (!this.canCreateLabel()) {
            return;
        }
        const createLabelModal = this.Modals.show(LabelCreateComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {}
        });

        createLabelModal.content.onCreateLabel.subscribe((event: LabelCreateEvent) => {
            const { label } = event;
            this._Labels.createLabel(this.team.id, label)
                .toPromise()
                .then((createdLabel: Label) => {
                    this.onLabelAdded(createdLabel);
                    createLabelModal.hide();
                })
                .catch((error) => {
                    this.handleErrorNotification(error);
                    createLabelModal.hide();
                });
        });
    }

    openValueAssignedWarnModal(params): void {
        const bodyText = 'This action <span class="strong text-uppercase">cannot</span> be undone. This will permanently update '
            + `values for the label <span class="strong">${this.selectedLabel.name}</span> which will impact current assignments.`;
        const warningText = 'Some removed values have already been assigned to one or more Binders or Folders. '
            + 'The Binders and Folders will remain but the label assignment will be removed.';

        this._modalHelper.open({
            animation: false,
            component: 'confirm-destroy-wrapper',
            size: 'md',
            resolve: {
                onSubmit: () => (event: ConfirmDestroySubmitEvent): void => {
                    this.persistUpdate(params)
                        .then(event.onSuccess)
                        .catch(event.onError);
                },
                onCancel: () => this.openEditLabelModal.bind(this),
                warningText: () => warningText,
                bodyText: () => bodyText
            }
        });
    }

    onLabelUpdated(updatedLabel = {}) {
        this.selectedLabel.selected = false;
        this.selectedLabel = null;
        const label = this.labels.find((l) => l.id === updatedLabel.id);
        if (label) {
            Object.assign(label, updatedLabel);
        }
        return updatedLabel;
    }

    private handleLabelValuesUpdate(isAssigned, params): void {
        if (!isAssigned) {
            return this.persistUpdate(params);
        }

        return this.openValueAssignedWarnModal(params);
    }

    checkValueAssignedAndUpdate(params) {
        const checkValueIds = params.removedValues.map((v) => v.id);

        if (checkValueIds.length) {
            return this._Labels.checkIsAssigned({
                teamId: this.team.id, labelId: this.selectedLabel.id, values: checkValueIds
            }).toPromise()
                .then(({ isAssigned }) => this.handleLabelValuesUpdate(isAssigned, params));
        }

        return this._$q.resolve()
            .then(() => this.handleLabelValuesUpdate(undefined, params));
    }

    private persistUpdate(params) {
        const teamId = this.team.id;
        const labelId = this.selectedLabel.id;
        const {
            addedValues = [],
            updatedValues = [],
            removedValues = []
        } = params;
        const results = [];
        const errors = [];
        return this._$q.resolve.call(this)
            .then(() => {
                const { name, description } = params;
                if (name || description || description === '') {
                    return this._Labels.updateLabelProps({ teamId, labelId, updates: params })
                        .toPromise()
                        .catch((error) => {
                            errors.push(error);
                            this._$q.resolve();
                        });
                }

                return this._$q.resolve();
            })
            .then((result) => {
                results.push(result);

                if (addedValues.length) {
                    return this._Labels.addLabelValues({ teamId, labelId, addedValues })
                        .toPromise()
                        .catch((error) => {
                            errors.push(error);
                            this._$q.resolve();
                        });
                }

                return this._$q.resolve();
            })
            .then((result) => {
                results.push(result);

                if (removedValues.length) {
                    return this._Labels.deleteLabelValues({ teamId, labelId, removedValues })
                        .toPromise()
                        .catch((error) => {
                            errors.push(error);
                            this._$q.resolve();
                        });
                }

                return this._$q.resolve();
            })
            .then((result) => {
                results.push(result);

                if (updatedValues.length) {
                    return this._Labels.updateLabelValues({ teamId, labelId, updatedValues })
                        .toPromise()
                        .catch((error) => {
                            errors.push(error);
                            this._$q.resolve();
                        });
                }

                return this._$q.resolve();
            })
            .then((result) => {
                results.push(result);
                if (errors.length > 1) {
                    const message = 'There was a problem making the label updates! Try again or contact customer support.';
                    this.handleErrorNotification(null, message);
                    return this._Labels.getSingleLabel({ teamId, labelId }).toPromise();
                }
                if (errors.length === 1) {
                    this.handleErrorNotification(errors[0]);
                    return this._Labels.getSingleLabel({ teamId, labelId }).toPromise();
                }

                this._Notifications.success('Updated label.');
                const filteredResults = results.filter(Boolean);
                if (!filteredResults.length) {
                    return;
                }

                return _.maxBy(filteredResults, 'updatedAt');
            })
            .then((updatedLabel) => this.onLabelUpdated(updatedLabel))
            .catch(this.handleErrorNotification.bind(this));
    }

    canCreateLabel() {
        return this.team.permissions.labelCreate;
    }

    canEditSelectedLabel() {
        const {
            labelEdit, labelValueManage, labelValueAdd, labelValueUpdate, labelValueDelete
        } = this.team.permissions;

        return this.selectedLabel
            && labelEdit && (labelValueManage || (labelValueAdd && labelValueUpdate && labelValueDelete));
    }

    canDeleteSelectedLabel() {
        return this.selectedLabel
            && !this.selectedLabel.isPredefined
            && this.team.permissions.labelDelete;
    }

    canActOnSelection() {
        return this.canEditSelectedLabel()
            || this.canDeleteSelectedLabel();
    }

    openEditLabelModal() {
        if (!this.canEditSelectedLabel()) {
            return;
        }

        const editModal = this.Modals.show(LabelUpdateComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                label: this.selectedLabel
            }
        });

        editModal.content.onUpdateLabel
            .subscribe((updates) => {
                this.checkValueAssignedAndUpdate(updates);
                updates.onSuccess();
            });
    }

    openDeleteLabelModal() {
        if (!this.canDeleteSelectedLabel()) {
            return;
        }

        this._Labels.checkIsAssigned({
            teamId: this.team.id,
            labelId: this.selectedLabel.id
        }).toPromise()
            .then(({ isAssigned }) => {
                const bodyText = 'This action <span class="strong text-uppercase">cannot</span> be undone. This will permanently delete '
                    + `the label <span class="strong">${this.selectedLabel.name}</span> and all of its assignments.`;
                const warningText = isAssigned ? 'The Label selected for deletion has already been assigned to one or more Binders or Folders. '
                    + 'The Binders and Folders will remain but will no longer have this Label assigned.' : '';
                this._modalHelper.open({
                    animation: false,
                    component: 'confirm-destroy-wrapper',
                    size: 'md',
                    resolve: {
                        onSubmit: () => (event: ConfirmDestroySubmitEvent): void => {
                            this._Labels
                                .deleteLabel({
                                    teamId: this.team.id,
                                    labelId: this.selectedLabel.id,
                                    reason: event.data.reason
                                })
                                .toPromise()
                                .then(() => {
                                    const startIndex = this.labels.findIndex((l) => l.id === this.selectedLabel.id);
                                    this.labels.splice(startIndex, 1);
                                    this._Notifications.success('Label successfully deleted.');
                                    event.onSuccess();
                                })
                                .catch((err) => {
                                    this.handleErrorNotification(err);
                                    event.onError(err);
                                });
                        },
                        warningText: () => warningText,
                        bodyText: () => bodyText,
                        requireReason: () => true,
                        confirmLabel: () => 'Type in the name of the label to continue',
                        confirmValue: () => this.selectedLabel.name
                    }
                }, this.onLabelUpdated.bind(this));
            });
    }

    toggleActions($event, label) {
        if (this.selectedLabel !== label) {
            this.select(label);
        }
    }

    private handleErrorNotification(error: ApiError, customMessage?: string): void {

        const message = (error && error.error && error.error.message) || customMessage;
        if (message) {
            return this._Notifications.error(message);
        }

        return this._Notifications.unexpectedError();
    }
}

ManageLabelsController.$inject = [
    'Labels',
    'modalHelper',
    'SORT',
    '$q',
    '$timeout',
    'ApiError',
    'Notifications',
    'ModalsService'
];

export default ManageLabelsController;