import controller from './manage-team-members.controller';
import template from './manage-team-members.html';

const manageTeamMembersComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {}
};

export default manageTeamMembersComponent;
