class MarketingNotificationsController {
    constructor($scope, MarketingNotifications, CurrentSession, REGEX) {
        this._$scope = $scope;
        this._CurrentSession = CurrentSession;
        this._MarketingNotifications = MarketingNotifications;
        this.emailPattern = REGEX.email;
    }

    $onInit() {
        this.user = this._CurrentSession.getCurrentUser();
        this.marketingNotification = this.$resolve.marketingNotification;
    }

    cancel() {
        this.marketingNotification.shown = true;
        this.marketingNotification.notificationData = null;

        this._MarketingNotifications.update(this.marketingNotification.id, this.marketingNotification).subscribe();
        this.$dismiss('cancel');
    }

    submitNotificationForm() {
        this.marketingNotification.shown = true;
        this.marketingNotification.notificationData = this.data;

        this.isProcessing = true;
        this._MarketingNotifications.update(this.marketingNotification.id, this.marketingNotification).subscribe();
        this.$close();
    }

}

MarketingNotificationsController.$inject = [
    '$scope',
    'MarketingNotifications',
    'CurrentSession',
    'REGEX'
];

export default MarketingNotificationsController;
