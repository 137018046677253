import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import * as _ from 'lodash';
import { from } from 'rxjs';
import { take } from 'rxjs/operators';

import { User, Role, UserRole } from '@app/shared/models';
import { SORT, CHECKBOX_STATES, MAX_DATE_ISO } from '@app/core/constants';
import { CurrentSessionService } from '@app/core/current-session.service';
import { TeamService } from '@app/shared/teams/team.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';

import { DecoratedUserRole, ManageAccessSubmitEvent, SubmitData } from './manage-access.component.types';

import template from './manage-access.component.html';
import styles from './manage-access.component.scss';

@Component({
    selector: 'manage-access',
    styles: [String(styles)],
    template
})
export class ManageAccessComponent implements OnInit {
    @Output() dismiss = new EventEmitter<void>();
    @Output() submit = new EventEmitter<ManageAccessSubmitEvent>();
    @Input() canAssignDates = false;
    @Input() canAssignRoles = false;
    @Input() items: UserRole[] = [];
    @Input() subject: User | Role;

    itemType: 'user' | 'role';
    SORT = SORT;
    MAX_DATE = new Date(MAX_DATE_ISO);
    now = new Date();
    bulkMode = false;
    timezone: string;
    canSubmit = false;
    canUndo = false;
    isProcessing = false;
    loadingSearchData = false;
    searchData: (User | Role)[] = [];
    decoratedItems: DecoratedUserRole[];
    originalItems: { [id: string]: UserRole };
    selectedCount = 0;
    notRemovedCount = 0;
    filter: string;
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    bulk: {
        isActive?: boolean;
        start?: Date;
        end?: Date;
        isInvalid: boolean;
    } = { isInvalid: false };

    constructor(
        private Teams: TeamService,
        private CurrentSession: CurrentSessionService
    ) { }

    ngOnInit(): void {
        this.itemType = this.subject.type === 'role' ? 'user' : 'role';
        this.decoratedItems = sortByLexicographically<DecoratedUserRole>(
            this.items.map(this.decorateItemWithSubjectData),
            'name'
        );
        this.notRemovedCount = this.decoratedItems.length;
        this.originalItems = this.decoratedItems.reduce((acc, item) => {
            acc[item.id] = { ...item };
            return acc;
        }, {});

        this.timezone = this.CurrentSession.getCurrentTeam().settings.timezone;
        this.searchRolesOrUsers('');
    }

    searchRolesOrUsers = (query: string): void => {
        this.loadingSearchData = true;
        this.Teams.searchRolesAndUsers(`${this.itemType} ${query}`)
            .pipe(take(1))
            .subscribe((results) => {
                this.searchData = results.filter((res) => !this.decoratedItems.find((i) => (i.user || i.role).id === res.id));
                this.loadingSearchData = false;
            });
    }

    toggleSelected(item: DecoratedUserRole): void {
        item.selected = !item.selected;
        item.selected ? this.selectedCount += 1 : this.selectedCount -= 1;
        if (this.bulkMode) {
            this.setIsActive(item, this.bulk.isActive);
            this.setBulkDateToItems();
        }
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    toggleSelectAll(): void {
        const allSelected = this.headerCheckboxState === CHECKBOX_STATES.SELECTED;

        this.decoratedItems.forEach((item) => {
            item.selected = item.deleted ? false : !allSelected;
            if (this.bulkMode && item.selected) {
                this.setIsActive(item, this.bulk.isActive);
            }
        });
        this.selectedCount = allSelected ? 0 : this.notRemovedCount;

        if (this.bulkMode) {
            this.setBulkDateToItems();
        }
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    toggleBulkAssign(): void {
        this.bulkMode = !this.bulkMode;
        this.bulk.isActive = false;

        if (this.selectedCount !== 0) {
            this.decoratedItems.forEach((item) => item.selected && this.setIsActive(item, this.bulk.isActive));
            this.setBulkDateToItems();
        }
    }

    private getHeaderCheckboxState(): CHECKBOX_STATES {
        if (this.decoratedItems.length) {
            if (this.selectedCount === this.notRemovedCount && this.selectedCount > 0) {
                return CHECKBOX_STATES.SELECTED;
            }

            if (this.selectedCount) {
                return CHECKBOX_STATES.PARTIALLY_SELECTED;
            }
        }

        return CHECKBOX_STATES.NOT_SELECTED;
    }

    checkForChanges(): void {
        const modalData = this.getModalData();
        const numberOfChanges = modalData.creates.length + modalData.updates.length + modalData.deletes.length;
        this.canSubmit = !!numberOfChanges;
    }

    preventSubmit(): void {
        this.canSubmit = false;
    }

    addItem = (event: FilteredSelectEvent<User | Role>): void => {
        const added = event.added[0];
        if (this.bulkMode) {
            return;
        }
        const item = this.decorateItemWithSubjectData({
            isActive: false,
            [this.itemType]: added,
            userId: this.itemType === 'user' ? added.id : this.subject.id,
            roleId: this.itemType === 'user' ? this.subject.id : added.id
        });

        this.decoratedItems = [item, ...this.decoratedItems];
        this.notRemovedCount += 1;
        this.checkForChanges();
        this.removeAddedItemFromCurrentSearchResults(item);
    }

    removeItem(item: DecoratedUserRole): void {
        item.deleted = true;
        if (item.selected) {
            item.selected = false;
            this.selectedCount -= 1;
        }
        this.notRemovedCount -= 1;
        this.checkForChanges();
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    undoRemoveItem(item: DecoratedUserRole): void {
        item.deleted = false;
        this.notRemovedCount += 1;
        this.checkForChanges();
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    setDate(item: DecoratedUserRole, key: 'start' | 'end', event: { value: Date }): void {
        item[key] = event.value ? event.value.toISOString() : null;
        this.checkForChanges();
        item.datesInvalid = this.getIsInvalid(item.start, item.end);
    }

    setDateBulk(key: 'start' | 'end', event: { value: Date }): void {
        this.bulk[key] = event.value;

        if (this.bulkIsValid()) {
            this.setBulkDateToItems();
        }
        this.bulk.isInvalid = this.getIsInvalid(this.bulk.start, this.bulk.end);
    }

    bulkIsValid(): boolean {
        const isOngoing = !this.bulk.start && (!this.bulk.end || this.bulk.end >= new Date());
        const validStart = !this.bulk.start || this.bulk.start >= new Date();
        const validEnd = !this.bulk.end || !this.bulk.start || (this.bulk.end >= this.bulk.start && this.bulk.end >= new Date());
        return !this.bulk.isActive || isOngoing || (validStart && validEnd);
    }

    setBulkDateToItems(): void {
        this.decoratedItems.forEach((item) => {
            if (item.selected) {
                this.setDate(item, 'start', { value: this.bulk.start ? this.bulk.start : null });
                this.setDate(item, 'end', { value: this.bulk.end ? this.bulk.end : null });
            }
        });
    }

    setIsActive(item: DecoratedUserRole, isActive: boolean): void {
        Object.assign(item, { start: '', end: '' });
        item.isActive = isActive;
        this.checkForChanges();
    }

    toggleIsActiveBulk(): void {
        this.bulk.isActive = !this.bulk.isActive;
        this.decoratedItems.forEach((item) => item.selected && this.setIsActive(item, this.bulk.isActive));

        if (this.bulkIsValid()) {
            this.setBulkDateToItems();
        }
    }

    private decorateItemWithSubjectData = (userRole: Partial<UserRole>): DecoratedUserRole => {
        let name: string;
        if (userRole.user && userRole.user.name) {
            name = userRole.user.name;
        }
        else {
            name = userRole.role && userRole.role.name;
        }
        return {
            ...userRole,
            selected: false,
            deleted: false,
            name: name || '',
            datesInvalid: false
        };
    }

    private getIsInvalid(start: Date | string | undefined, end: Date | string | undefined): boolean {
        return start && end && new Date(start) >= new Date(end);
    }

    private getUpdateParams(oldAccess: UserRole, newAccess: DecoratedUserRole): SubmitData['updates'][0] | undefined {
        let updated = false;
        const updates: SubmitData['updates'][0] = {
            roleId: newAccess.roleId,
            userId: newAccess.userId,
            isActive: newAccess.isActive
        };

        if (oldAccess.isActive !== newAccess.isActive) {
            updated = true;
        }

        if (new Date(oldAccess.start).getTime() !== new Date(newAccess.start).getTime()) {
            updates.start = newAccess.start || null;
            updated = true;
        }

        if (new Date(oldAccess.end).getTime() !== new Date(newAccess.end || this.MAX_DATE).getTime()) {
            updates.end = newAccess.end || this.MAX_DATE.toISOString();
            updated = true;
        }

        return updated ? updates : undefined;
    }

    private getCreateParams(item: DecoratedUserRole): SubmitData['creates'][0] {
        return {
            userId: item.userId,
            roleId: item.roleId,
            start: (!item.start || _.isNaN(new Date(item.start).getTime())) ? undefined : item.start,
            end: (!item.end || _.isNaN(new Date(item.end).getTime())) ? undefined : item.end,
            ...item.isActive && { isActive: item.isActive }
        };
    }

    private removeAddedItemFromCurrentSearchResults(added: DecoratedUserRole): void {
        const newSearchData = this.searchData.slice();
        const index = this.searchData.findIndex((searchItem) => searchItem.id === added[this.itemType].id);
        newSearchData.splice(index, 1);
        this.searchData = newSearchData;
    }

    getModalData(): SubmitData {
        const creates: SubmitData['creates'] = [];
        const updates: SubmitData['updates'] = [];
        const sames: SubmitData['sames'] = [];
        const deletes: SubmitData['deletes'] = [];
        this.decoratedItems.forEach((item) => {
            const originalItem = this.originalItems[item.id];
            if (!originalItem) {
                if (!item.deleted) {
                    creates.push(this.getCreateParams(item));
                }
                return;
            }

            if (item.deleted) {
                deletes.push({ userId: item.userId, roleId: item.roleId });
                return;
            }

            const update = this.getUpdateParams(originalItem, item);
            if (update) {
                updates.push(update);
                return;
            }

            sames.push(originalItem[this.itemType]);
        });

        return {
            creates,
            updates,
            deletes,
            sames
        };
    }

    handleSubmit(): void {
        if (!this.canSubmit) {
            return;
        }
        this.isProcessing = true;

        this.submit.emit({
            data: this.getModalData(),
            onSuccess: () => {
                this.dismiss.emit();
                this.isProcessing = false;
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    cancel(): void {
        if (this.isProcessing) {
            return;
        }
        this.dismiss.emit();
    }

    public updateFilter(text: string): void {
        this.filter = text;
    }
}
