const manageAccessWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
        <manage-access
            [can-assign-dates]="vm.resolve.canAssignDates"
            [can-assign-roles]="vm.resolve.canAssignRoles"
            [items]="vm.resolve.items"
            [subject]="vm.resolve.subject"
            [item-type]="vm.resolve.itemType"
            (submit)="vm.resolve.onSubmit($event)"
            (dismiss)="vm.modalInstance.close()">
        </manage-access>
    `,
    bindings: {
        resolve: '<',
        modalInstance: '<'
    }
};

export default manageAccessWrapperComponent;
