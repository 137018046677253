import { CHECKBOX_STATES } from '@app/core/constants';
import {
    LogEntry, Column, SigningReasons, User
} from '@app/shared/models';

export enum SignerOptions {
    SINGLE = 'single',
    VARIETY = 'variety'
}

export enum SignersFormControls {
    SIGNATURE_COLUMN = 'signatureColumn',
    SIGNER_OPTION = 'signerOption',
    SIGNER = 'signer'
}

export type LogEntryBulkRequestSignatureTableDataRow = LogEntry & {
    signatureColumn: Column;
    signer: User | null;
    signByDate: Date | null;
    reason: SigningReasons | '';
    checked: CHECKBOX_STATES;
    notifyMe: boolean;
    emailSigner: boolean;
    signatureColumnHasPendingSignatures: boolean;
    otherColumHasPendingSignatureForUser: boolean;
    signatureColumnHasSignatures: boolean;
}
