import './reports-index.scss';
import template from './reports-index.html';
import controller from './reports-index.controller';

const reportsIndexComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: { }
};

export default reportsIndexComponent;
