import * as _ from 'lodash';
import { catchError, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StateService } from '@uirouter/angularjs';

import { TeamService } from '@app/shared/teams/team.service';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { SORT } from '@app/core/constants';
import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Team,
    Study,
    Binder,
    Folder,
    Tag
} from '@app/shared/models';
import * as Moment from 'moment-timezone';

import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import {
    Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output
} from '@angular/core';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import template from './reports-table.component.html';
import style from './reports-table.component.scss';
import {
    ReportData,
    PaginationConfig,
    CompleteReportDataParams,
    SendSignatureRemindersPayload,
    ReminderParams
} from '../../reports.service.types';
import { ColumnConfig } from '../../reports-table.service.types';
import { ReportsTableService } from './reports-table.service';
import { ReportNames } from '../../report-names';
import {
    ReportPendingSignatureItem,
    DateFilterConfig,
    TableUpdateParams,
    AdditionalFilter,
    ReportLabel,
    ReportQCReviewStatus,
    ReportMonitorStatus,
    Sorter,
    ReportSipStatus,
    ReportTag
} from './reports-table.component.types';
import { ReportService } from '../../report.service';
@Component({
    selector: 'reports-table',
    template,
    styles: [String(style)]
})
export class ReportsTableComponent implements OnInit, OnChanges, OnDestroy {

    @Input() report: ReportData;
    @Input() reportName: string;
    @Input() reportParams: CompleteReportDataParams;
    @Input() dateFilterConfig: DateFilterConfig;
    @Input() selectedObjects: (Binder | Folder)[];
    @Input() selectedStudy: Study;
    @Input() selectedLabels: ReportLabel[] = [];
    @Input() selectedSIPStatuses: ReportSipStatus[] = [];
    @Input() selectedQCStatuses: ReportQCReviewStatus[] = [];
    @Input() selectedMonitorStatuses: ReportMonitorStatus[] = [];
    @Input() allTags: Tag[];
    @Input() allLabels: ReportLabel[];
    @Input() allMonitorReviewStatuses: ReportMonitorStatus[];
    @Input() allSipStatuses: ReportSipStatus[];
    @Input() allQcStatuses: ReportQCReviewStatus[];
    @Input() allStudies: Study[];
    @Input() minWidth: string;

    @Output() onTableUpdate = new EventEmitter<TableUpdateParams>();
    @Output() onAdditionalFiltersUpdate = new EventEmitter<AdditionalFilter[]>();
    @Output() onStartDateUpdate = new EventEmitter<Date>();
    @Output() onEndDateUpdate = new EventEmitter<Date>();
    @Output() onTagsSelectionChange = new EventEmitter<Tag[]>();
    @Output() onLabelsSelectionChange = new EventEmitter<ReportLabel[]>();
    @Output() onMonitorStatusesSelectionChange = new EventEmitter<ReportMonitorStatus[]>();
    @Output() onSipStatusesSelectionChange = new EventEmitter<ReportSipStatus[]>();
    @Output() onQcStatusesSelectionChange = new EventEmitter<ReportQCReviewStatus[]>();
    @Output() onStudySelectionChange = new EventEmitter<Study>();

    private currentTeam: Team;
    public selectedTags: ReportTag[] = [];
    public isSendingReminders = false;
    public canBulkUpdate = false;
    public isTaskReport = false;
    public showDateRangeFilter = false;
    public isSignaturesSignByDateReport = false;
    public shouldShowDatePicker = false;
    public checkboxCellStateProperty = 'checkboxState';
    public isDisabledDateTimePicker: boolean;
    public isLabelsReport: boolean;
    public isTagsReport: boolean;
    public isStudyReport: boolean;
    public isDocumentsOverviewReport: boolean;
    public columnConfig: ColumnConfig[];
    public paginationConfig: PaginationConfig;
    public sorter: Sorter;
    private REPORT_NAMES = ReportNames;
    private timezone = Moment.tz.guess();

    public allTagsSelected = false;
    public allLabelsSelected = false;
    public allMonitorStatusesSelected = false;
    public allSipStatusesSelected = false;
    public allQcStatusesSelected = false;

    public previousReportName: string;
    private isAllPlaceholdersFF: boolean;

    public filteredTags: ReportTag[] = [];
    public hasTagsToShow = false;
    public allFilteredTagsSelected = false;

    public filteredLabels: ReportLabel[] = [];
    public hasLabelsToShow = false;
    public allFilteredLabelsSelected = false;

    public isTagsAndLabelsSearchEnabled: boolean;

    private SORT = SORT;

    public documentsOverviewLastUpdatedAt: string;
    public isDocumentsOverviewCustomerMessageEnabled: boolean;
    public documentsOverviewCustomerMessage: string;

    constructor(
        private ReportsTable: ReportsTableService,
        private Teams: TeamService,
        private PaginationProperties: PaginationPropertiesService,
        private Downloads: DownloadsService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService,
        private $state: StateService,
        private ApiError: ApiErrorsService,
        private Reports: ReportService,
        private FeatureFlags: FeatureFlagService
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.initializeDropdownSelections(false);
        this.getPlaceholderFlag();
        this.getTagsAndLabelsSearchFlag();

        this.filteredTags = this.allTags;
        this.hasTagsToShow = !!this.filteredTags.length;

        this.filteredLabels = this.allLabels;
        this.hasLabelsToShow = !!this.filteredLabels.length;
    }

    ngOnChanges(): void {
        this._setPaginationConfig();
        this._setSortConfig();
        this._populateFilterFields();
        if (this.isAllPlaceholdersFF !== undefined) {
            this.columnConfig = this.ReportsTable.getColumns(this.reportName, this.isAllPlaceholdersFF);
        }

        this.isTaskReport = this.reportName === this.REPORT_NAMES.tasksPending;
        this.isLabelsReport = this.reportName === this.REPORT_NAMES.labels;
        this.isTagsReport = this.reportName === this.REPORT_NAMES.tags;
        this.isStudyReport = this.reportName === this.REPORT_NAMES.studies;
        this.isSignaturesSignByDateReport = this.reportName === this.REPORT_NAMES.signaturesSignByDate;
        this.isDocumentsOverviewReport = this.reportName === this.REPORT_NAMES.documentsOverview;
        this.canBulkUpdate = this.reportName === this.REPORT_NAMES.signaturesPending
            || this.isTaskReport
            || this.isSignaturesSignByDateReport;
        this.shouldShowDatePicker = this.reportName === this.REPORT_NAMES.documentsRecentlyUploaded
            || this.reportName === this.REPORT_NAMES.documentsRecentlyUploaded
            || this.reportName === this.REPORT_NAMES.dueDates
            || this.reportName === this.REPORT_NAMES.expirationDates
            || this.reportName === this.REPORT_NAMES.labels
            || this.reportName === this.REPORT_NAMES.monitorReviewsApproved
            || this.reportName === this.REPORT_NAMES.monitorReviewsOpen
            || this.reportName === this.REPORT_NAMES.signaturesCompleted
            || this.reportName === this.REPORT_NAMES.signaturesDeclined
            || this.reportName === this.REPORT_NAMES.signaturesMyQueue
            || this.reportName === this.REPORT_NAMES.signaturesPending
            || this.reportName === this.REPORT_NAMES.signaturesSignByDate
            || this.reportName === this.REPORT_NAMES.tasksClosed
            || this.reportName === this.REPORT_NAMES.tasksMyQueue
            || this.reportName === this.REPORT_NAMES.tasksPending
            || this.reportName === this.REPORT_NAMES.qcReport
            || this.reportName === this.REPORT_NAMES.qcReviewMyQueue
            || this.reportName === this.REPORT_NAMES.documentsOverview;

        const hasReportData = this.hasReportData();
        if (this.isDocumentsOverviewReport) {
            if (hasReportData) {
                this.populateDocumentType();
                this.populateExpirationDateForPlaceholders();
            }
        }

        this.populateDocumentsOverviewCustomerMessage();

        this.initializeDropdownSelections(false);
    }

    getPlaceholderFlag() {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.ALL_PLACEHOLDERS_REPORT, true).subscribe((value: boolean) => {
            this.isAllPlaceholdersFF = value;
        });
    }

    getTagsAndLabelsSearchFlag() {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.EBINDERS_REPORT_TAGS_LABELS_SEARCH, true).subscribe((value: boolean) => {
            this.isTagsAndLabelsSearchEnabled = value;
        });
    }

    ngOnDestroy(): void {
        this.removePopup();
    }

    populateDocumentType(): void {
        this.report.items.forEach((item) => {
            switch (item.documentType) {
                case 'content':
                    item.documentType = 'Document';
                    break;
                case 'placeholder':
                    item.documentType = 'Placeholder';
                    break;
                case 'log':
                    item.documentType = 'Log';
                    break;
                case 'form':
                    item.documentType = 'Form';
                    break;
                case 'shortcut':
                    item.documentType = 'Shortcut';
                    break;
                default:
                    break;
            }
        });
    }

    populateExpirationDateForPlaceholders(): void {
        this.report.items.forEach((item) => {
            if (item.documentType && item.documentType.toLowerCase() === 'placeholder') {
                item.expirationDate = 'N/A';
            }
        });
    }

    populateDocumentsOverviewCustomerMessage(): void {
        // If the FF is turned ON show the custom message to customer. Message will be whatever is written in the FF.
        this.FeatureFlags.getFlag(FEATURE_FLAGS.EBINDERS_DOCUMENTS_OVERVIEW_CUSTOMER_MESSAGE, true).subscribe((value: string) => {
            this.isDocumentsOverviewCustomerMessageEnabled = value !== '';
            this.documentsOverviewCustomerMessage = value;
        });

        // Format the date and time when the data was last updated. This timestamp will be a part of the customer message.
        this.documentsOverviewLastUpdatedAt = `(last updated at: ${Moment(this.report?.lastUpdatedAt).tz(this.timezone).format('DD-MMM-YYYY @ LT z')})`;
    }

    initializeDropdownSelections(emitEvent?: boolean) {
        switch (this.reportName) {
            case ReportNames.labels:
                this.bulkSelectLabels(true, this.selectedLabels, null, emitEvent);
                break;
            case ReportNames.tags:
                this.bulkSelectTags(true, this.selectedTags, null, emitEvent);
                break;
            case ReportNames.monitorReviewsApproved:
                this.bulkSelectMonitorStatuses(true, this.selectedMonitorStatuses, null, emitEvent);
                break;
            case ReportNames.sipDocumentStatuses:
                this.bulkSelectSIPStatuses(true, this.selectedSIPStatuses, null, emitEvent);
                break;
            case ReportNames.qcReport:
            case ReportNames.qcReviewMyQueue:
                this.bulkSelectQCStatuses(true, this.selectedQCStatuses, null, emitEvent);
                break;
            default:
                break;
        }

        this.previousReportName = this.reportName;
    }

    hasReportData(): boolean {
        return this.report && this.report.items && this.report.items.length && !!this.columnConfig;
    }

    hasAllSelectionsToShowTable(): boolean {
        let hasDropdownItems = true;

        if (this.reportName === ReportNames.labels) {
            hasDropdownItems = (this.allLabels.length > 0);
        }
        else if (this.reportName === ReportNames.tags) {
            hasDropdownItems = (this.allTags.length > 0);
        }

        const hasRequiredObjectsSelection = (this.selectedObjects && this.selectedObjects.length && hasDropdownItems)
            || this.reportName === ReportNames.studies;
        return this.reportName
            && hasRequiredObjectsSelection
            && (this.reportName !== ReportNames.studies || !!this.selectedStudy);
    }

    getTooltipValue(): string {

        switch (this.reportName) {
            case this.REPORT_NAMES.documentsRecentlyUploaded:
            case this.REPORT_NAMES.documentsOverview:
                return 'Uploaded Date - Date Range';
            case this.REPORT_NAMES.dueDates:
                return 'Due Date - Date Range';
            case this.REPORT_NAMES.expirationDates:
                return 'Expiration Date - Date Range';
            case this.REPORT_NAMES.labels:
                return 'Date Applied - Date Range';
            case this.REPORT_NAMES.monitorReviewsApproved:
                return 'Date - Date Range';
            case this.REPORT_NAMES.monitorReviewsOpen:
                return 'Last Modified - Date Range';
            case this.REPORT_NAMES.signaturesCompleted:
                return 'Signed On - Date Range';
            case this.REPORT_NAMES.signaturesDeclined:
                return 'Declined On - Date Range';
            case this.REPORT_NAMES.signaturesMyQueue:
                return 'Sign By Date - Date Range';
            case this.REPORT_NAMES.signaturesPending:
                return 'Requested On - Date Range';
            case this.REPORT_NAMES.signaturesSignByDate:
                return 'Sign By Date - Date Range';
            case this.REPORT_NAMES.tasksClosed:
                return 'Closed On - Date Range';
            case this.REPORT_NAMES.tasksMyQueue:
                return 'Last Modified - Date Range';
            case this.REPORT_NAMES.tasksPending:
                return 'Last Modified - Date Range';
            case this.REPORT_NAMES.qcReport:
            case this.REPORT_NAMES.qcReviewMyQueue:
                return 'Request Created - Date Range';
            default:
                return 'Date Range';
        }
    }

    getReport(name: string) {
        return this.reportName === name;
    }

    get reportNames() {
        return ReportNames;
    }

    selectSingleTag(tag: ReportTag): void {

        if (tag.isSelected) {
            this.selectedTags.push(tag);
        }
        else {
            this.selectedTags.splice(this.selectedTags.findIndex((element) => element.id === tag.id), 1);
        }

        this.allTagsSelected = this.selectedTags.length === this.allTags.length;
        const filteredTagsNotSelected = this.filteredTags.filter((filteredTag) => !this.selectedTags.includes(filteredTag));

        this.allFilteredTagsSelected = !filteredTagsNotSelected.length;
        this.onTagsSelectionChange.emit(this.selectedTags);
    }

    bulkSelectTags(newSelectedValue: boolean, selectionData: ReportTag[], event?: Event, emitEvent = true): void {

        if (event) {
            // keeps dropdown open
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        selectionData?.forEach((tag) => {
            tag.isSelected = newSelectedValue;
        });

        if (this.isTagsAndLabelsSearchEnabled) {
            const nonSelectedFilteredTags = selectionData?.filter((tag) => !this.selectedTags?.includes(tag));
            if (newSelectedValue && selectionData?.length) {
                // to the previously selected tags add newly selected tags
                this.selectedTags = this.selectedTags?.concat([...nonSelectedFilteredTags]);
            }
            else {
                // from the selected tags remove newly unselected tags
                this.selectedTags = this.selectedTags?.filter((tag) => !selectionData?.includes(tag));
            }
            this.allFilteredTagsSelected = !(this.filteredTags.filter((tag) => !selectionData?.includes(tag)).length > 0);
        }
        else {
            this.selectedTags = (newSelectedValue && selectionData?.length) ? [...selectionData] : [];
        }
        this.allTagsSelected = selectionData?.length === this.allTags.length;

        // if there are selected tags (newSelectedValue is true), load report
        return emitEvent && this.onTagsSelectionChange.emit(this.selectedTags);
    }

    selectSingleLabel(label: ReportLabel): void {
        if (label.isSelected) {
            this.selectedLabels.push(label);
        }
        else {
            this.selectedLabels.splice(this.selectedLabels.findIndex((element) => element.id === label.id), 1);
        }

        this.allLabelsSelected = this.selectedLabels.length === this.allLabels.length;
        const filteredLabelsNotSelected = this.filteredLabels
            .filter((filteredLabel) => !this.selectedLabels.includes(filteredLabel));

        this.allFilteredLabelsSelected = !filteredLabelsNotSelected.length;
        this.onLabelsSelectionChange.emit(this.selectedLabels);
    }

    bulkSelectLabels(newSelectedValue: boolean, selectionData: ReportLabel[], event?: Event, emitEvent = true): void {

        if (!this.allLabels) {
            return;
        }

        if (event) {
            // keeps dropdown open
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        selectionData.forEach((label) => {
            label.isSelected = newSelectedValue;
        });

        if (this.isTagsAndLabelsSearchEnabled) {
            const nonSelectedFilteredLabels = selectionData?.filter((label) => !this.selectedLabels?.includes(label));
            if (newSelectedValue && selectionData?.length) {
                // to the previously selected labels add newly selected labels
                this.selectedLabels = this.selectedLabels?.concat([...nonSelectedFilteredLabels]);
            }
            else {
                // from the selected labels remove newly unselected labels
                this.selectedLabels = this.selectedLabels?.filter((label) => !selectionData?.includes(label));
            }
            this.allFilteredLabelsSelected = !(this.filteredLabels.filter((label) => !selectionData?.includes(label)).length > 0);
        }
        else {
            this.selectedLabels = newSelectedValue ? [...selectionData] : [];
        }
        this.allLabelsSelected = selectionData?.length === this.allLabels.length;

        // if there are selected labels (newSelectedValue is true), load report
        return emitEvent && this.onLabelsSelectionChange.emit(this.selectedLabels);
    }

    selectSingleMonitorStatus(monitorStatus: ReportMonitorStatus): void {

        if (monitorStatus.isSelected) {
            this.selectedMonitorStatuses.push(monitorStatus);
        }
        else {
            this.selectedMonitorStatuses.splice(this.selectedMonitorStatuses
                .findIndex((element) => element.name === monitorStatus.name), 1);
        }

        this.allMonitorStatusesSelected = this.selectedMonitorStatuses.length === this.allMonitorReviewStatuses.length;
        this.onMonitorStatusesSelectionChange.emit(this.selectedMonitorStatuses);
    }

    bulkSelectMonitorStatuses(
        newSelectedValue: boolean,
        selectionData: ReportMonitorStatus[],
        event?: Event,
        emitEvent = true
    ): void {

        if (event) {
            // keeps dropdown open
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        selectionData.forEach((monitorStatus) => {
            monitorStatus.isSelected = newSelectedValue;
        });
        this.selectedMonitorStatuses = newSelectedValue ? [...selectionData] : [];
        this.allMonitorStatusesSelected = selectionData?.length === this.allMonitorReviewStatuses.length;

        // if there are selected monitor statuses (newSelectedValue is true), load report
        return emitEvent && this.onMonitorStatusesSelectionChange.emit(this.selectedMonitorStatuses);
    }

    selectSingleSIPStatus(status: ReportSipStatus): void {

        if (status.isSelected) {
            this.selectedSIPStatuses.push(status);
        }
        else {
            this.selectedSIPStatuses.splice(this.selectedSIPStatuses.findIndex((element) => element.name === status.name), 1);
        }
        this.allSipStatusesSelected = this.selectedSIPStatuses.length === this.allSipStatuses.length;
        this.onSipStatusesSelectionChange.emit(this.selectedSIPStatuses);
    }

    bulkSelectSIPStatuses(
        newSelectedValue: boolean,
        selectionData: ReportSipStatus[] = [],
        event?: Event,
        emitEvent = true
    ): void {

        if (!this.allSipStatuses) {
            return;
        }

        if (event) {
            // keeps dropdown open
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        selectionData.forEach((status) => {
            status.isSelected = newSelectedValue;
        });
        this.selectedSIPStatuses = newSelectedValue ? [...selectionData] : [];
        this.allSipStatusesSelected = selectionData?.length === this.allSipStatuses.length;
        return emitEvent && this.onSipStatusesSelectionChange.emit(this.selectedSIPStatuses);
    }

    selectSingleQCStatus(status: ReportQCReviewStatus): void {
        if (status.isSelected) {
            this.selectedQCStatuses.push(status);
        }
        else {
            this.selectedQCStatuses.splice(this.selectedQCStatuses.findIndex((element) => element.name === status.name), 1);
        }
        this.allQcStatusesSelected = this.selectedQCStatuses.length === this.allQcStatuses.length;
        this.onQcStatusesSelectionChange.emit(this.selectedQCStatuses);
    }

    bulkSelectQCStatuses(
        newSelectedValue: boolean,
        selectionData: ReportQCReviewStatus[] = [],
        event?: Event,
        emitEvent = true
    ): void {

        if (!this.allQcStatuses) {
            return;
        }

        if (event) {
            // keeps dropdown open
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        selectionData.forEach((status) => {
            status.isSelected = newSelectedValue;
        });
        this.selectedQCStatuses = newSelectedValue ? [...selectionData] : [];
        this.allQcStatusesSelected = selectionData?.length === this.allQcStatuses.length;
        return emitEvent && this.onQcStatusesSelectionChange.emit(this.selectedQCStatuses);
    }

    selectSingleStudy(study: Study): void {
        if (this.selectedStudy.id === study.id) {
            this.selectedStudy = {} as Study;
        }
        else {
            this.selectedStudy = study;
        }
        this.onStudySelectionChange.emit(this.selectedStudy);
    }

    tagsFilterKeyPressed(event: string): void {
        if (event === '') {
            this.filteredTags = this.allTags;
            this.allFilteredTagsSelected = this.allTagsSelected;
        }
        else {
            this.filteredTags = this.allTags.filter((tag) => tag.name.toLowerCase().includes(event.toLowerCase().trim()));
            const filteredTagsNotSelected = this.filteredTags.filter((tag) => !this.selectedTags.includes(tag));
            this.allFilteredTagsSelected = !filteredTagsNotSelected.length;
        }
        this.hasTagsToShow = !!this.filteredTags.length;
    }

    labelsFilterKeyPressed(event: string): void {
        if (event === '') {
            this.filteredLabels = this.allLabels;
            this.allFilteredLabelsSelected = this.allLabelsSelected;
        }
        else {
            this.filteredLabels = this.allLabels.filter((label) => label.name.toLowerCase().includes(event.toLowerCase().trim()));
            const filteredLabelsNotSelected = this.filteredLabels.filter((label) => !this.selectedLabels.includes(label));
            this.allFilteredLabelsSelected = !filteredLabelsNotSelected.length;
        }
        this.hasLabelsToShow = !!this.filteredLabels.length;
    }

    _setPaginationConfig(): void {

        const maxSize = this.PaginationProperties.getMaxSize() || 5;
        const showBoundary = this.PaginationProperties.showBoundaryLinkNumbers();

        this.paginationConfig = {
            pageSize: 20,
            pageNum: (this.reportParams?.pageNum) || 1,
            totalItemCount: this.report ? this.report.recordCount : 0,
            maxSize,
            showBoundary
        };
    }

    _populateFilterFields() {
        if (this.reportParams?.additionalFilters) {
            this.reportParams.additionalFilters.forEach((filter) => {
                const foundColumn = this.ReportsTable.getColumns(this.reportName, this.isAllPlaceholdersFF)
                    .find((config) => this.ReportsTable.getFilterFieldFromColumnConfig(config) === filter.filterField);

                if (foundColumn) {
                    if (this.reportName === ReportNames.documentsOverview && Array.isArray(filter.filterBy)) {
                        foundColumn.searchFieldPreselected = filter.filterBy;
                    }
                    foundColumn.searchValue = filter.filterBy;
                    this.ReportsTable.updateReportColumn(this.reportName, foundColumn);
                }

                this.isDisabledDateTimePicker = filter.filterField === 'dueDate' && filter.filterBy === 'Without Due Date';
            });
        }

        if (this.reportParams?.monitorStatuses) {
            this.selectedMonitorStatuses = this.reportParams.monitorStatuses.map((status) => {
                return {
                    isSelected: true,
                    name: status
                };
            });
            this.allMonitorReviewStatuses.forEach((status) => {
                status.isSelected = !!this.reportParams.monitorStatuses.includes(status.name);
            });
        }

        if (this.reportParams?.sipStatuses) {
            this.selectedSIPStatuses = this.reportParams.sipStatuses.map((status) => {
                return {
                    isSelected: true,
                    name: status
                };
            });
            this.allSipStatuses.forEach((status) => {
                status.isSelected = !!this.reportParams.sipStatuses.includes(status.name);
            });
            this.allSipStatusesSelected = this.selectedSIPStatuses.length === this.allSipStatuses.length;
        }

        if (this.reportParams?.qcStatuses) {
            this.selectedQCStatuses = this.reportParams.qcStatuses.map((status) => {
                return {
                    isSelected: true,
                    name: status
                };
            });
            this.allQcStatuses.forEach((status) => {
                status.isSelected = !!this.reportParams.qcStatuses.includes(status.name);
            });
            this.allQcStatusesSelected = this.selectedQCStatuses.length === this.allQcStatuses.length;
        }

        if (this.reportParams?.labelIds) {
            this.selectedLabels = this.allLabels.filter((label) => this.reportParams.labelIds.includes(label.id));
        }

        if (this.reportParams?.tagIds) {
            this.selectedTags = this.allTags.filter((tag) => this.reportParams.tagIds.includes(tag.id));
        }

        if (this.reportParams?.studyIds) {
            this.selectedStudy = this.allStudies.find((study) => this.reportParams.studyIds.includes(study.id));
        }
    }

    onPageChange(pagination: PaginationConfig): void {
        const updateParams: TableUpdateParams = {
            pageNum: pagination.pageNum,
            sortDir: this.SORT.isReversed ? 'DESC' : 'ASC',
            sortBy: this.SORT.by
        };
        this.onTableUpdate.emit(updateParams);
    }

    onStartDateSelect(event: { value: Date }): void {
        this.onStartDateUpdate.emit(event.value);
    }

    onEndDateSelect(event: { value: Date }): void {
        this.onEndDateUpdate.emit(event.value);
    }

    _setSortConfig(): void {
        const defaultSortProperty = this.ReportsTable.getDefaultSort(this.reportName);
        const defaultSortDirProperty = this.ReportsTable.getDefaultSortDirection(this.reportName);
        const sortDir = this.reportParams.sortDir || defaultSortDirProperty;
        this.SORT.by = this.reportParams.sortBy || defaultSortProperty;
        this.SORT.isReversed = sortDir === 'DESC';
        this.sorter = {
            SORT: this.SORT,
            onSortChange: this.onSortChange.bind(this),
            defaultSortProperty
        };
    }

    onFilterConfirmed($event: ColumnConfig): void {
        this.ReportsTable.updateReportColumn(this.reportName, $event);

        const updatedReportColumns = this.ReportsTable.getColumns(this.reportName, this.isAllPlaceholdersFF);

        const hasWhiteSpaceFilter = updatedReportColumns.find((column) => {
            if (!Array.isArray(column.searchValue)) {
                return column.searchValue?.length && column.searchValue.trim() === '';
            }
            return false;
        });

        if (hasWhiteSpaceFilter) {
            this.report = {
                recordCount: 0,
                items: []
            };
            this._setPaginationConfig();
            return;
        }

        const additionalFilters = updatedReportColumns
            .filter((column) => {
                if (!Array.isArray(column.searchValue)) {
                    return column.searchValue && column.searchValue.trim();
                }
                return true;
            })
            .map((column) => {
                return {
                    filterField: this.ReportsTable.getFilterFieldFromColumnConfig(column),
                    filterBy: Array.isArray(column.searchValue) ? column.searchValue : column.searchValue.trim(),
                    filterType: this.ReportsTable.getFilterTypeFromColumnConfig(column)
                };
            });
        this.isDisabledDateTimePicker = !!additionalFilters.find((column) => (column.filterField === 'dueDate' && column.filterBy === 'Without Due Date'));
        this.onAdditionalFiltersUpdate.emit(additionalFilters);
    }

    onSortChange(sort: { isReversed: boolean; by: string }): void {
        const updateParams: TableUpdateParams = {
            pageNum: this.paginationConfig.pageNum,
            sortDir: sort.isReversed ? 'DESC' : 'ASC',
            sortBy: sort.by
        };
        this.onTableUpdate.emit(updateParams);
    }

    getSelectedReportItems() {
        if (!this.report || !this.report.items) {
            return [];
        }

        return this.report.items.filter((item) => item[this.checkboxCellStateProperty]);
    }

    enableSendReminders(): boolean {
        if (!this.canBulkUpdate) {
            return;
        }

        const permissionName = this.isTaskReport ? 'manageDocTasks' : 'requestSignature';
        const selectedItems = this.getSelectedReportItems();
        const hasPermissionsOnAllSelected = selectedItems.every((selectedItem) => selectedItem.permissions[permissionName]);
        return selectedItems.length && hasPermissionsOnAllSelected;
    }

    downloadReport(): void {
        const binderIds = this.selectedObjects?.filter((o) => o.type === 'binder').map((i) => i.id);
        const folderIds = this.selectedObjects?.filter((o) => o.type === 'folder').map((i) => i.id);

        this.Downloads.downloadReport({
            ...this.isStudyReport
                ? { studyIds: this.selectedStudy && this.selectedStudy.id && [this.selectedStudy.id] }
                : {
                    ...binderIds.length && { binderIds },
                    ...folderIds.length && { folderIds }
                },
            format: 'csv', // for now we are only supporting csv format for report download
            teamId: this.currentTeam.id,
            reportId: this.reportParams.reportId,
            tagIds: this.selectedTags.map((tag) => tag.id),
            monitorStatuses: this.selectedMonitorStatuses.map((status) => status.name),
            labelIds: this.selectedLabels.map((label) => label.id),
            sipStatuses: this.selectedSIPStatuses.map((status) => status.name),
            qcStatuses: this.selectedQCStatuses.map((status) => status.name),
            sortBy: this.reportParams.sortBy,
            sortDir: this.reportParams.sortDir,
            filterBy: this.reportParams.filterBy,
            filterField: this.reportParams.filterField,
            dateFilter: this.reportParams.dateFilter,
            additionalFilters: this.reportParams.additionalFilters
        })
            .subscribe(
                () => {
                    const href = this.$state.href('app.team.downloads', { teamId: this.currentTeam.id });
                    const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
            Go to <a class="page-action u-d-inline" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                    this.Notifications.info({ message, closeOnClick: false });
                },
                (error) => {
                    this.ApiError.handleError(error);
                }
            );
    }

    sendReminders(): void {
        const selectedItems = this.getSelectedReportItems();
        if (!this.canBulkUpdate || !selectedItems.length) {
            return;
        }

        if (this.isTaskReport) {
            this._sendTaskReminders(selectedItems);
            return;
        }
        this._sendSignatureReminders(selectedItems);
    }

    _sendTaskReminders(selectedItems): void {
        // get tasks that are assigned to users and format as { taskId, userIds } array
        const params = {
            teamId: this.currentTeam.id,
            remind: []
        };
        selectedItems.forEach((item) => {

            if (item.assignedTo && item.assignedTo.length && item.assignedTo[0].id) {
                params.remind.push({
                    taskId: item.taskId,
                    userIds: _.map(item.assignedTo, 'id')
                });
            }
        });

        this.isSendingReminders = true;
        this.Teams.bulkUpdateTask(params, selectedItems)
            .subscribe(
                () => {
                    this.isSendingReminders = false;
                },
                () => {
                    this.isSendingReminders = false;
                }
            );
    }

    _sendSignatureReminders(selectedItems: ReportPendingSignatureItem[]): void {
        this.isSendingReminders = true;
        const payload: SendSignatureRemindersPayload = {
            comment: this.isSignaturesSignByDateReport ? 'N/A - Sent via eBinders™ Signatures - Sign By Date Report' : 'N/A - Sent via eBinders™ Signatures - Pending Report',
            updates: []
        };
        selectedItems.forEach((reportItem) => {
            const resendItem: ReminderParams = {
                id: reportItem.signatureRequestId,
                reason: reportItem.reason,
                signByDate: reportItem.signByDate,
                method: null,
                notifyRequestorWhenSigned: null
            };
            const payloadUpdateIndex = payload.updates.findIndex((u) => u.documentId === reportItem.documentId);
            if (payloadUpdateIndex > -1) {
                payload.updates[payloadUpdateIndex].resend.push(resendItem);
            }
            else {
                payload.updates.push({
                    documentId: reportItem.documentId,
                    version: reportItem.version,
                    resend: [resendItem]
                });
            }
        });

        this.Reports.sendSignatureReminders(payload).pipe(
            take(1),
            tap(() => {
                this.isSendingReminders = false;
            }),
            catchError(() => {
                this.isSendingReminders = false;
                return of(null);
            })
        ).subscribe();

    }

    removePopup() {
        const datepicker = window.document.getElementsByClassName('datetime-picker-dropdown');
        if (datepicker.length) {
            datepicker[0].remove();
        }
    }
}
