import { CurrentSessionService } from '@app/core/current-session.service';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import {
    DocumentHistory,
    DocumentHistoryFilterField,
    GetDocumentHistoriesQueryParams,
    GetDocumentHistoryResponse
} from '@app/shared/document-history/document-history.service.types';
import {
    AuditTrailEventType,
    Document,
    Team
} from '@app/shared/models';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { DynamicFilterTypeItem } from '../../../../widgets/dynamic-filter/dynamic-filter.component';
import template from './document-history-modal.component.html';
import styles from './document-history-modal.component.scss';

@Component({
    selector: 'document-history-modal',
    template,
    styles: [String(styles)]
})
export class DocumentHistoryModalComponent implements OnInit {

    @Input() document: Document;
    @Input() getDocumentHistories: (paginationParams: GetDocumentHistoriesQueryParams) => Promise<GetDocumentHistoryResponse>;

    histories: DocumentHistory[] = [];
    textFilter = '';
    textFilterSelected: DynamicFilterTypeItem;
    showPaginationElement: boolean;

    readonly textFilterTypes: DynamicFilterTypeItem[] = [
        { label: 'Action', filterField: DocumentHistoryFilterField.ACTION },
        { label: 'Who', filterField: DocumentHistoryFilterField.WHO }
    ];

    pagination = {
        pageNum: 1,
        pageSize: 20,
        sortBy: 'timestamp' as GetDocumentHistoriesQueryParams['sortBy'],
        sortDir: 'DESC' as GetDocumentHistoriesQueryParams['sortDir'],
        filterBy: 'ACTION' as GetDocumentHistoriesQueryParams['filterBy'],
        searchTerm: '' as GetDocumentHistoriesQueryParams['searchTerm'],
        totalItems: 0,
        maxSize: 0,
        showBoundaryLinkNumbers: false
    };

    public team: Team;
    auditTrailEventType = AuditTrailEventType;

    constructor(
        private CurrentSession: CurrentSessionService,
        private PaginationProperties: PaginationPropertiesService,
        private modalInstance: BsModalRef,
        private ApiError: ApiErrorFactory
    ) {
        [this.textFilterSelected] = this.textFilterTypes;

        this.team = this.CurrentSession.getCurrentTeam();
        this.pagination.maxSize = this.PaginationProperties.getMaxSize();
        this.pagination.showBoundaryLinkNumbers = this.PaginationProperties.showBoundaryLinkNumbers();
    }

    ngOnInit(): void {
        this.loadDocumentHistories();
    }

    loadDocumentHistories() {

        this.getDocumentHistories(this.pagination)
            .then((data) => {
                this.histories = data.documentHistories;
                this.pagination.totalItems = data.totalItems;
            }).catch(this.ApiError.handleError);
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    sortChanged(sortBy: GetDocumentHistoriesQueryParams['sortBy']): void {

        if (this.pagination.sortBy === sortBy) {
            this.pagination.sortDir = this.pagination.sortDir === 'ASC' ? 'DESC' : 'ASC';
        }
        this.pagination.sortBy = sortBy;
        this.loadDocumentHistories();
    }

    pageChanged(): void {
        this.loadDocumentHistories();
    }

    setFilter(filter: string): void {
        this.pagination.searchTerm = filter;
        this.pagination.pageNum = 1;
        this.loadDocumentHistories();
    }

    onFilterTypeChange(filterType: DynamicFilterTypeItem): void {
        this.pagination.filterBy = filterType.filterField as GetDocumentHistoriesQueryParams['filterBy'];
    }
}
