import {
    Component,
    EventEmitter,
    OnInit,
    Output
} from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StudyRolesService } from '@app/shared/study-roles/study-roles.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team } from '@app/shared/models';
import { StudyTeamPreview } from '@app/shared/study-roles/study-roles.types';
import template from './study-team-import.component.html';
import styles from './study-team-import.component.scss';
import templateExample from '../../../../../assets/study-site-team-template.xlsx';


@Component({
    selector: 'study-team-import',
    template,
    styles: [String(styles)]
})
export class StudyTeamImportComponent implements OnInit {
    @Output() onTeamImport: EventEmitter<StudyTeamPreview[]> = new EventEmitter();

    public currentTeam: Team;
    public teamMembersUploaded = false;
    public uploadedFile: File;
    public isProcessingFileUpload = false;
    public isProcessing = false;
    public errorCountInStudyTeam = 0;
    public loadingSizes = ['40%', '45%', '30%'];
    public templateExample = templateExample;
    public studyTeam: StudyTeamPreview[];

    constructor(
        private modalRef: BsModalRef,
        private Notifications: NotificationsService,
        private StudyRoles: StudyRolesService,
        private CurrentSession: CurrentSessionService
    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
    }

    onFileAdded($event: FileList): void {
        this.isProcessingFileUpload = true;
        if ($event.length > 1) {
            this.Notifications.error('Please upload a single file.');
            return;
        }
        const file = $event.item(0);
        const extension = file.name.split('.').pop();
        if (!(extension?.toLowerCase() === 'xlsx')) {
            this.Notifications.error('Only .xlsx or .csv files are allowed.');
            return;
        }
        this.uploadedFile = file;
        this.teamMembersUploaded = true;

        this.StudyRoles.getFilePreview(this.currentTeam.id, file)
            .subscribe((data) => {
                this.studyTeam = data.sort((a, b) => {
                    const userNameComparison = a.userName.localeCompare(b.userName);
                    if (userNameComparison !== 0) {
                        return userNameComparison;
                    }
                    return a.email.localeCompare(b.email);
                });
                this.isProcessingFileUpload = false;
                this.countStudyTeamErrors();
            },
            ({ error }) => {
                this.Notifications.error(error.message || 'Failed to process the file. Please try again.');
                this.removeFile();
                this.isProcessingFileUpload = false;
            });
    }

    private countStudyTeamErrors(): void {
        this.errorCountInStudyTeam = this.studyTeam.filter((member) => member.error).length;
    }

    removeFile(): void {
        this.uploadedFile = null;
        this.errorCountInStudyTeam = 0;
        this.teamMembersUploaded = false;
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    onSubmit(): void {
        this.onTeamImport.emit(this.studyTeam);
        this.hideModal();
    }
}
