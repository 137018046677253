'use strict';

module.exports = [
    '.doc',
    '.docx',
    // '.rtf', This is removed, as Apryse has performance issues with this doc type
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.tif',
    '.tiff',
    '.txt',
    '.pdf',
    // '.csv', This is removed, as Apryse has performance issues with this doc type
    '.jp2',
    '.emf',
    '.wmf',
    '.bmp',
    '.pub',
    '.vsdx',
    '.vsd'
];
