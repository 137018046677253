import {
    Binder, Folder, SignatureRequest, SigningReasons
} from '@app/shared/models';

export enum SortDirectionTypes {
    'ASC',
    'DESC'
}

export type AdditionalFilterType = {
    filterBy: string;
    filterField: string;
    filterType: 'text' | 'select'
}

export interface SpecificReportParams {
    tagIds?: string[];
    labelIds?: string[];
    studyIds?: string[];
    sipStatuses?: string[];
    qcStatuses?: string[];
    monitorStatuses?: string[];
    sortBy?: string;
    sortDir?: keyof typeof SortDirectionTypes;
    pageNum: number;
    pageSize?: number;
    filterBy?: string;
    filterField?: string;
    dateFilter?: {
        start: Date;
        end: Date;
    };
    additionalFilters?: AdditionalFilterType[]
}

export interface TeamReportParams {
    teamId: string;
    reportId: string;
    binders?: Binder[];
    folders?: Folder[];
    binderIds?: string[];
    folderIds?: string[];
    withPermissions?: boolean;
}

export interface CompleteReportDataParams extends SpecificReportParams, TeamReportParams{}


export interface ReportData {
    recordCount: number;
    items: any[];
    lastUpdatedAt?: Date;
}
export interface PaginationConfig {
    pageSize: number;
    pageNum: number;
    totalItemCount: number;
    maxSize: number;
    showBoundary: boolean;
}

export interface ReminderParams {
    id: string;
    reason: SigningReasons;
    method: null;
    signByDate: string;
    notifyRequestorWhenSigned: null;
}

export interface SendSignatureRemindersPayload {
    comment: 'N/A - Sent via eBinders™ Signatures - Sign By Date Report' | 'N/A - Sent via eBinders™ Signatures - Pending Report';
    updates: {
        documentId: string;
        version: number;
        resend: ReminderParams[];
    }[];
}

export type SendSignatureRemindersResponse = Array<{
    statusCode: number;
    payload: {
        createdRequests: SignatureRequest[];
        updatedRequests: SignatureRequest[];
        cancelledRequestCount: number;
    };
}>
