import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { ModalsModule } from '@app/widgets/modals/modals.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { ScrollingModule, ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import {
    ArchiveTabsContainer
} from '@app/components/archive/containers/archive-tabs/archive-tabs.component';
import { ArchiveContainerComponent } from '@app/components/archive/containers/archive-container/archive-container.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ArchivedListComponent } from '@app/components/archive/components/archived-list.component';
import { UIRouterModule } from '@uirouter/angular';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ArchivesService } from './archives.service';
import { ArchiveDownloadComponent } from './containers/archive-download/archive-download.component';
import { ArchiveWizardComponent } from './containers/archive-wizard/archive-wizard.component';
import { ArchiveFormComponent } from './containers/archive-wizard/archive-form/archive-form.component';
import { StatementsComponent } from './containers/archive-wizard/statements/statements.component';
import { SummaryComponent } from './containers/archive-wizard/summary/summary.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        ModalsModule,
        WidgetsModule,
        ScrollingModule,
        TabsModule,
        UIRouterModule,
        TooltipModule
    ],
    providers: [
        ArchivesService,
        ScrollDispatcher,
        ViewportRuler
    ],
    declarations: [
        ArchiveDownloadComponent,
        ArchiveContainerComponent,
        ArchiveTabsContainer,
        ArchivedListComponent,
        ArchiveWizardComponent,
        ArchiveFormComponent,
        StatementsComponent,
        SummaryComponent,
        ArchiveDownloadComponent
    ],
    entryComponents: [
        ArchiveDownloadComponent,
        ArchiveContainerComponent,
        ArchiveTabsContainer,
        ArchivedListComponent,
        ArchiveWizardComponent,
        ArchiveFormComponent,
        StatementsComponent,
        SummaryComponent,
        ArchiveDownloadComponent
    ],
    exports: []
})
export class ArchiveModule {}
