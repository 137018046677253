import * as _ from 'lodash';
import { StateService } from '@uirouter/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';

class ApiErrorFactory {
    private currentTeam;
    private lastRedirectHash = {};

    constructor(
        private $q: ng.IQService,
        private $state: StateService,
        CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService
    ) {
        CurrentSession.currentTeam$.subscribe((team) => {
            this.currentTeam = team;
        });
    }

    private redirectOnError = (): void => {
        if (this.currentTeam && !this.hasRecentlyRedirected('app.team.binders')) {
            this.$state.go('app.team.binders', { teamId: this.currentTeam.id });
            this.recordRedirect('app.team.binders');
        }
        else if (!this.hasRecentlyRedirected('sign-in')) {
            this.$state.go('sign-in');
            this.recordRedirect('sign-in');
        }
    };

    private recordRedirect = (state): void => {
        this.lastRedirectHash[state] = Date.now();
    };

    private hasRecentlyRedirected = (state, threshold = 350): boolean => this.lastRedirectHash[state]
        && ((Date.now() - this.lastRedirectHash[state]) < threshold);

    private displayApiError = (errMessage): void => {
        const message = errMessage || 'There was an unexpected error. Please try again. If the error still happens, please contact your administrator.';
        this.Notifications.error(message);
    };

    parseErrorMessage = (apiError = {}): string => {
        const errorMessagePaths = ['output.payload.message', 'data.message', 'error.message', 'message'];
        return errorMessagePaths.reduce((msg, path) => msg || _.get(apiError, path, ''), '');
    };

    handleError = (apiError): void|ng.IPromise<never> => {
        const msg = this.parseErrorMessage(apiError || {});
        this.displayApiError(msg);
        if (apiError && apiError.status === 403) {
            if (apiError.headers && (!_.isFunction(apiError.headers) || !apiError.headers('x-fhc-password-expired'))) {
                //  Redirect on permissions error
                this.redirectOnError();
            }
        }
        return this.$q.reject(apiError || {});
    };

    handleErrorWithoutRedirectOn403 = (apiError): ng.IPromise<never> => {
        const msg = this.parseErrorMessage(apiError || {});
        this.displayApiError(msg);
        return this.$q.reject(apiError || {});
    };
}

ApiErrorFactory.$inject = ['$q', '$state', 'CurrentSession', 'Notifications'];

export default ApiErrorFactory;
