const reasonModalWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
    <reason-modal
        [header]="vm.resolve.header"
        [reason-label]="vm.resolve.reasonLabel || 'Please provide a reason for these changes:'"
        (save)="vm.resolve.onSubmit($event)"
        (dismiss)="vm.modalInstance.close()"
    ></reason-modal>
    `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default reasonModalWrapperComponent;
