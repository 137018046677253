import { StateProvider } from '@uirouter/angularjs';

const archivesRoute = ($stateProvider: StateProvider): void => {
    $stateProvider.state('app.team.archive-wizard', {
        url: '/archives-wizard/:objectId/:objectType/:objectName',
        params: {
            objectId: '',
            objectType: '',
            objectName: ''
        },
        views: {
            '': 'archiveWizard'
        }
    });
    $stateProvider.state('archives', {
        url: '/archives-download/teams/:teamId/archives/:archiveId',
        params: {
            teamId: '',
            archiveId: ''
        },
        views: {
            'layout@': {
                component: 'archiveDownload'
            }
        }
    });
    $stateProvider.state('app.team.long-term-archives', {
        url: '/archive-list',
        params: {
            teamId: ''
        },
        views: {
            '': { component: 'archiveContainer' }
        }
    });
};

archivesRoute.$inject = ['$stateProvider'];

export default archivesRoute;
