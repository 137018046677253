import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MarketingNotification } from '@app/shared/models/marketing-notification.model';
import { UpdateMarketingNotificationResponse } from './marketing-notifications.service.types';

@Injectable()
export class MarketingNotificationsService {
    private readonly url = {
        marketingNotifications: '/api/marketing-notifications',
        editMarketingNotification: (id: string): string => `/api/marketing-notifications/${id}`
    };

    constructor(private $http: HttpClient) {}

    get(): Observable<MarketingNotification[]> {
        return this.$http.get<MarketingNotification[]>(this.url.marketingNotifications);
    }

    update(id: string, notification: MarketingNotification): Observable<UpdateMarketingNotificationResponse> {
        return this.$http.patch<UpdateMarketingNotificationResponse>(this.url.editMarketingNotification(id), notification);
    }
}
