import { Document, LogEntry } from '@app/shared/models';

export class LogEntrySignatureRequestsModalWrapperController {
    public modalInstance: ng.ui.bootstrap.IModalInstanceService;
    public resolve: {
        logDocument: Document;
        logEntry: LogEntry;
        preselectedSignerId: string;
    };

    onDismissed(): void {
        this.modalInstance.close();
    }
}
