import * as _ from 'lodash';
import { AuditTrailSubject } from '@app/shared/models';
import { ConfirmDestroySubmitEvent } from '@app/widgets/confirm-destroy/confirm-destroy.component.types';
import { ManageAccessSubmitEvent } from '@app/widgets/manage-access/manage-access.component.types';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { AccessDatesReportComponent } from '../../components/access-dates-report/access-dates-report.component';
import { AddUsersComponent } from '../../components/add-users/add-users.component';
import { AddUsersEvent } from '../../components/add-users/add-users.component.types';

class ManageTeamMembersController {
    crumbs = [{ name: 'Manage Team Members' }];

    constructor(SORT, Team, CurrentSession, AuditTrail, $state, modalHelper, AccessDates, PaginationProperties,
        Notifications, ApiError, FeatureFlagService, private Modals: ModalsService) {
        this.SORT = SORT;
        this._Team = Team;
        this._AuditTrail = AuditTrail;
        this._$state = $state;
        this._modalHelper = modalHelper;
        this._AccessDates = AccessDates;
        this._CurrentSession = CurrentSession;
        this._Notifications = Notifications;
        this._ApiError = ApiError;
        this._FeatureFlag = FeatureFlagService;

        this.loadingUsers = false;
        this.loadedUsers = false;
        this.maxSize = PaginationProperties.getMaxSize();
        this.showBoundaryLinkNumbers = PaginationProperties.showBoundaryLinkNumbers();

        this.selectedUser = undefined;
        this.filterValue;
        this.pagination = {
            pageSize: 20,
            sortBy: 'profile.firstName',
            sortDir: 'ASC',
            totalItems: 0
        };
    }

    $onInit(): void {
        this.currentTeam = this._CurrentSession.getCurrentTeam();
        this.currentUser = this._CurrentSession.getCurrentUser();

        if (!this._canManageTeamMembers(this.currentTeam.permissions)) {
            return this._$state.go('app.select-team');
        }
        this.crumbs = [{ name: `Manage Team Members: ${this.currentTeam.name}` }];
        this._fetchUsers();
        this._FeatureFlag.getFlag(FEATURE_FLAGS.NEW_ACCESS_DATES_REPORT, true).subscribe((value: boolean) => {
            this.isNewAccessDates = value;
        });
    }

    _canManageTeamMembers(perm = {}) {
        return perm.manageTeam
            || perm.viewTeamUsersRolesPermissions
            || perm.inviteUsers
            || perm.uninviteUsers;
    }

    _filterTeamRolesOnUsers() {

        this.users.items.forEach((user) => {

            user.roles = user.roles.filter((role) => this.currentTeam.id === role.teamId && !role.isHiddenRole);
        });
    }

    addUsers() {
        if (!this.canAddUsers()) {
            return;
        }
        const addUsersModal = this.Modals.show(AddUsersComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {}
        });
        addUsersModal.content.onAddUser.subscribe((event: AddUsersEvent) => {
            const { success } = event;
            if (success) {
                this.pageChanged();
            }
        });
    }

    openAuditTrailModal() {
        if (this.canViewAuditTrail()) {
            const params = {
                subject: AuditTrailSubject.TEAM_MEMBERS,
                teamId: this.currentTeam.teamId,
                objectId: this.currentTeam.id,
                overwrittenObjectId: this.currentTeam.overwrittenPlaceholderId || null,
                limitToOverwritten: false,
                ...this._AuditTrail.auditPagination
            };


            this._AuditTrail.getAudits(params).toPromise().then((audits) => {
                this.Modals.show(AuditTrailModalComponent, {
                    class: 'modal-lg',
                    initialState: {
                        data: audits,
                        item: this.currentTeam,
                        subject: params.subject,
                        pagination: this._AuditTrail.auditPagination,
                        onPageChange: this._AuditTrail.getAudits.bind(this._AuditTrail)
                    }
                });

            }).catch((errorResponse) => {
                const message = errorResponse && errorResponse.error && errorResponse.error.message;
                if (message) {
                    this._Notifications.error(message);
                }
                else {
                    this._Notifications.unexpectedError();
                }
            });
        }
    }

    openPermissionReport(user) {
        if (this.canViewPermissionOverview()) {
            this._$state.go('app.team.permission-report', {
                teamId: this.currentTeam.id,
                subjectId: user.id,
                subjectType: 'user'
            });
        }
    }

    cancelInvitation() {
        if (this.canUninviteUsers()) {
            const displayName = _.escape(this.selectedUser.name || this.selectedUser.email);
            const bodyText = 'This action <span class="strong text-uppercase">cannot</span> be undone. This will permanently remove or cancel the '
            + `invitation for <span class="strong">${displayName}</strong>.`;
            this._modalHelper.open({
                animation: false,
                component: 'confirm-destroy-wrapper',
                resolve: {
                    onSubmit: () => (event: ConfirmDestroySubmitEvent): void => {
                        this.removeUser()
                            .then(event.onSuccess)
                            .catch(event.onError);
                    },
                    bodyText: () => bodyText,
                    confirmVerb: () => 'Remove',
                    confirmVerbProcessing: () => 'Removing'
                },
                size: 'md'
            });
        }
    }

    removeUser() {
        const user = _.find(this.users.items, 'selected');
        return this._Team.removeUserFromTeam(user)
            .toPromise()
            .then(() => {
                this.selectedUser = undefined;
            })
            .then(this._fetchUsers.bind(this));
    }

    resendInvitation() {
        if (this.canReinviteUsers()) {
            const selectedUser = _.find(this.users.items, 'selected');
            this._Team.resendInvitation(selectedUser).toPromise();
        }
    }

    select(user) {
        if (user.selected) {
            user.selected = false;
            this.selectedUser = undefined;
        }
        else {
            this.users.items.forEach((userItem) => {
                userItem.selected = false;
            });

            user.selected = true;
            this.selectedUser = user;
        }
    }

    toggleActions($event, user) {
        $event.stopPropagation();
        if (this.selectedUser !== user) {
            this.select(user);
        }
    }

    findRoles(filter) {

        return this._Team.searchRolesAndUsers(`role ${filter}`).toPromise();
    }

    assignUserRoles() {
        if (!this.canManageUsers()) {
            return;
        }

        const userId = this.selectedUser.id;
        const teamId = this.currentTeam.id;

        this._modalHelper.open({
            animation: false,
            component: 'manage-access-wrapper',
            size: 'lg',
            keyboard: false,
            resolve: {
                onSubmit: () => (event: ManageAccessSubmitEvent): void => {
                    const {
                        creates, updates, deletes, sames
                    } = event.data;
                    this._Team.updateRoleAssignmentMultiple(teamId, { creates, updates, deletes })
                        .toPromise()
                        .then((data) => {
                            event.onSuccess();

                            const createdRolesForUser = data.created.map((userRole) => userRole.role);
                            const updatedRolesForUser = data.updated.map((userRole) => userRole.role);
                            this.selectedUser.roles = sames.concat(createdRolesForUser, updatedRolesForUser);
                        })
                        .catch(event.onError);
                },
                items: () => this._AccessDates.getRolesForUser({ userId, teamId }).toPromise().then((data) => data)
                    .catch((fetchError) => {
                        this._Notifications.error(fetchError.error.message);
                        throw fetchError;
                    }),
                canAssignDates: () => this.currentTeam.permissions.manageTeamAccessControl,
                canAssignRoles: () => this.currentTeam.permissions.assignTeamRoles,
                subject: () => _.clone(this.selectedUser),
                itemType: () => 'role'
            }
        });

    }

    async removeAllPermissions(user): Promise<void> {
        if (!this.canRemoveAllPermissions()) {
            return;
        }
        const userRoles = await this._AccessDates.getRolesForUser({ teamId: this.currentTeam.id, userId: user.id })
            .toPromise()
            .then((data) => data);
        let bodyText = '<div>This action cannot be undone. It will <b>Remove ALL Permissions</b> this user has on the team.</div>';
        if (userRoles.length) {
            bodyText += '<div>This action will also detach the user from the following roles:<ul>';
            userRoles.forEach((userRole) => {
                bodyText += `<li><strong>${userRole.role.name}</strong></li>`;
            });
            bodyText += '</ul></div>';
        }
        bodyText += '<div>ARE YOU SURE you want to go ahead with this action?</div>';
        this._modalHelper.open({
            animation: false,
            component: 'confirm-destroy-wrapper',
            resolve: {
                bodyText: () => bodyText,
                confirmVerb: () => 'Remove ALL Permissions',
                confirmVerbProcessing: () => 'Saving',
                onSubmit: () => (event: ConfirmDestroySubmitEvent): void => {
                    return this._Team
                        .executeRemoveAllPermissions(this.currentTeam.id, user)
                        .toPromise()
                        .then(event.onSuccess)
                        .then(() => {
                            this._$state.reload();
                        })
                        .catch(event.onError);
                }
            },
            size: 'md'
        });
    }

    isCurrentUser() {
        return this.selectedUser && this.currentUser.id === this.selectedUser.id;
    }

    canAddUsers() {
        return this.currentTeam.permissions.inviteUsers;
    }

    canViewAuditTrail() {
        return this.currentTeam.permissions.viewTeamAuditTrail;
    }

    canManageUsers() {
        return this.selectedUser && this.currentTeam.permissions.viewTeamUsersRolesPermissions;
    }

    canReinviteUsers() {
        return this.currentTeam.permissions.inviteUsers
            && this.selectedUser
            && !this.selectedUser.isConfirmed;
    }

    canUninviteUsers() {
        return this.currentTeam.permissions.uninviteUsers && this.selectedUser && !this.isCurrentUser();
    }

    canViewPermissionOverview() {
        return this.currentTeam.permissions.viewTeamUsersRolesPermissions && this.selectedUser;
    }

    canRemoveAllPermissions() {
        return this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedUser && !this.isCurrentUser();
    }

    canActOnSelection() {
        return this.canUninviteUsers()
            || this.canManageUsers()
            || this.canViewPermissionOverview()
            || this.canRemoveAllPermissions()
            || this.canReinviteUsers();
    }

    sortChanged(sortProperty) {

        if (this.pagination.sortBy === sortProperty) {
            this.pagination.sortDir = this.pagination.sortDir === 'DESC' ? 'ASC' : 'DESC';
        }
        this.pagination.sortBy = sortProperty;
        this._fetchUsers();
    }

    pageChanged() {

        this._fetchUsers();
    }

    _fetchUsers() {

        const params = {
            teamId: this.currentTeam.teamId,
            filter: this.filterValue === '' ? undefined : this.filterValue,
            ...this.pagination
        };

        this.loadingUsers = true;
        this._Team
            .loadUsers(params)
            .toPromise()
            .then((users) => {
                this.loadedUsers = true;
                this.sortDir = this.pagination.sortDir;
                this.users = users;
                this.pagination.totalItems = users.recordCount;
                this._filterTeamRolesOnUsers();
            })
            .finally(() => {
                this.loadingUsers = false;
            });
    }

    applyFilter(filter) {
        this.filterValue = filter;
        this.pagination.pageNum = 1;
        this.selectedUser = undefined;
        this._fetchUsers();
    }

    canViewAccessDatesReport() {
        return this.currentTeam.permissions.viewTeamUsersRolesPermissions;
    }

    openAccessDatesReport() {
        if (this.canViewAccessDatesReport()) {
            this.Modals.show(AccessDatesReportComponent, {
                animated: true,
                class: this.isNewAccessDates ? 'modal-xl' : 'modal-lg',
                initialState: undefined
            });
        }
    }
}

ManageTeamMembersController.$inject = [
    'SORT',
    'Team',
    'CurrentSession',
    'AuditTrail',
    '$state',
    'modalHelper',
    'AccessDates',
    'PaginationProperties',
    'Notifications',
    'ApiError',
    'FeatureFlagService',
    'ModalsService'
];

export default ManageTeamMembersController;
