import {
    Directive,
    ElementRef,
    Injector,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'date-time-picker'
})
export class DateTimePickerDirective extends UpgradeComponent {
    @Input() date: string | Date;
    @Input() minDate: string | Date;
    @Input() maxDate: string | Date;
    @Input() dateToDisplay: string;
    @Input() includeTime: boolean;
    @Input() allowPast: boolean;
    @Input() allowZero: boolean;
    @Input() clearOption = true;
    @Input() timezone: string;
    @Input() isDisabled: boolean;
    @Input() isInvalid: boolean;
    @Input() isWarning: boolean;
    @Input() placeholderText: string;
    @Input() datePickerOptions;
    @Input() isLongTermArchive: boolean;
    @Output() onSelect = new EventEmitter<{ value: Date }>();
    @Output() onInvalid = new EventEmitter<void>();

    constructor(elementRef: ElementRef, injector: Injector) {
        super('dateTimePicker', elementRef, injector);
    }
}
