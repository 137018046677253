import template from './date-time-picker.html';
import controller from './date-time-picker.controller';
import './date-time-picker.scss';

/*
* @param {string | object} date - optional - the date the picker should default to
* @param {string | object} minDate - optional - the date before which the picker should disable dates
* @param {string | object} maxDate - optional - the date after which the picker should disable dates
* @param {boolean} includeTime - optional - if true, the time should be included in picker
* @param {boolean} allowPast - optional - indicates if the picker should allow past dates (only validated
* if the date/time is changed)
* @param {boolean} allowZero - optional - if true, will show date even it it is zero. otherwise, zero dates
* are interpreted as blank
* @param {string} timezone - optional - the timezone of the date
* @param {boolean} isDisabled - optional - indicates if the input is disabled (true) or editable (false)
* @param {boolean} isInvalid - optional - indicates if the input is currently in an error state
* @param {function} onChanges - a function to run when the date input is updated
* @param {string} placeholderText - text to appear in the input field when input is blank
*
*/

const dateTimePickerComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        date: '<?',
        minDate: '<?',
        maxDate: '<?',
        isLongTermArchive: '<?',
        includeTime: '<?',
        allowPast: '<?',
        allowZero: '<?',
        clearOption: '<?',
        timezone: '<?',
        isDisabled: '<?',
        isInvalid: '<?',
        isWarning: '<?',
        onSelect: '&?',
        onInvalid: '&?',
        placeholderText: '@'
    }
};

export default dateTimePickerComponent;
