import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArchivesService } from '@app/components/archive/archives.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import {
    ApiError, Archive, ArchiveStatus, Crumb, SelectionState, SelectionStates, Team
} from '@app/shared/models';
import { StateService } from '@uirouter/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import {
    switchMap,
    take, takeUntil
} from 'rxjs/operators';
import { TabsDetails } from '../archive-tabs/archive-tabs.types';
import template from './archive-container.component.html';
import styles from './archive-container.component.scss';

@Component({
    selector: 'archive-list-container',
    template,
    styles: [String(styles)]
})
export class ArchiveContainerComponent implements OnInit, OnDestroy {

    crumbs: Crumb[] = [{ name: 'Archives' }];
    team: Team;
    archivesList: Archive[] | null = null;
    selectedArchives: Set<Archive['id']> = new Set();
    selectedTab: BehaviorSubject<TabsDetails['heading']>;
    archivedTabDesc = 'Archived binders/folders store your study documents long term. The details you see below reflect the actions that happened until the archive date.';
    scheduledTabDesc = 'These are your binders/folders scheduled for long term archiving. If you want to cancel the upcoming archive, click Remove.';
    filterText = null;
    private searchSub?: Subscription;

    private readonly destroy$ = new Subject<void>();
    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Archives: ArchivesService,
        private Notifications: NotificationsService
    ) {
        this.team = CurrentSession.getCurrentTeam();
        this.selectedTab = Archives.selectedTab$;
    }

    ngOnInit() {
        if (!(this.team && this.team.permissions && this.team.permissions.viewArchives)) {
            this.$state.go('app.select-team');
        }
        this.getArchives();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getArchives() {
        this.Archives.selectedTab$.pipe(
            switchMap((tab) => {
                const archiveStatus: Archive['status'] = tab === 'Archived' ? ArchiveStatus.complete : ArchiveStatus.scheduled;
                this.archivesList = null;
                return this.Archives.getArchives(this.team.id, archiveStatus, this.filterText ?? undefined);
            }),
            takeUntil(this.destroy$)
        ).subscribe((archives) => {
            this.archivesList = Object.values(archives);
        }, ({ error }: ApiError) => {
            if (error?.message) {
                this.Notifications.error(error.message);
            }
            else {
                this.Notifications.unexpectedError();
            }
        });
    }

    private getArchivesOnFilter(tab) {
        const archiveStatus: Archive['status'] = tab === 'Archived' ? ArchiveStatus.complete : ArchiveStatus.scheduled;
        this.searchSub?.unsubscribe();
        this.searchSub = this.Archives.getArchives(this.team.id, archiveStatus, this.filterText ?? undefined)
            .subscribe((archives) => {
                this.archivesList = Object.values(archives);
            });
    }

    handleArchiveSelected(archive: Archive) {
        if (this.selectedArchives.has(archive.id)) {
            this.selectedArchives.delete(archive.id);
        }
        else {
            this.selectedArchives.add(archive.id);
        }
    }

    private selectAllArchives() {
        this.selectedArchives = new Set(this.archivesList.map((archive) => archive.id));
    }

    private deselectAllArchives() {
        this.selectedArchives = new Set();
    }

    handleAllArchivesToggle(state: SelectionStates) {
        if (state === SelectionState.FULL) {
            this.selectAllArchives();
        }
        else {
            this.deselectAllArchives();
        }
    }

    get canBulkDownload() {
        return !!(this.selectedArchives && this.selectedArchives.size);
    }

    bulkDownload() {
        this.selectedArchives.forEach((selectedArchiveId) => {
            const selectedArchive = this.archivesList.find((archive) => archive.id === selectedArchiveId);
            this.Archives.downloadArchive(this.team.id, selectedArchive.archiveId)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.Notifications.success(`Archive for ${selectedArchive.type} "${selectedArchive.name}" has been downloaded to your computer`);
                    },
                    error: async ({ error }) => {
                        const err = JSON.parse(await error.text());
                        if (err?.message) {
                            this.Notifications.error(err.message);
                        }
                        else {
                            this.Notifications.unexpectedError();
                        }
                    }
                });
        });
    }

    onFilterChanged(searchText: string, tab: TabsDetails['heading']) {
        this.filterText = searchText;
        this.archivesList = null;
        this.getArchivesOnFilter(tab);
    }
}
