import * as angular from 'angular';
import dateTimePickerComponent from './date-time-picker.component';
import 'bootstrap-ui-datetime-picker';

const dependencies = [];

const dateTimePicker = angular
    .module('app.widgets.dateTime', dependencies)
    .component('dateTimePicker', dateTimePickerComponent);

export default dateTimePicker;
