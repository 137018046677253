import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '@app/shared/shared.module';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpgradeModule } from '@angular/upgrade/static';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProjectsService } from '@app/shared/projects/projects.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimelineFormComponent } from './compontents/timeline-form/timeline-form.component';
import { CreateTimelineModalComponent } from './compontents/create-timeline-modal/create-timeline-modal.component';
import { DestroyProjectOrTimelineComponent } from './compontents/destroy-project/destroy-project-or-timeline.component';
import { ProjectizerItemComponent } from './containers/projectizer/projectizer-item/projectizer-item.component';
import { EditProjectModalComponent } from './compontents/edit-project-modal/edit-project-modal.component';
import { ProjectizerComponent } from './containers/projectizer/projectizer.component';
import { DuplicateTimelineComponent } from './compontents/duplicate-timeline-modal/duplicate-timeline-modal.component';
import { ProjectsPageComponent } from './containers/projects-page/projects-page.component';
import { ProjectsDetailPageComponent } from './containers/projects-detail-page/projects-detail-page.component';
import { TimelineSelectionDropdownComponent } from './compontents/timeline-selection-dropdown/timeline-selection-dropdown.component';
import { TimelineVisibilitySettingsComponent } from './compontents/timeline-visibility-settings/timeline-visibility-settings.component';
import { ModifyCompletionModalComponent } from './compontents/modify-completion-modal/modify-completion-modal.component';
import { DestroyItemComponent } from './compontents/destroy-item/destroy-item.component';
import { TimelineMetricsComponent } from './compontents/timeline-metrics/timeline-metrics.component';
import { DashboardTimelinesComponent } from './containers/dashboard-timelines/dashboard-timelines.component';

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        CommonModule,
        SharedModule,
        DragDropModule,
        EntityModule,
        WidgetsModule,
        FormsModule,
        ReactiveFormsModule,
        UpgradeModule,
        BrowserAnimationsModule

    ],
    providers: [
        NotificationsService,
        ProjectsService,
        BsModalRef
    ],
    declarations: [
        TimelineVisibilitySettingsComponent,
        TimelineFormComponent,
        CreateTimelineModalComponent,
        DestroyProjectOrTimelineComponent,
        ProjectizerItemComponent,
        EditProjectModalComponent,
        ProjectizerComponent,
        DuplicateTimelineComponent,
        ProjectsPageComponent,
        ProjectsDetailPageComponent,
        TimelineSelectionDropdownComponent,
        ModifyCompletionModalComponent,
        DestroyItemComponent,
        TimelineMetricsComponent,
        DashboardTimelinesComponent
    ],
    entryComponents: [
        TimelineVisibilitySettingsComponent,
        TimelineFormComponent,
        CreateTimelineModalComponent,
        DestroyProjectOrTimelineComponent,
        ProjectizerItemComponent,
        EditProjectModalComponent,
        ProjectizerComponent,
        DuplicateTimelineComponent,
        ProjectsPageComponent,
        ProjectsDetailPageComponent,
        TimelineSelectionDropdownComponent,
        ModifyCompletionModalComponent,
        DestroyItemComponent,
        TimelineMetricsComponent,
        DashboardTimelinesComponent
    ],
    exports: []
})
export class ProjectsModule {}
