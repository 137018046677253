import { Transition } from '@uirouter/angularjs';

const scrollConfig = ($transitions: Transition): void => {
    $transitions.onSuccess({}, () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });
};

scrollConfig.$inject = [
    '$transitions'
];

export default scrollConfig;
