const MissingSigningPasscodeWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
        <missing-signing-passcode
            [entity]="vm.resolve.entity"
            (on-submit)="vm.resolve.onSubmit($event)"
            (dismiss)="vm.modalInstance.close()">
        </missing-signing-passcode>
        `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default MissingSigningPasscodeWrapperComponent;
