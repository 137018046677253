import template from './inactivity-warning.html';
import controller from './inactivity.warning.controller';

const inactivityWarningComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        item: '<',
        modalInstance: '<',
        resolve: '<'
    }
};

export default inactivityWarningComponent;
