import { Transition, HookResult, StateService } from '@uirouter/angularjs';
import { take } from 'rxjs/operators';
import { Auth0Service } from '@app/shared/sessions/auth0.service';

const friendlyForwardConfig = (
    $transitions: Transition,
    $state: StateService,
    auth0: Auth0Service
): void => {
    $transitions.onStart({}, (transiton: Transition): HookResult => {
        return auth0.isAuthenticated$.pipe(take(1)).toPromise().then((isAuthenticated) => {
            const toState = transiton.to();
            const toParams = transiton.params('to');

            if (toState.data && toState.data.requiresAuth && !isAuthenticated) {
                const returnTo = $state.href(toState.name, toParams, { absolute: true });
                return $state.target('sign-in', { returnTo });
            }
        });
    });
};

friendlyForwardConfig.$inject = [
    '$transitions',
    '$state',
    'Auth0Service'
];

export default friendlyForwardConfig;
