import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent } from '@angular/upgrade/static';
import downloadsRoute from './downloads.route';
import { DownloadsIndexComponent } from './containers/downloads-index/downloads-index.component';

const dependencies = [
    uiRouter
];

const bindersModule = angular
    .module('app.downloads', dependencies)
    .config(downloadsRoute)
    .directive('downloadsIndex', downgradeComponent({ component: DownloadsIndexComponent }) as ng.IDirectiveFactory);

export default bindersModule;
