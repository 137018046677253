import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import rolesRoute from './roles.route';
import { RolesService } from './roles.service';
import { RequestPreviewRoleTemplatesComponent } from './components/request-preview-role-templates/request-preview-role-templates.component';
import manageRolesComponent from './containers/manage-roles/manage-roles.component';
import { ManageRolesTemplatesComponent } from './containers/manage-roles-templates/manage-roles-templates.component';
import { RolePermissionsComponent } from './containers/role-permissions/role-permissions.component';
import { RolePermissionsFormComponent } from './components/role-permissions-form/role-permissions-form.component';
import { RolesTemplatesByStudyNumberComponent } from './components/roles-templates-by-study-number/roles-templates-by-study-number.component';
import { AssignUsersToRolesComponent } from './components/assign-users-to-roles/assign-users-to-roles.component';
import { RolesTemplatesPreviewComponent } from './components/roles-templates-preview/roles-templates-preview.component';
import { RolesTemplatesByTemplateComponent } from './components/roles-templates-by-template/roles-templates-by-template.component';
import { CreateRoleComponent } from './components/create-role/create-role.component';

const dependencies = [
    uiRouter
];

const rolesModule = angular
    .module('app.roles', dependencies)
    .factory('Roles', downgradeInjectable(RolesService))
    .directive('createRole', downgradeComponent({ component: CreateRoleComponent }) as ng.IDirectiveFactory)
    .directive('assignUsersToRoles', downgradeComponent({ component: AssignUsersToRolesComponent }) as ng.IDirectiveFactory)
    .component('manageRoles', manageRolesComponent)
    .directive('manageRolesTemplates', downgradeComponent({ component: ManageRolesTemplatesComponent }) as ng.IDirectiveFactory)
    .directive('rolePermissions', downgradeComponent({ component: RolePermissionsComponent }) as ng.IDirectiveFactory)
    .directive('rolePermissionsForm', downgradeComponent({ component: RolePermissionsFormComponent }) as ng.IDirectiveFactory)
    .directive('requestPreviewRoleTemplates', downgradeComponent({ component: RequestPreviewRoleTemplatesComponent }) as ng.IDirectiveFactory)
    .directive('rolesTemplatesByStudyNumber', downgradeComponent({ component: RolesTemplatesByStudyNumberComponent }) as ng.IDirectiveFactory)
    .directive('rolesTemplatesPreview', downgradeComponent({ component: RolesTemplatesPreviewComponent }) as ng.IDirectiveFactory)
    .directive('rolesTemplatesByTemplate', downgradeComponent({ component: RolesTemplatesByTemplateComponent }) as ng.IDirectiveFactory)
    .config(rolesRoute);

export default rolesModule;
