import * as _ from 'lodash';

const internals = {};

/**
 * Slight modification of typeahead's uibTypeaheadHighlight filter
 * https://github.com/angular-ui/bootstrap/blob/0d79005f8d1f4d674bb04ba93c41bb9c06280b4f/src/typeahead/typeahead.js#L669
 */
const partialTextHighlightFilter = ($sce, $injector, $log) => {

    const isSanitizePresent = $injector.has('$sanitize');

    return (matchItem, query) => {

        if (!isSanitizePresent && internals.containsHtml(matchItem)) {
            $log.warn('Unsafe use of typeahead please use ngSanitize'); // Warn the user about the danger
        }
        let item = _.escape(matchItem);

        /**
         * Lets say we have a item `texas oncology staff`,
         * and our query is `tex on`.
         * We first replace `tex` with `<strong>tex</strong>`,
         * so we end up with: `<strong>tex</strong>as oncology staff`.
         * Now we need to highlight `on`, but we must ignore matches inside html tags,
         * or we will end up with something like this:
         * `<str<strong>on</strong>g>tex</strong>as oncology staff`.
         *
         * Thats why we are calling internals.matchFilter(), which ignores html tags
         */
        query.split(' ').forEach((filter) => {
            // Replaces the capture string with a the same string inside of a "strong" tag
            item = (`${item}`).replace(
                internals.matchFilter(filter),
                '<strong>$&</strong>'
            );
        });

        if (!isSanitizePresent) {
            // If $sanitize is not present we pack the string in a $sce object for the ng-bind-html directive
            item = $sce.trustAsHtml(item);
        }
        return item;
    };
};

internals.matchFilter = (filter) => {

    const ignoreMatchesInHtmlTags = '(?!([^<])*?>)';
    return new RegExp(internals.escapeRegexp(filter) + ignoreMatchesInHtmlTags, 'i');
};

internals.escapeRegexp = (queryToEscape) => queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
internals.containsHtml = (matchItem) => /<.*>/g.test(matchItem);

partialTextHighlightFilter.$inject = ['$sce', '$injector', '$log'];

export default partialTextHighlightFilter;
