import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { Team, User } from '@app/shared/models';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@app/shared/app-config/app-config.service';

@Injectable()
export class CurrentSessionService {
    public currentUser$: Observable<User>;
    public currentTeam$: Observable<Team>;
    public canUsePartialRequests$: Observable<boolean>;
    private currentUser: BehaviorSubject<User>;
    private currentTeam: BehaviorSubject<Team>;
    private canUsePartialRequests: BehaviorSubject<boolean>;

    private readonly userProfileRequiredFields = [
        'firstName',
        'lastName',
        'orgType',
        'orgName',
        'jobFunction',
        'jobTitle'
    ];

    constructor(
        private AppConfig: AppConfigService
    ) {
        this.currentUser = new BehaviorSubject<User | undefined>(undefined);
        this.currentUser$ = this.currentUser.asObservable();

        this.currentTeam = new BehaviorSubject<Team>(undefined);
        this.currentTeam$ = this.currentTeam.asObservable();

        this.canUsePartialRequests = new BehaviorSubject<boolean>(undefined);
        this.canUsePartialRequests$ = this.canUsePartialRequests.asObservable();
    }

    private isProfileFillRequired(user: User): boolean {
        const { newUsersThresholdDate } = this.AppConfig.config;
        const userCreatedTime = new Date(user.createdAt).getTime();
        const tresholdTime = new Date(newUsersThresholdDate).getTime();

        const isProfileMissingInfo = this.userProfileRequiredFields.some(
            (key: string) => !user.profile[key as keyof User['profile']]
        );
        if (userCreatedTime > tresholdTime) {
            return isProfileMissingInfo;
        }
        return false;
    }

    isRegistrationFinished(user: User): boolean {
        const hasAcceptedTerms = user.hasAcceptedTermsOfUse;
        const hasFilledPrivacyPolicy = user.privacyConsent && Object.keys(user.privacyConsent).length;

        if (!this.isProfileFillRequired(user) && hasAcceptedTerms && hasFilledPrivacyPolicy) {
            return true;
        }

        return false;
    }

    getCurrentUser(): User {
        return _.cloneDeep(this.currentUser.getValue());
    }

    setCurrentUser(user: User): void {
        this.currentUser.next(user);
    }

    getCurrentTeam(): Team {
        return _.cloneDeep(this.currentTeam.getValue());
    }

    setCurrentTeam(team?: Team): void {
        this.currentTeam.next(team);
    }

    getCanUsePartialRequests(): boolean {
        return this.canUsePartialRequests.getValue();
    }

    setCanUsePartialRequests(value: boolean): void {
        this.canUsePartialRequests.next(value);
    }
}
