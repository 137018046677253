import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import inactivityWarningComponent from './inactivity.warning.component';
import inactivityLogoff from './inactivity.logoff';

const dependencies = [
    uiRouter,
    inactivityLogoff.name
];

const inactivityWarningModule = angular
    .module('inactivityWarning', dependencies)
    .component('inactivityWarning', inactivityWarningComponent);

export default inactivityWarningModule;
