import {
    Directive, Input, Output, EventEmitter, ElementRef, Injector
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({ selector: 'date-time-picker' })
export class DateTimePickerComponent extends UpgradeComponent {
    @Input() date?: Date | string;
    @Input() includeTime?: boolean;
    @Input() allowPast?: boolean;
    @Input() allowZero?: boolean;
    @Input() clearOption?: boolean;
    @Input() timezone?: string;
    @Input() isDisabled?: boolean;
    @Input() isInvalid?: boolean;
    @Input() isWarning?: boolean;
    @Input() placeholderText?: string;
    @Output() onSelect = new EventEmitter<{ value: Date }>();
    @Output() onInvalid = new EventEmitter<void>();

    constructor(elementRef: ElementRef, injector: Injector) {
        super('dateTimePicker', elementRef, injector);
    }
}
