import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnDestroy, OnInit
} from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import {
    BrowseTree, Crumb, Document, Team
} from '@app/shared/models';

import {
    VirtualTreeFlatNode,
    VirtualTreeItemSelectedEvent
} from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { BindersService } from '@app/shared/binders/binders.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { DOCUMENT_TYPES } from '@florencehealthcare/florence-constants/lib/documents';

import {
    BehaviorSubject,
    Observable,
    Subject,
    throwError
} from 'rxjs';
import {
    catchError,
    debounceTime,
    map,
    switchMap,
    take,
    takeUntil
} from 'rxjs/operators';
import { StateService } from '@uirouter/angularjs';
import {
    DocumentShareVersionOptions
} from '@app/components/document-sharing/components/share-documents-virtual-tree/share-documents-virtual-tree.component.types';
import { TeamService } from '@app/shared/teams/team.service';
import { ModalsService } from '../../../../shared/modal-helper/modals.service';
import { ShareDocumentsComponent } from '../../components/share-documents/share-documents.component';
import { ChooseTeamsComponent } from '../../components/choose-teams/choose-teams.component';
import {
    PageCommand,
    Connection,
    DocumentSharingView,
    ExchangeEvent,
    SortObject,
    GetExchangeEventParams,
    GetActiveConnectionsParams,
    DocumentDirection
} from '../../services/document-sharing.types';
import { ExchangeEventsApiService } from '../../services/exchange-events/exchange-events-api.service';
import { ExchangeEventsStateService } from '../../services/exchange-events/exchange-events-state.service';
import { DocumentSharingApiService } from '../../services/share-documents/share-documents-api.service';
import { DocumentSharingStateService } from '../../services/share-documents/share-documents-state.service';
import template from './document-sharing.component.html';
import styles from './document-sharing.component.scss';

@Component({
    selector: 'document-sharing',
    template,
    styles: [String(styles)]
})
export class DocumentSharingComponent implements OnInit, OnDestroy {
    public team: Team;
    public crumbs: Crumb[] = [{ name: 'Document Sharing' }];
    public sharingView$: Observable<DocumentSharingView>;
    public documentDirection: DocumentDirection;
    public exchangeEvents$: Observable<ExchangeEvent[]>;
    public noResultsMessage$: Observable<string[]>;
    public isLoading$: Observable<boolean>;
    public sorts$: Observable<SortObject[]>;
    public startDate$: Observable<Date>;
    public endDate$: Observable<Date>;
    public hasNext$: Observable<boolean>;
    public currentPage$: Observable<number>;
    public minSelectableDate: Date;
    public maxSelectableDate: Date;

    private destroy$ = new Subject<void>();
    private pageCommand$ = new BehaviorSubject<PageCommand>('nextPage');

    // This pipe of exchangeEvent could be put into its own service middleware that interacts between API Service and Component
    // The subject is put into the component rather than the API service for more flexibility on requests.
    // Placing the subject into the API service limits to 1 request per injected service instance
    private getExchangeEventRequest$ = new Subject<GetExchangeEventParams>();
    private getExchangeEventResponse$ = this.getExchangeEventRequest$
        .pipe(
            debounceTime(250),
            switchMap((params: GetExchangeEventParams) => this.ExchangeEventApi.getExchangeEvents(params))
        );

    private getActiveConnectionsRequest$ = new Subject<GetActiveConnectionsParams>();
    private getActiveConnectionsResponse$ = this.getActiveConnectionsRequest$
        .pipe(
            switchMap((params: GetActiveConnectionsParams) => this.ExchangeEventApi.getActiveConnections(params))
        );

    private binderPermissions = new Map();
    private folderPermissions = new Map();

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private Binders: BindersService,
        private Notifications: NotificationsService,
        private Teams: TeamService,
        private Modals: ModalsService,
        private DocumentSharingStateManagement: DocumentSharingStateService,
        private DocumentSharingApi: DocumentSharingApiService,
        private ExchangeEventStateManagement: ExchangeEventsStateService,
        private ExchangeEventApi: ExchangeEventsApiService
    ) {
        this.sharingView$ = this.ExchangeEventStateManagement.documentSharingView$;
        this.exchangeEvents$ = this.ExchangeEventStateManagement.exchangeEvents$;
        this.noResultsMessage$ = this.ExchangeEventStateManagement.noResultsMessage$;
        this.isLoading$ = this.ExchangeEventStateManagement.isLoading$;
        this.sorts$ = this.ExchangeEventStateManagement.sorts$;
        this.startDate$ = this.ExchangeEventStateManagement.startDate$;
        this.endDate$ = this.ExchangeEventStateManagement.endDate$;
        this.hasNext$ = this.ExchangeEventStateManagement.hasNext$;
        this.currentPage$ = this.ExchangeEventStateManagement.currentPage$;
    }

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        this.ExchangeEventStateManagement.setConnectorIds([]);
        this.minSelectableDate = this.ExchangeEventStateManagement.minSelectableDate;
        this.maxSelectableDate = this.ExchangeEventStateManagement.maxSelectableDate;

        if (!(this.team && this.team.permissions && this.team.permissions.documentSharingView)) {
            this.$state.go('app.select-team');
        }
        this.getExchangeEventResponse$
            .pipe(
                map(({ exchangeEvents }) => {
                    const pageCommand = this.pageCommand$.getValue();
                    return ({
                        ...exchangeEvents,
                        pageCommand
                    });
                }),
                takeUntil(this.destroy$)
            )
            .subscribe(
                (params) => this.handleGetExchangeEvent(params),
                (error: HttpErrorResponse) => {
                    if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                }
            );

        this.getActiveConnectionsResponse$
            .pipe(
                switchMap((connections: Connection[]) => this.openChooseTeamsModal(connections)),
                takeUntil(this.destroy$)
            )
            .subscribe((connectionIds: string[]) => {
                this.ExchangeEventStateManagement.setConnectorIds(connectionIds);
                this.fetchFirstPage();
            });

        this.sharingView$
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((view) => {
                // clear the chosen teams filters when changing between docs received/sent tabs
                this.ExchangeEventStateManagement.setConnectorIds([]);
                this.ExchangeEventStateManagement.resetSorts();
                this.documentDirection = view === 'documentsReceived' ? 'receiving' : 'sending';
                this.fetchFirstPage();
            });
    }

    ngOnDestroy(): void {
        this.ExchangeEventStateManagement.setDocumentSharingView('documentsReceived');
        this.destroy$.next();
        this.destroy$.complete();
    }

    setNextSort(sort: SortObject): void {
        this.ExchangeEventStateManagement.setPrimarySort(sort);
        this.fetchFirstPage();
    }

    /** Probably need to debounce this! */
    setFilterValue(filterValue: string): void {
        this.ExchangeEventStateManagement.setFilterValue(filterValue);
        this.fetchFirstPage();
    }

    setStartDate({ value }: { value: Date }): void {
        this.ExchangeEventStateManagement.setStartDate(value);
        this.fetchFirstPage();
    }

    setEndDate({ value }: { value: Date }): void {
        this.ExchangeEventStateManagement.setEndDate(value);
        this.fetchFirstPage();
    }

    handleNextPage(): void {
        const pageCommand: PageCommand = 'nextPage';
        this.fetchPage(pageCommand);
    }

    handlePreviousPage(): void {
        const pageCommand: PageCommand = 'previousPage';
        this.fetchPage(pageCommand);
    }

    fetchFirstPage(): void {
        this.ExchangeEventStateManagement.resetPagination();
        const pageCommand: PageCommand = 'nextPage';
        this.fetchPage(pageCommand);
    }

    fetchPage(pageCommand: PageCommand): void {
        this.ExchangeEventStateManagement.setIsLoading(true);

        const team = this.CurrentSession.getCurrentTeam();
        const teamId = team.id;

        if (pageCommand === 'nextPage') {
            this.ExchangeEventStateManagement.setPageNumber(
                this.ExchangeEventStateManagement.getPageNumber() + 1
            );
        }
        else if (pageCommand === 'previousPage') {
            this.ExchangeEventStateManagement.setPageNumber(
                this.ExchangeEventStateManagement.getPageNumber() - 1
            );
        }

        const params = {
            teamId,
            sort: this.ExchangeEventStateManagement.getSorts(),
            pageSize: this.ExchangeEventStateManagement.getPageSize(),
            pageNumber: this.ExchangeEventStateManagement.getPageNumber(),
            startDate: this.ExchangeEventStateManagement.getStartDate(),
            endDate: this.ExchangeEventStateManagement.getEndDate(),
            connectorIds: this.ExchangeEventStateManagement.getConnectorIds(),
            isReceiver: this.ExchangeEventStateManagement.getDocumentSharingView() === 'documentsReceived',
            ...(this.ExchangeEventStateManagement.getFilterValue()
                ? { filterValue: this.ExchangeEventStateManagement.getFilterValue() } : {}),
            ...(this.ExchangeEventStateManagement.filterType
                ? { filterType: this.ExchangeEventStateManagement.filterType } : {})
        };

        this.getExchangeEventRequest$.next(params);
        this.pageCommand$.next(pageCommand);
    }

    handleGetExchangeEvent(params) {
        const {
            items,
            hasNext
        } = params;

        this.ExchangeEventStateManagement.setExchangeEvents(items);
        this.ExchangeEventStateManagement.setHasNext(hasNext);
        this.ExchangeEventStateManagement.setIsLoading(false);
    }

    openShareDocumentsModal(): void {
        const modalRef = this.Modals.show(ShareDocumentsComponent, {
            animated: false,
            class: 'modal-lg',
            initialState: {
                modalStepsData: this.modalStepsData,
                currentTeam: this.team,
                exchangeName: this.DocumentSharingStateManagement.exchangeName$.getValue(),
                binders: this.Binders.getBinders(this.team.id, { includeArchived: false }).pipe(
                    takeUntil(this.destroy$),
                    map((binders) => sortByLexicographically(binders, 'name')),
                    catchError((error: HttpErrorResponse) => {
                        if (error.message) {
                            this.Notifications.error(error.message);
                        }
                        else {
                            this.Notifications.unexpectedError();
                        }
                        return throwError(error);
                    })
                ),
                loadItem: (params: BrowseParams): Promise<BrowseTree> => {
                    params.subTypes = [DOCUMENT_TYPES.CONTENT];

                    return this.resolveNodePermissions(params).then((hasPermission) => {
                        if (hasPermission) {
                            params.includeDocs = true;
                            params.includeArchived = false;
                            return this.Teams.browse(this.team.id, params).toPromise().then((data) => {
                                const documentShareVersion = DocumentShareVersionOptions.LATEST_VERSION;
                                const decoratedData = {
                                    ...data,
                                    ...data.items && { items: data.items.map((item) => ({ ...item, documentShareVersion })) }
                                };
                                return sortBrowseTreeLexicographically(decoratedData, 'name');
                            });
                        }

                        params.includeDocs = false;
                        params.includeArchived = false;
                        return this.Teams.browse(this.team.id, params).toPromise().then((data) => {
                            return sortBrowseTreeLexicographically(data, 'name');
                        });
                    });
                },
                onSelectionChange: ($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>) => {
                    this.DocumentSharingStateManagement.selectedDocumentsForExchange = [...$event.selectedItems] as Document[];
                    modalRef.content.selectedDocuments = this.DocumentSharingStateManagement.selectedDocumentsForExchange;
                },
                selectedDocuments: this.DocumentSharingStateManagement.selectedDocumentsForExchange,
                allTeams$: this.DocumentSharingApi.getConnections(this.team.id).pipe(
                    takeUntil(this.destroy$),
                    map((teams) => {
                        const validConnections = teams
                            .filter((conn) => !!conn.configs
                                .find((config) => config.teamId !== this.team.id && config.receivingLocation));

                        return this.sortLexicographicallyByConnectionName(validConnections);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.message) {
                            this.Notifications.error(error.message);
                        }
                        else {
                            this.Notifications.unexpectedError();
                        }
                        return throwError(error);
                    })
                ),
                selectedTeams: this.DocumentSharingStateManagement.teams$.getValue()
            }
        });

        modalRef.content.selectTeams
            .pipe(takeUntil(modalRef.content.closeModal))
            .subscribe((team) => {
                this.DocumentSharingStateManagement.selectTeams(team);
                modalRef.content.selectedTeams = this.DocumentSharingStateManagement.teams$.getValue();
            });

        modalRef.content.deselectTeams
            .pipe(takeUntil(modalRef.content.closeModal))
            .subscribe((team) => {
                this.DocumentSharingStateManagement.deselectTeams(team);
                modalRef.content.selectedTeams = this.DocumentSharingStateManagement.teams$.getValue();
            });

        modalRef.content.updateExchangeNameEvent
            .pipe(takeUntil(modalRef.content.closeModal))
            .subscribe((name) => {
                this.DocumentSharingStateManagement.changeExchangeName(name);
                modalRef.content.exchangeName = this.DocumentSharingStateManagement.exchangeName$.getValue();
            });

        modalRef.content.deselectDocumentEvent
            .pipe(takeUntil(modalRef.content.closeModal))
            .subscribe((document) => {
                this.DocumentSharingStateManagement.deselectDocument(document);
                modalRef.content.selectedDocuments = this.DocumentSharingStateManagement.selectedDocumentsForExchange;
            });

        modalRef.content.createExchangeEvent.pipe(
            takeUntil(modalRef.content.closeModal),
            switchMap(() => this.DocumentSharingApi.createExchange({
                senderTeamId: this.team.id,
                documents: this.DocumentSharingStateManagement.selectedDocumentsForExchange,
                connections: this.DocumentSharingStateManagement.teams$.getValue()
            }))
        ).subscribe(() => {
            modalRef.content.closeModal.emit(true);
            this.Notifications.success('You have successfully started the sharing process.');
        }, ({ error }: HttpErrorResponse) => {
            modalRef.content.closeModal.emit(true);
            if (error?.message) {
                this.Notifications.error(error.message);
            }
            else {
                this.Notifications.unexpectedError();
            }
        });

        modalRef.content.closeModal
            .pipe(take(1))
            .subscribe(() => {
                this.clearState();
                modalRef.hide();
            });
    }

    clearState() {
        this.DocumentSharingStateManagement.deselectTeams(this.DocumentSharingStateManagement.teams$.getValue());
        this.DocumentSharingStateManagement.selectedDocumentsForExchange = [];
        this.DocumentSharingStateManagement.exchangeName$.next('');
        this.binderPermissions.clear();
        this.folderPermissions.clear();
    }

    get modalStepsData() {
        return [
            {
                id: 1,
                title: 'Select Documents',
                description: 'You can begin by choosing which document(s) you would like to share.'
            },
            {
                id: 2,
                title: 'Select Teams',
                description: 'Next, choose which team(s) you want the documents shared with.'
            },
            {
                id: 3,
                title: 'Summary',
                description: 'The final step is to review the selection summary before clicking “Share Documents”.'
            }
        ];
    }

    sortLexicographicallyByConnectionName(connections: Connection[]) {
        return connections.sort((current, next) => {
            const currentConnectionName = this.getConnectedTeamName(current).toUpperCase();
            const nextConnectionName = this.getConnectedTeamName(next).toUpperCase();

            return currentConnectionName.localeCompare(nextConnectionName, 'en', { numeric: true }) || currentConnectionName.length - nextConnectionName.length;
        });
    }

    getConnectedTeamName(connection: Connection): string {
        return connection.configs.find((config) => config.teamId !== this.team.id)?.name;
    }

    handleOpenChooseTeamsModal(): void {
        const isReceiver = this.ExchangeEventStateManagement.getDocumentSharingView() === 'documentsReceived';
        const getActiveConnectionsParams = {
            teamId: this.team.id,
            isReceiver
        };
        this.getActiveConnectionsRequest$.next(getActiveConnectionsParams);
    }

    openChooseTeamsModal(connections: Connection[]): Observable<string[]> {
        const isReceiver = this.ExchangeEventStateManagement.getDocumentSharingView() === 'documentsReceived';
        const modalRef = this.Modals.show(ChooseTeamsComponent, {
            animated: false,
            initialState: {
                connections,
                isReceiver,
                selectedConnectionIds: this.ExchangeEventStateManagement.getConnectorIds(),
                currentTeam: this.team
            }
        });
        return modalRef.content.selectConnections
            .pipe(
                take(1)
            );
    }

    viewConnectedTeams(): void {
        this.$state.go('app.team.connected-teams');
    }

    resolveNodePermissions(object: BrowseParams) {
        if (this.team.permissions.documentSharingShareDocuments) {
            return Promise.resolve(true);
        }

        switch (object.objectType) {
            case 'binder': {
                if (this.binderPermissions.has(object.objectId)) {
                    return Promise.resolve(this.binderPermissions.get(object.objectId));
                }
                return this.Teams.getUserObjectPermissions(object.objectId, 'binders')
                    .toPromise()
                    .then((permissions) => {
                        this.binderPermissions.set(object.objectId, permissions.documentSharingShareDocuments);
                        return this.binderPermissions.get(object.objectId);
                    }) as Promise<BrowseTree>;
            }
            case 'folder': {
                if (this.binderPermissions.has(object.binderId) && this.binderPermissions.get(object.binderId)) {
                    return Promise.resolve(true);
                }
                const existingLineageChecks = object.lineage
                    .filter((ancestorId) => this.folderPermissions.has(ancestorId));

                if (existingLineageChecks.some((ancestorId) => ancestorId)) {
                    return Promise.resolve(true);
                }

                return this.Teams.getUserObjectPermissions(object.objectId, 'folders')

                    .toPromise()
                    .then((permissions) => {
                        this.folderPermissions.set(object.objectId, permissions.documentSharingShareDocuments);
                        return permissions.documentSharingShareDocuments;
                    }) as Promise<BrowseTree>;
            }
            default:
                return Promise.resolve(false);
        }
    }
}
