import {
    Component, Input, Output, EventEmitter, OnInit, OnDestroy
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import {
    BrowseNode, BrowseTree, Document, Team
} from '@app/shared/models';
import {
    forkJoin,
    from,
    Observable,
    of,
    Subject
} from 'rxjs';
import { DocumentService } from '@app/shared/documents/document.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { QCReviewService } from '@app/components/documents/qc-review.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { ModalHelperService } from '@app/shared/modal-helper/modal-helper.service';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { SetPiiResponse } from '@app/shared/documents/documents.service.types';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { ModalRef, ModalsService } from '@app/shared/modal-helper/modals.service';
import { TimelineAssignComponent } from '@app/widgets/timeline-assign/timeline-assign.component';
import { TagsAssignComponent } from '@app/widgets/tags-assign/tags-assign.component';
import {
    catchError, filter, switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { mapTimelinesToDocumentProjects } from '@app/shared/documents/map-timelines-to-document-projects.util';

import { DocumentRequestQCReviewComponent } from '@app/components/qc-reviews/components/document-request-qc-review/document-request-qc-review.component';
import { MoveComponent } from '@app/components/folders/components/move/move.component';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { TogglePiiComponent } from '@app/widgets/documents/toggle-pii/toggle-pii.component';
import { HttpErrorResponse } from '@angular/common/http';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { TeamService } from '@app/shared/teams/team.service';
import { DocumentsSignatureRequestsComponent } from '@app/components/signature-requests/containers/documents-signature-requests/documents-signature-requests.component';
import { LogTemplateType } from '@app/components/log-templates/components/log-template-type-selector/log-template-type-selector.component.types';
import { ALLOWED_PREVIEW_FILE_TYPES } from '@florencehealthcare/florence-constants/lib/documents';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import template from './document-actions.component.html';
import styles from './document-actions.component.scss';
import { DestroyDocumentComponent } from '../destroy-document/destroy-document.component';
import { DocumentDestroyEvent } from '../destroy-document/destroy-document.component.types';
import { DocumentLockingModalComponent } from '../document-locking-modal/document-locking-modal.component';
import { LogImportRowsComponent } from '../log-bulk-import-rows/log-import-rows/log-import-rows.component';
import { QcReview, QcReviewRequestParam } from '../../qc-review.services.types';
import { DocumentApprovalComponent } from '../document-approval/document-approval.component';

@Component({
    selector: 'document-actions',
    template,
    styles: [String(styles)]
})
export class DocumentActionsComponent implements OnInit, OnDestroy {
    @Input() doc: Document;
    @Input() noDocumentPreview: boolean;
    @Output() requestDownload = new EventEmitter<void>();
    @Output() reject = new EventEmitter<void>();
    @Output() openTask = new EventEmitter<void>();
    @Output() updateDetails = new EventEmitter<void>();
    @Output() update = new EventEmitter<void>();
    @Output() updateLegend = new EventEmitter<void>();
    @Output() updateInformation = new EventEmitter<void>();
    @Output() updateDoaLogRolesResponsibilitites = new EventEmitter<void>();
    @Output() qcLogCreated = new EventEmitter<void>();

    currentTeam: Team;
    isBrokenShortcut: boolean;
    isDocumentLocked: boolean;
    showMove: boolean;
    canApprove: boolean;
    canReject: boolean;
    isShortcut: boolean;
    isLog: boolean;
    canMove: boolean;
    canDuplicate: boolean;
    canDownload: boolean;
    canCreateTask: boolean;
    canTogglePii: boolean;
    canUpdateLog: boolean;
    canBulkImportRows: boolean;
    approvalTooltip: string;
    cancelledOrRejected: boolean;
    canRequestQCReview: boolean;
    canManageQcReview: boolean;
    hasQcReview: boolean
    importRowsModalRef: ModalRef<LogImportRowsComponent>;
    isDoaLog = false;
    isDoaLogTemplatesFeatureFlagEnabled = false;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private Teams: TeamService,
        private Binders: BindersService,
        private CurrentSession: CurrentSessionService,
        private modalHelper: ModalHelperService,
        private Modals: ModalsService,
        private $state: StateService,
        private ApiError: ApiErrorFactory,
        private Notifications: NotificationsService,
        private Downloads: DownloadsService,
        private QCReview: QCReviewService,
        private Documents: DocumentService,
        private FeatureFlags: FeatureFlagService
    ) { }

    ngOnInit(): void {
        this.isDoaLog = this.doc.documentProperties?.templateType === LogTemplateType.DOA;
        this.isBrokenShortcut = this.doc.isBrokenShortcut;
        this.isDocumentLocked = this.doc.isLocked;
        this.showMove = this.shouldDisplayMove();
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.approvalTooltip = this.getApprovalTooltip();
        this.cancelledOrRejected = this.checkCancelledOrRejected();

        this.isShortcut = this.doc.subType === 'shortcut';
        this.isLog = this.doc.subType === 'log';
        this.canApprove = this.doc.permissions.approveDocument && this.isPendingApproval() && !this.isDocumentLocked;
        this.canReject = this.doc.permissions.rejectDocument && this.getApprovalStatus() === 'pending' && !this.isDocumentLocked;

        this.canMove = this.doc.isLatestVersion && this.doc.permissions.duplicateDocument && !this.isShortcut;
        this.canDuplicate = this.doc.isLatestVersion && this.doc.permissions.duplicateDocument && !this.isShortcut && !this.isLog;
        this.canCreateTask = this.doc.isLatestVersion && this.doc.permissions.manageDocTasks && !this.isLog;

        this.canTogglePii = (this.doc.subType === 'content' || this.doc.subType === 'log') && this.doc.permissions.documentMarkUnmarkPhi && this.doc.isLatestVersion;
        this.canUpdateLog = this.isLog && this.doc.isLatestVersion && this.doc.permissions.updateLogDetails;
        this.canDownload = this.getCanDownload();
        this.canBulkImportRows = this.isLog && this.doc.isLatestVersion && this.doc.permissions.createLogEntry;

        this.canRequestQCReview = (this.doc.permissions.documentCreateEditQCReview
            || this.doc.permissions.manageQCreview
            || this.doc.permissions.qcReviewAdministrator)
                                    && this.doc.qcReview === undefined
                                    && this.canQcReviewDocBySubtypeAndStatus()
                                    && (this.doc.documentProperties?.approval?.status !== 'rejected');
        this.canManageQcReview = this.doc.permissions.qcReviewAdministrator
                                || (this.doc.permissions.documentCreateEditQCReview && this.isUserQcRequestor());
        this.hasQcReview = !!this.doc.qcReview;

        this.FeatureFlags.getFlag(FEATURE_FLAGS.DOA_LOG_TEMPLATES, true)
            .pipe(
                filter((value) => value !== undefined),
                takeUntil(this.destroy$)
            )
            .subscribe((value) => {
                this.isDoaLogTemplatesFeatureFlagEnabled = !!value;
            });
    }

    ngOnDestroy(): void {
        if (this.importRowsModalRef) {
            this.importRowsModalRef.hide();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    getCanDownload(): boolean {
        const isDownloadable = this.doc.isDownloadable && !this.isBrokenShortcut;
        if (this.doc.hasPii) {
            return isDownloadable && this.doc.permissions.downloadDocumentWithPii;
        }
        return isDownloadable;
    }

    isUserQcRequestor(): boolean {
        return this.doc.qcReview?.requestorId.id === this.CurrentSession.getCurrentUser().id;
    }

    canRequestSignatures(): boolean {
        return this.doc
            && !this.noDocumentPreview
            && this.doc.permissions
            && this.doc.permissions.requestSignature
            && !this.isBrokenShortcut
            && !this.cancelledOrRejected;
    }

    canQcReviewDocBySubtypeAndStatus() {

        switch (this.doc.subType) {
            case 'content':
                if (this.doc.formStatus !== 'no-form' && this.doc.formStatus !== 'form-finalized') {
                    return false;
                }

                return ALLOWED_PREVIEW_FILE_TYPES.includes(this.doc.ext?.toLowerCase());
            case 'log':
                return true;
            case 'shortcut':
                return false;
            case 'placeholder':
                return false;
            default:
                return false;
        }
    }

    private getApprovalTooltip(): string {
        if (this.doc.hasPendingSignatures && this.doc.formStatus === 'form-in-progress') {
            return 'Action disabled due to pending signatures and an incomplete form';
        }
        if (this.doc.hasPendingSignatures) {
            return 'Action disabled due to pending signatures';
        }
        if (this.doc.formStatus === 'form-in-progress') {
            return 'Action disabled due to an incomplete form';
        }
    }

    requestQcReview() {
        const docVersion = this.doc.version;
        const userId = this.CurrentSession.getCurrentUser().id;
        const entity = this.doc;

        const modalRef = this.Modals.show(DocumentRequestQCReviewComponent, {
            initialState: {
                entity,
                docVersion,
                userId,
                currentTeam: this.currentTeam
            }
        });

        const requestParams = {
            documentId: this.doc.id as string,
            documentVersion: docVersion,
            teamId: this.currentTeam.id,
            requestorId: this.CurrentSession.getCurrentUser().id

        };

        modalRef.content.save.pipe(
            takeUntil(this.destroy$),
            switchMap((resp) => this.createQCReview({ ...requestParams, reviews: resp.qcReview }))
        ).subscribe((qcReview) => {
            if (qcReview === null) {
                modalRef.content.isProcessing = false;
                return;
            }
            this.hasQcReview = !!qcReview;
            modalRef.hide();
        });
    }

    private createQCReview(params: QcReviewRequestParam): Observable<QcReview | null> {
        return this.QCReview.requestQCReview(params)
            .pipe(
                tap((qcReview) => {
                    const successMessage = `QC Review for version ${qcReview.documentVersion} successfully created`;
                    this.Notifications.success(successMessage);
                    this.doc.subType === 'log' ? this.qcLogCreated.emit() : this.update.emit();
                    this.canManageQcReview = this.doc.permissions.qcReviewAdministrator
                                || this.doc.permissions.documentCreateEditQCReview;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    return of(null);
                })
            );
    }

    private getApprovalStatus(): string {
        return this.doc
            && this.doc.documentProperties
            && this.doc.documentProperties.approval
            && this.doc.documentProperties.approval.status;
    }

    private isPendingApproval(): boolean {
        return this.getApprovalStatus() === 'pending';
    }

    checkCancelledOrRejected(): boolean {
        const currentStatus = this.getApprovalStatus();
        return currentStatus === 'cancelled' || currentStatus === 'rejected';
    }

    assignTags(): void {
        const entity = this.doc;

        const modalRef = this.Modals.show(TagsAssignComponent, {
            initialState: {
                currentTags: entity.tags,
                entity
            }
        });

        modalRef.content.save.subscribe(async ({ tagsToAssign, onSuccess, onError }): Promise<void> => {
            const tagIds = tagsToAssign.map((tag) => tag.id);
            modalRef.content.isProcessing = true;
            from(this.Teams.assignTags(entity.id.toString(), entity.type, tagIds))
                .subscribe(() => {
                    onSuccess();
                    this.doc.tags = tagsToAssign;
                }, onError);
        });
    }

    openAssignToTimelinesModal(): void {
        if (this.currentTeam.permissions.assignToTimelines) {
            const modalRef = this.Modals.show(TimelineAssignComponent, {
                initialState: { document: this.doc }
            });

            modalRef.content.documentTimelinesUpdated
                .pipe(take(1))
                .subscribe((timelines) => {
                    this.doc.timelinesCount = timelines.length;
                    this.doc.projects = mapTimelinesToDocumentProjects(timelines);
                });
        }
    }

    async deleteDocument() {
        const hasOriginalDocuments = await this.Teams.hasOriginalDocuments(this.currentTeam.id, {
            objectType: 'documents',
            objectIds: [this.doc.id.toString()]
        }).toPromise();

        const modal = this.Modals.show(DestroyDocumentComponent, {
            initialState: {
                documents: [this.doc],
                hasOriginalDocuments
            }
        });

        modal.content.destroy.pipe(
            switchMap(({ data: { documents, reason } }: DocumentDestroyEvent) => {
                return forkJoin({
                    documentsDeleted: this.Documents.destroy(documents, reason),
                    removedDocuments: of(documents)
                });
            })
        ).subscribe(({
            removedDocuments
        }) => {
            modal.hide();
            this.$state.go('app.team.folder', { teamId: removedDocuments[0].teamId, binderId: removedDocuments[0].binderId, folderId: removedDocuments[0].folderId });
        },
        ({ error }) => {
            if (error) {
                const message = error.message || 'There was an unexpected error. Please contact your administrator.';
                this.Notifications.error(message);
            }
            modal.hide();
        });
    }

    togglePii(hasPii: boolean): void {
        const modalRef = this.Modals.show(TogglePiiComponent, {
            initialState: {
                documents: [this.doc],
                setPii: hasPii
            }
        });

        modalRef.content.onPiiUpdate.subscribe((setPiiResponse: SetPiiResponse) => {
            this.onPiiUpdate(setPiiResponse);
        });
    }

    onPiiUpdate = (setPiiResponse: SetPiiResponse): void => {
        this.doc.hasPii = setPiiResponse.documents[0].hasPii;
    }

    handleRequestDownload(): void {
        this.requestDownload.emit();
    }

    handleOpenCreateTask(): void {
        this.openTask.emit();
    }

    handleEditDetails(): void {
        this.updateDetails.emit();
    }

    handleEditLegend(): void {
        this.updateLegend.emit();
    }

    handleEditInformation(): void {
        this.updateInformation.emit();
    }

    handleEditRolesAndResponsibilitites(): void {
        this.updateDoaLogRolesResponsibilitites.emit();
    }

    canEditRolesAndResponsibilitities(): boolean {
        return this.canUpdateLog && !this.isDocumentLocked && this.isDoaLog && this.isDoaLogTemplatesFeatureFlagEnabled;
    }

    move(): void {
        const modalRef = this.Modals.show(MoveComponent, {
            class: 'modal-lg',
            initialState: {
                parentEntity: { binderName: this.doc.binderName } as BrowseTree,
                items: [this.doc] as BrowseNode[],
                loadRoot: () => this.Binders
                    .getBinders(this.currentTeam.id, { includeArchived: false })
                    .toPromise()
                    .then((binders) => sortByLexicographically(binders, 'name'))
                    .catch(this.ApiError.handleError),
                loadItem: (params = {}) => this.Teams.browse(
                    this.currentTeam.id, { ...params, includeArchived: false } as BrowseParams
                )
                    .toPromise().then((data) => sortBrowseTreeLexicographically(data, 'name'))
            }
        });

        modalRef.content.move.subscribe((params) => {
            this.Documents.moveTo(params.selectedFolder, [this.doc])
                .toPromise()
                .then(() => {
                    modalRef.hide();
                    // in case the current view needs to update with newly moved documents
                    this.$state.go(this.$state.current, {}, { reload: true });
                }, () => {
                    modalRef.hide();
                });
        });
    }

    duplicate(): void {
        this.modalHelper.open({
            animation: false,
            size: 'lg',
            component: 'duplicateModalWrapper',
            resolve: {
                items: (): Document[] => [this.doc]
            }
        });
    }

    requestSignatures(): void {
        const requestsSignatureModal = this.Modals.show(DocumentsSignatureRequestsComponent, {
            class: 'modal-lg',
            keyboard: false,
            initialState: {
                initiallySelectedDocuments: [this.doc]
            }
        });

        requestsSignatureModal.content.dismiss.subscribe(() => {
            requestsSignatureModal.hide();
        });
    }

    viewManageQcReview(): void {
        this.$state.go('app.team.view-manage-qc-review', { teamId: this.doc.teamId, documentId: this.doc.id, version: this.doc.version });
    }

    importRows(): void {
        const modalRef = this.Modals.show(LogImportRowsComponent, {
            class: 'modal-lg',
            initialState: {
                teamId: this.currentTeam.id,
                documentId: this.doc.id as string,
                documentTemplateType: this.doc.documentProperties.templateType
            }
        });

        this.importRowsModalRef = modalRef;
        modalRef.content.downloadExcelFile
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.Downloads.downloadLogImportedRowsTemplate({ logId: this.doc.id as string, teamId: this.currentTeam.id })
                    .subscribe(
                        () => {
                            const href = this.$state.href('app.team.downloads', { teamId: this.currentTeam.id });
                            const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                                    Go to <a class="page-action u-d-inline" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                            this.Notifications.info({ message, closeOnClick: false });
                        }, (error: HttpErrorResponse) => {
                            if (error.error && error.error.message) {
                                this.Notifications.error(error.error.message);
                            }
                            else {
                                this.Notifications.unexpectedError();
                            }
                        }
                    );
            });
        modalRef.content.rowsImported
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.$state.reload();
            });

    }

    approveDocument(): void {
        const approveDocForm = this.Modals.show(DocumentApprovalComponent, {
            class: 'modal-md',
            initialState: {
                action: 'approve',
                entity: this.doc
            }
        });

        approveDocForm.content.submit.subscribe((event) => {
            this.Documents.approve(event.data.entity)
                .toPromise()
                .then(() => {
                    approveDocForm.hide();
                    this.$state.go(this.$state.current, {}, { reload: true });
                });
        });
    }

    rejectDocument(): void {

        const rejectDocForm = this.Modals.show(DocumentApprovalComponent, {
            class: 'modal-md',
            initialState: {
                action: 'reject',
                entity: this.doc
            }
        });

        rejectDocForm.content.submit.subscribe((event) => {
            this.Documents.reject(event.data.entity, event.data.reason)
                .toPromise()
                .then(() => {
                    this.reject.emit();
                    this.checkCancelledOrRejected();
                    rejectDocForm.hide();
                    this.$state.go(this.$state.current, {}, { reload: true });
                });
        });
    }

    toggleLockDoc(isLocking: boolean): void {
        const modalRef = this.Modals.show(DocumentLockingModalComponent, {
            initialState: {
                isLocking,
                documentCount: 1,
                isShortcutSelected: this.isShortcut
            }
        });

        modalRef.content.confirm
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {

                const params = {
                    id: this.doc.id,
                    isLocked: isLocking
                };
                this.Documents.updateDocumentIsLocked([params])
                    .subscribe(
                        () => {
                            this.$state.go(this.$state.current, {}, { reload: true });
                            const toastMessage = `Document(s) successfully ${isLocking ? 'locked' : 'unlocked'}`;
                            this.Notifications.success(toastMessage);
                            modalRef.hide();
                        },
                        (error: HttpErrorResponse) => {
                            const message = error.error.message || 'There was an unexpected error.  Please contact your administrator.';
                            this.Notifications.error(message);
                            modalRef.hide();
                        }
                    );
            });
    }

    isApprovable(): boolean {

        return !this.doc.hasPendingSignatures && this.doc.formStatus !== 'form-in-progress';
    }

    shouldDisplayMove(): boolean {
        if (!this.doc.isLatestVersion) {
            return false;
        }
        if (this.isDocumentLocked) {
            return !!(this.doc.permissions.moveLockedDocument
                || (this.currentTeam && this.currentTeam.permissions.moveLockedDocument));
        }
        return this.doc.permissions.moveDocument;
    }
}
