import controller from './marketing-notifications.controller';

const marketingNotificationsComponent = {
    template: '',
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default marketingNotificationsComponent;
