import * as angular from 'angular';

import UibTabHrefDirective from './uib-tab-href.directive';

const dependencies = [];

const UibTabHref = angular
    .module('app.widgets.uibTabHref', dependencies)
    .directive('uibTabHref', UibTabHrefDirective);

export default UibTabHref;
