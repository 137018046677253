import * as moment from 'moment-timezone';
import { Transition } from '@uirouter/angularjs';
import * as _ from 'lodash';
import {
    DatePickerButtonBar,
    DatePickerOptions,
    DisableDateParams,
    DATE_PICKER_DEFAULT_BUTTON_BAR,
    DATE_PICKER_DEFAULT_OPTIONS
} from './date-time-picker-options';

class DateTimePickerController {
    open = false;
    MAX_DATE = new Date('9999-09-09T00:00:00.000Z');
    pickerDateFormat: string;
    datePickerForm: ng.IFormController;
    // bindings
    date: string | Date;
    minDate: string | Date;
    maxDate: string | Date;
    dateToDisplay: string;
    includeTime: boolean;
    allowPast: boolean;
    allowZero: boolean;
    timezone: string;
    isDisabled: boolean;
    isInvalid: boolean;
    isWarning: boolean;
    clearOption = true;
    onSelect: ({ value: string }) => void;
    onInvalid: () => void;
    placeholderText: string;
    datePickerOptions: DatePickerOptions;
    buttonBar: DatePickerButtonBar;
    isLongTermArchive: boolean;

    constructor(
        $transitions: Transition
    ) {
        const deregister = $transitions.onExit({}, () => {
            this.open = false;
            deregister();
        });
        this.datePickerOptions = _.cloneDeep(DATE_PICKER_DEFAULT_OPTIONS);
        this.buttonBar = _.cloneDeep(DATE_PICKER_DEFAULT_BUTTON_BAR);
    }

    $onChanges(): void {
        this.timezone = this.timezone || '';
        if (!this.clearOption) {
            this.buttonBar.clear.show = false;
        }
        if (this.date && typeof this.date === 'string') {
            this.date = new Date(this.date);
        }
        if ((this.date && new Date(this.date).getTime() === this.MAX_DATE.getTime())
            || (!this.allowZero && new Date(this.date).getTime() === new Date(0).getTime())) {
            // allow zero indicates if 0 dates should show as filled
            // otherwise, passing zero means no date should show
            this.date = '';
        }
        this.handleDateDisabled();
        this.datePickerOptions.enableTime = !!this.includeTime;
        this.pickerDateFormat = this.includeTime ? 'dd-MMM-yyyy @ hh:mm a' : 'dd-MMM-yyyy';
        this.dateToDisplay = this.formatDate();
    }

    isDateBeforeMin(mode: string, min: Date, date: Date): boolean {

        let isBeforeMin = false;
        switch (mode) {
            case 'day':
                isBeforeMin = (min.getTime() - date.getTime()) > 1;
                break;
            case 'month':
                isBeforeMin = (date.getTime() + (moment(this.minDate).date() * 24 * 60 * 60 * 1000)) < min.getTime();
                break;
            case 'year':
                isBeforeMin = min.getFullYear() > date.getFullYear();
                break;
            default:
                break;
        }

        return isBeforeMin;
    }

    handleDateDisabled(): void {
        // Only min date is covered by moment js as it will be used (for now) only by the archive-form component
        if (this.isLongTermArchive && this.minDate && this.timezone) {
            this.datePickerOptions.dateDisabled = (params: DisableDateParams): boolean => {
                const { date } = params;
                const calendarDate = moment(date).tz(this.timezone, true).set({
                    hour: 0,
                    minute: 0,
                    second: 0
                });

                const min = moment.tz(this.minDate, this.timezone).set({
                    hour: 0,
                    minute: 0,
                    second: 0
                });

                return !calendarDate.isSameOrAfter(min);
            };
            return;
        }

        if (this.minDate && this.maxDate) {
            this.datePickerOptions.dateDisabled = (params: DisableDateParams): boolean => {
                const { date } = params;
                const min = new Date(this.minDate);
                min.setHours(0, 0, 0, 1);
                const max = new Date(this.maxDate);
                max.setHours(23, 59, 59, 999);
                const isBeforeMin = this.isDateBeforeMin(params.mode, min, date);
                const isAfterMax = date.getTime() > max.getTime();
                return isBeforeMin || isAfterMax || this.isSelectedDate(params);
            };
            return;
        }
        if (this.minDate) {
            this.datePickerOptions.dateDisabled = (params: DisableDateParams): boolean => {
                const { date } = params;
                const min = new Date(this.minDate);
                min.setHours(0, 0, 0, 0);
                const isBeforeMin = this.isDateBeforeMin(params.mode, min, date);
                return isBeforeMin || this.isSelectedDate(params);
            };
            return;
        }
        if (this.maxDate) {
            this.datePickerOptions.dateDisabled = (params: DisableDateParams): boolean => {
                const { date } = params;
                const max = new Date(this.maxDate);
                max.setHours(23, 59, 59, 999);
                const isAfterMax = date.getTime() > max.getTime();
                return isAfterMax || this.isSelectedDate(params);
            };
            return;
        }
        this.datePickerOptions.dateDisabled = this.isSelectedDate;
    }

    hasError(): boolean {
        const isError = this.isInvalid || (this.datePickerForm.$dirty && this.hasPastError());
        if (isError && this.onInvalid) {
            this.onInvalid();
        }
        return isError;
    }

    hasPastError(): boolean {
        const isPastError = this.date && !this.allowPast && (this.date < new Date());
        if (isPastError && this.onInvalid) {
            this.onInvalid();
        }
        return isPastError;
    }

    openCalendar(event): void {
        if (event) {
            event.target.focus({ preventScroll: true });
        }
        this.open = true;
    }

    formatDate(): string {
        if (!this.date) {
            return null;
        }
        if (this.includeTime) {
            if (this.timezone) {
                return `${moment.tz(this.date, this.timezone).format('DD-MMM-YYYY @ hh:mm A z')}`;
            }
            return moment(this.date).format('DD-MMM-YYYY @ hh:mm A');
        }
        return moment(this.date).format('DD-MMM-YYYY');
    }

    handleChange(): void {
        if (!this.onSelect) {
            return;
        }

        this.onSelect({ value: this.date });
        this.dateToDisplay = this.formatDate();
    }

    isSelectedDate = (params: DisableDateParams): boolean => {
        const { date, mode } = params;

        return this.date && new Date(this.date).toDateString() === date.toDateString() && mode === 'day';
    }
}

DateTimePickerController.$inject = [
    '$transitions'
];

export default DateTimePickerController;
