import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    Study, Site, StudyEntity, StudyWithTeamSipIntegrationLink, SiteWithTeamSipIntegrationLink, Paywall
} from '@app/shared/models';

import {
    GetStudiesResponse, GetStudiesParams, BulkSiteActionResponse,
    UpdateLinkedEntitiesParams, UpdateLinkedEntitiesResponse, UpdateMonitorGroupParams
} from './studies.service.types';

@Injectable()
export class StudiesService {
    readonly url = {
        studies: (teamId: string): string => `/api/teams/${teamId}/studies`,
        documentStudies: (teamId: string, documentId: string): string => `/api/teams/${teamId}/documents/${documentId}/study-profile`,
        study: (teamId: string, studyId: string): string => `/api/teams/${teamId}/studies/${studyId}`,
        studySites: (teamId: string, studyId: string): string => `/api/teams/${teamId}/studies/${studyId}/sites`,
        studySitesV2: (teamId: string, studyId: string): string => `/api/v2/teams/${teamId}/studies/${studyId}/sites`,
        studyLinkedEntities: (teamId: string, studyId: string): string => `/api/teams/${teamId}/studies/${studyId}/linked-entities`,
        siteLinkedEntities: (teamId: string, studyId: string, siteId: string): string => `/api/teams/${teamId}/studies/${studyId}/sites/${siteId}/linked-entities`,
        studyPaywalls: (teamId: string, studyId: string): string => `/api/teams/${teamId}/studies/${studyId}/paywalls`,
        paywall: (teamId: string, paywallId: string): string => `/api/teams/${teamId}/paywalls/${paywallId}`,
        paywalls: (teamId: string): string => `/api/teams/${teamId}/paywalls`,
        paywallsDomains: (teamId: string): string => `/api/teams/${teamId}/paywalls-domains`
    }

    constructor(private http: HttpClient, @Inject('Window') private window: Window) {}

    getStudies(teamId: string, paginationParams?: GetStudiesParams): Observable<GetStudiesResponse> {
        const params = paginationParams
            && Object.keys(paginationParams).reduce((httpParam, param) => {
                return httpParam.set(param, paginationParams[param]);
            }, new HttpParams());
        return this.http.get<GetStudiesResponse>(this.url.studies(teamId), { params });
    }

    getDocumentStudyProfile(teamId: string, documentId: string): Observable<StudyWithTeamSipIntegrationLink[]> {
        return this.http.get<StudyWithTeamSipIntegrationLink[]>(this.url.documentStudies(teamId, documentId));
    }

    getStudy(teamId: string, studyId: string): Observable<StudyWithTeamSipIntegrationLink> {
        return this.http.get<StudyWithTeamSipIntegrationLink>(this.url.study(teamId, studyId));
    }

    createStudy(teamId: string, study: Study): Observable<Study> {
        return this.http.post<Study>(this.url.studies(teamId), study);
    }

    updateStudy(teamId: string, study: Study): Observable<Study> {
        return this.http.patch<Study>(this.url.study(teamId, study.id), study);
    }

    getStudySites(teamId: string, studyId: string): Observable<SiteWithTeamSipIntegrationLink[]> {
        return this.http.get<SiteWithTeamSipIntegrationLink[]>(this.url.studySites(teamId, studyId));
    }

    createStudySites(teamId: string, studyId: string, sites: Site[], shouldUseV2: boolean): Observable<BulkSiteActionResponse[]> {
        const url = shouldUseV2 ? this.url.studySitesV2 : this.url.studySites;
        return this.http.post<BulkSiteActionResponse[]>(url(teamId, studyId), sites);
    }

    updateStudySites(teamId: string, studyId: string, sites: Site[], shouldUseV2: boolean): Observable<BulkSiteActionResponse[]> {
        const url = shouldUseV2 ? this.url.studySitesV2 : this.url.studySites;
        return this.http.patch<BulkSiteActionResponse[]>(url(teamId, studyId), sites);
    }

    getStudyLinkedEntities(teamId: string, studyId: string): Observable<StudyEntity[]> {
        return this.http.get<StudyEntity[]>(this.url.studyLinkedEntities(teamId, studyId));
    }

    updateStudyLinkedEntities(
        teamId: string,
        studyId: string,
        updates: UpdateLinkedEntitiesParams
    ): Observable<UpdateLinkedEntitiesResponse> {
        return this.http.patch<UpdateLinkedEntitiesResponse>(this.url.studyLinkedEntities(teamId, studyId), updates);
    }

    getSiteLinkedEntities(teamId: string, studyId: string, siteId: string): Observable<StudyEntity[]> {
        return this.http.get<StudyEntity[]>(this.url.siteLinkedEntities(teamId, studyId, siteId));
    }

    updateSiteLinkedEntities(
        teamId: string,
        studyId: string,
        siteId: string,
        updates: UpdateLinkedEntitiesParams
    ): Observable<UpdateLinkedEntitiesResponse> {
        return this.http.patch<UpdateLinkedEntitiesResponse>(this.url.siteLinkedEntities(teamId, studyId, siteId), updates);
    }

    getTeamMonitorGroups(teamId: string): Observable<Paywall[]> {
        return this.http.get<Paywall[]>(this.url.paywalls(teamId));
    }

    getTeamMonitorGroupsDomains(teamId: string, filter?: string): Observable<string[]> {
        return this.http.get<string[]>(this.url.paywallsDomains(teamId), { params: { ...filter && { filter } } });
    }

    getMonitorGroup(teamId: string, paywallId: string): Observable<Paywall> {
        return this.http.get<Paywall>(this.url.paywall(teamId, paywallId));
    }

    createMonitorGroup(teamId: string, paywall: Paywall): Observable<Paywall> {
        return this.http.post<Paywall>(this.url.paywalls(teamId), paywall);
    }

    updateMonitorGroup(
        teamId: string,
        paywallId: string,
        update: UpdateMonitorGroupParams
    ): Observable<Paywall> {
        return this.http.patch<Paywall>(this.url.paywall(teamId, paywallId), update);
    }

    getStudyMonitorGroups(teamId: string, studyId: string): Observable<Paywall[]> {
        return this.http.get<Paywall[]>(this.url.studyPaywalls(teamId, studyId));
    }

    updateMonitorGroupStudies({
        teamId,
        studyId,
        update
    }): Observable<Paywall[]> {
        return this.http.patch<Paywall[]>(this.url.studyPaywalls(teamId, studyId), update);
    }
}
