import * as angular from 'angular';
import 'ng-idle';

const idleConfig = (IdleProvider, KeepaliveProvider) => {
    // 15 minutes till kicked
    const minutesUntilWarning = 13;
    const minutesBeingWarned = 2;

    // THIS VALUE DETERMINES HOW QUICKLY A LOGGED IN USER CAN BE BOOTED. IT
    // SHOULD NOT BE LENGTHENED
    const minutesForHeartbeatInterval = 3;

    const secondsUntilWarning = minutesUntilWarning * 60;
    const secondsBeingWarned = minutesBeingWarned * 60;
    const secondsForHeartbeatInterval = minutesForHeartbeatInterval * 60;

    IdleProvider.idle(secondsUntilWarning);
    IdleProvider.timeout(secondsBeingWarned);
    KeepaliveProvider.interval(secondsForHeartbeatInterval);
};

idleConfig.$inject = ['IdleProvider', 'KeepaliveProvider'];

const idleRunner = ($rootScope, $state, $window, Idle, sessions, cookies, currentSession) => {
    window.$state = $state;
    Idle.watch();

    $rootScope.$on('Keepalive', () => {
        console.log('Keepalive');
        sessions.heartbeat();
    });

    $rootScope.$on('IdleStart', () => {
        console.log('IdleStart');
        sessions.openInactivityWarning();
    });

    $rootScope.$on('IdleTimeout', () => {
        console.log('IdleTimeout');
        sessions.destroy({ sessionExpired: true });
    });

    $rootScope.$on('IdleInterrupt', () => {
        if (!cookies.checkIfAuth0CookieExists()) {
            sessions.destroy();
        }
    });

    currentSession.currentTeam$.subscribe((team) => {
        if (team && team.settings.inactivityTimeout) {
            const minutesUntilWarning = team.settings.inactivityTimeout.valueOf() - 2;

            const secondsUntilWarning = minutesUntilWarning * 60;

            Idle.setIdle(secondsUntilWarning);
        }
    });
};
idleRunner.$inject = ['$rootScope', '$state', '$window', 'Idle', 'SessionsService', 'CookiesService', 'CurrentSession'];

const inactivityLogoffModule = angular
    .module('inactivityLogoff', ['ngIdle'])
    .config(idleConfig)
    .run(idleRunner);

export default inactivityLogoffModule;
