import controller from './global-view-select-root.controller';
import template from './global-view-select-root.html';
import './global-view-select-root.scss';

export default {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
};
