import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';

class RootSelectController {
    SelectionMode = VirtualTreeSelectionMode;

    $onInit(): void {
        this.onSubmit = this.resolve.onSubmit;
        this.loadRoot = this.resolve.loadRoot;
        this.loadItem = this.resolve.loadItem;

        this.loadingRoot = true;
        this.loadRoot().then((items) => {
            this.items = items;
            this.loadingRoot = false;
            this.loadedRoot = true;
        });
    }


    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    onItemClicked = (item): void => {
        this.onSubmit(item);
    }
}

RootSelectController.$inject = [];

export default RootSelectController;
