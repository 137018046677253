import { NgModule, Provider } from '@angular/core';
import MarketingNotificationsModalService from './marketing-notifications-modal.service';
import { MarketingNotificationsService } from './marketing-notifications.service';

const upgradeProvider = (provide, injectorName: string): Provider => ({
    provide,
    useFactory: ($injector) => $injector.get(injectorName),
    deps: ['$injector']
});
@NgModule({
    imports: [],
    providers: [
        MarketingNotificationsService,
        upgradeProvider(MarketingNotificationsModalService, 'MarketingNotificationsModal')
    ],
    declarations: [],
    entryComponents: [],
    exports: []
})
export class MarketingNotificationsModule {}
