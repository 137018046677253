const MonitorGroupsListModalWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
        <monitor-groups-list-modal
            [study-name]="vm.resolve.studyName"
            [study-id]="vm.resolve.studyId"
            [team-id]="vm.resolve.teamId"
            (dismiss)="vm.modalInstance.close('cancel')"
            (on-submit)="vm.resolve.onSubmit($event)">
        </monitor-groups-list-modal>
    `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default MonitorGroupsListModalWrapperComponent;
