import template from './log-entry-signature-requests-modal-wrapper.html';
import { LogEntrySignatureRequestsModalWrapperController as controller } from './log-entry-signature-requests-modal-wrapper.controller';

export const LogEntrySignatureRequestsModalWrapperComponent = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};
