import {
    Component,
    Input,
    Output,
    OnInit,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    ChangeDetectionStrategy
} from '@angular/core';

import { CHECKBOX_STATES } from '@app/core/constants';
import { User } from '@app/shared/models';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';

import template from './documents-signers-tab.component.html';
import styles from './documents-signers-tab.component.scss';
import { DocumentsSignersTabDataRow } from './documents-signers-tab.types';
@Component({
    selector: 'documents-signers-tab',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: '$scope', useExisting: '$rootScope' }] // date-time-picker workaround
})
export class DocumentsSignersTabComponent implements OnInit, OnChanges {
    @Input() data: DocumentsSignersTabDataRow[] = [];
    @Input() loadingPotentialSigners = false;
    @Input() potentialSigners: User[] = [];
    @Input() selectedSigners: User[] = [];

    @Output() signersFilterChange = new EventEmitter<string>();
    @Output() removeSigners = new EventEmitter<string[]>();
    @Output() selectSigners = new EventEmitter<FilteredSelectEvent<User>>();
    @Output() changeSignByDate = new EventEmitter<{ value: Date; ids: string[] }>();
    @Output() changeNotifyMe = new EventEmitter<{ value: CHECKBOX_STATES; ids: string[] }>();
    @Output() changeEmailSigner = new EventEmitter<{ value: CHECKBOX_STATES; ids: string[] }>();

    documentsHaveFixedSignatureType: boolean;
    someSignersHavePendingRequests: boolean;
    someSignersLackSignPermissions: boolean;

    bulkMode = false;
    bulkSignByDate: Date = null;
    bulkNotifyMe = CHECKBOX_STATES.NOT_SELECTED;
    bulkEmailSigner = CHECKBOX_STATES.NOT_SELECTED;
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    checkedIds: Set<string> = new Set();
    readonly currentDate = new Date();

    ngOnInit(): void {
        this.data.forEach((d: DocumentsSignersTabDataRow) => {
            if (d.checked === CHECKBOX_STATES.SELECTED) {
                this.checkedIds.add(d.id);
            }
        });
        this.handleHeaderCheckboxStateOnRowChange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            if (!(this.data && this.data.length)) {
                this.bulkMode = false;
            }
            this.someSignersHavePendingRequests = this.data.some((d: DocumentsSignersTabDataRow) => {
                return d.rowDataType === 'has-pending-request-for-all-documents'
                    || d.hasPendingSignatureRequestsOnSomeDocuments;
            });
            this.someSignersLackSignPermissions = this.data.some((d: DocumentsSignersTabDataRow) => {
                return d.rowDataType === 'lacks-sign-permission-for-all-documents'
                    || d.lacksPermissionsForSomeDocuments;
            });
        }
    }

    onSignersFilterChange($event: string): void {
        this.signersFilterChange.emit($event);
    }

    onSignersSelect($event: FilteredSelectEvent<User>): void {
        this.selectSigners.emit($event);
        $event.removedIds.forEach((id) => this.checkedIds.delete(id));
    }

    private applyBulkValuesIfPresent(row?: DocumentsSignersTabDataRow): void {
        if (!this.bulkMode) {
            return;
        }
        if (this.bulkEmailSigner || this.bulkEmailSigner === CHECKBOX_STATES.NOT_SELECTED) {
            this.onEmailSignerChange();
        }
        if (this.bulkNotifyMe || this.bulkNotifyMe === CHECKBOX_STATES.NOT_SELECTED) {
            this.onNotifyMeChange();
        }
        if (this.bulkSignByDate || this.bulkSignByDate === null) {
            this.onSignByDateChange({ value: this.bulkSignByDate }, row && row.id);
        }
    }

    toggleCheckedAllRows(): void {
        if (
            this.headerCheckboxState === CHECKBOX_STATES.NOT_SELECTED
            || this.headerCheckboxState === CHECKBOX_STATES.PARTIALLY_SELECTED
        ) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
            this.data.forEach((row: DocumentsSignersTabDataRow) => {
                row.checked = this.headerCheckboxState;
                this.checkedIds.add(row.id);
            });
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
            this.data.forEach((row: DocumentsSignersTabDataRow) => {
                row.checked = this.headerCheckboxState;
                this.checkedIds.delete(row.id);
            });
        }

        this.applyBulkValuesIfPresent();
    }

    private handleHeaderCheckboxStateOnRowChange(): void {
        if (!this.checkedIds.size) {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
        }
        else if (this.checkedIds.size === this.data.length) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.PARTIALLY_SELECTED;
        }
    }

    toggleCheckedRow($event: DocumentsSignersTabDataRow): void {
        if ($event.checked && $event.checked === CHECKBOX_STATES.SELECTED) {
            $event.checked = CHECKBOX_STATES.NOT_SELECTED;
            this.checkedIds.delete($event.id as string);
        }
        else {
            $event.checked = CHECKBOX_STATES.SELECTED;
            this.checkedIds.add($event.id as string);
        }
        this.handleHeaderCheckboxStateOnRowChange();
        this.applyBulkValuesIfPresent($event);
    }

    onSignByDateChange({ value }, id?: string): void {
        let ids = [id];
        if (!id) {
            this.bulkSignByDate = value || null;
            ids = Array.from(this.checkedIds);
        }

        if (!(ids && ids.length)) {
            return;
        }
        this.changeSignByDate.emit({ value, ids });
    }

    private getNextCheckboxState(currentState: CHECKBOX_STATES): CHECKBOX_STATES {
        if (currentState === CHECKBOX_STATES.SELECTED) {
            return CHECKBOX_STATES.NOT_SELECTED;
        }
        return CHECKBOX_STATES.SELECTED;
    }

    onNotifyMeChange(row?: DocumentsSignersTabDataRow): void {
        let ids = [];
        let value = null;
        if (row) {
            ids = [row.id];
            value = this.getNextCheckboxState(row.notifyMe);
        }
        else {
            value = this.bulkNotifyMe;
            ids = Array.from(this.checkedIds);
        }
        if (!(ids && ids.length)) {
            return;
        }
        this.changeNotifyMe.emit({ value, ids });
    }

    onEmailSignerChange(row?: DocumentsSignersTabDataRow): void {
        let ids = [];
        let value = null;
        if (row) {
            ids = [row.id];
            value = this.getNextCheckboxState(row.emailSigner);
        }
        else {
            value = this.bulkEmailSigner;
            ids = Array.from(this.checkedIds);
        }
        if (!(ids && ids.length)) {
            return;
        }
        this.changeEmailSigner.emit({ value, ids });
    }

    onBulkNotifyMeCheck(): void {
        this.bulkNotifyMe = this.getNextCheckboxState(this.bulkNotifyMe);
        this.onNotifyMeChange();
    }

    onBulkEmailSignerCheck(): void {
        this.bulkEmailSigner = this.getNextCheckboxState(this.bulkEmailSigner);
        this.onEmailSignerChange();
    }

    onSignersRemove(): void {
        const ids = Array.from(this.checkedIds);
        if (!(ids && ids.length)) {
            return;
        }
        this.removeSigners.emit(ids);
        this.checkedIds.clear();
        this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    }

    toggleBulkMode(): void {
        this.bulkMode = !this.bulkMode;
    }
}
