const UibTabHref = () => ({
    restrict: 'A',
    link: function UibTabHrefLink(scope, element, attrs) {
        const firstAnchorChild = element.find('a')[0];
        if (attrs.uibTabHref) {
            firstAnchorChild.setAttribute('href', attrs.uibTabHref);
        }
        else {
            firstAnchorChild.removeAttribute('href');
        }
    }
});

export default UibTabHref;
